import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
  /** The `Short` scalar type represents non-fractional signed whole 16-bit numeric values. Short can represent values between -(2^15) and 2^15 - 1. */
  Short: any;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER'
}

export type BooleanDto = {
  __typename?: 'BooleanDTO';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CountryDto = {
  __typename?: 'CountryDTO';
  countryCode: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
};

export type CreateMediaDto = {
  __typename?: 'CreateMediaDTO';
  error?: Maybe<Scalars['String']>;
  value?: Maybe<CreateMediaValue>;
};

export type CreateMediaValue = {
  __typename?: 'CreateMediaValue';
  filePath: Scalars['String'];
  imageId: Scalars['String'];
  mediaLibraryIDHash: Scalars['String'];
  thumbFilePath: Scalars['String'];
};

export type DraftContentDto = {
  __typename?: 'DraftContentDTO';
  content: Scalars['String'];
  draftContentIDHash: Scalars['String'];
};

export type DraftDto = {
  __typename?: 'DraftDTO';
  created: Scalars['DateTime'];
  draftIDHash: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['DateTime']>;
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DraftDetailDto = {
  __typename?: 'DraftDetailDTO';
  created: Scalars['DateTime'];
  draftIDHash: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['DateTime']>;
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  virtualSections: Array<DraftContentDto>;
};

export type ErrorInfoInput = {
  componentStack?: InputMaybe<Scalars['String']>;
};

export type ErrorInput = {
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  stack?: InputMaybe<Scalars['String']>;
};

export type GenreDto = {
  __typename?: 'GenreDTO';
  description: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  genreIDHash: Scalars['String'];
  name: Scalars['String'];
};

export type Initialize2Fadto = {
  __typename?: 'Initialize2FADTO';
  error?: Maybe<Scalars['String']>;
  recoveryCodes?: Maybe<Array<Scalars['String']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  avatarUploadURL: Scalars['String'];
  cancelCurrentUserPlan?: Maybe<PlanChangeDto>;
  changeAvatarVersion?: Maybe<UserDto>;
  changeCurrentUserBio: BooleanDto;
  changeCurrentUserCountry: UserDto;
  changeCurrentUserDisplayName: BooleanDto;
  changeCurrentUserEmail: BooleanDto;
  changeCurrentUserRecoveryEmail: BooleanDto;
  changeCurrentUserShowActiveStatus: BooleanDto;
  changeCurrentUserWebLink: BooleanDto;
  completeRegistration?: Maybe<UserDto>;
  createDraft: StringDto;
  createMedia: CreateMediaDto;
  deleteDraft: BooleanDto;
  initializeTwoFA?: Maybe<Initialize2Fadto>;
  logError: Scalars['Boolean'];
  mediaUploadComplete: BooleanDto;
  processRegistrationPlan?: Maybe<PlanChangeDto>;
  registerUser?: Maybe<UserDto>;
  removeTwoFA?: Maybe<BooleanDto>;
  saveDraftContent: BooleanDto;
  sendCurrentEmailCode: BooleanDto;
  sendEmailCode: BooleanDto;
  subscribeFreePlan?: Maybe<UserDto>;
  updateDraftTitleAndSubtitle: BooleanDto;
  updateUserGenres: BooleanDto;
  verifyTwoFACode?: Maybe<BooleanDto>;
};


export type MutationChangeAvatarVersionArgs = {
  avatarVersion?: InputMaybe<Scalars['Int']>;
};


export type MutationChangeCurrentUserBioArgs = {
  bio: Scalars['String'];
};


export type MutationChangeCurrentUserCountryArgs = {
  countryCode?: InputMaybe<Scalars['String']>;
};


export type MutationChangeCurrentUserDisplayNameArgs = {
  code: Scalars['String'];
  codeEntryTime: Scalars['Long'];
  newDisplayName: Scalars['String'];
};


export type MutationChangeCurrentUserEmailArgs = {
  code: Scalars['String'];
  codeEntryTime: Scalars['Long'];
  newEmail: Scalars['String'];
};


export type MutationChangeCurrentUserRecoveryEmailArgs = {
  code: Scalars['String'];
  codeEntryTime: Scalars['Long'];
  newRecoveryEmail: Scalars['String'];
};


export type MutationChangeCurrentUserShowActiveStatusArgs = {
  status: Scalars['Boolean'];
};


export type MutationChangeCurrentUserWebLinkArgs = {
  webLink: Scalars['String'];
};


export type MutationCreateDraftArgs = {
  subtitle?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};


export type MutationCreateMediaArgs = {
  fileSize: Scalars['Long'];
  fileType: Scalars['String'];
  height: Scalars['Short'];
  tags?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  width: Scalars['Short'];
};


export type MutationDeleteDraftArgs = {
  draftIDHash: Scalars['String'];
};


export type MutationInitializeTwoFaArgs = {
  code: Scalars['String'];
  codeEntryTime: Scalars['Long'];
};


export type MutationLogErrorArgs = {
  error: ErrorInput;
  errorInfo: ErrorInfoInput;
};


export type MutationMediaUploadCompleteArgs = {
  mediaLibraryIDHash: Scalars['String'];
};


export type MutationProcessRegistrationPlanArgs = {
  hostedPageId: Scalars['String'];
};


export type MutationRemoveTwoFaArgs = {
  code: Scalars['String'];
  codeEntryTime: Scalars['Long'];
};


export type MutationSaveDraftContentArgs = {
  content: Scalars['String'];
  draftContentIDHash: Scalars['String'];
};


export type MutationSendEmailCodeArgs = {
  email: Scalars['String'];
};


export type MutationUpdateDraftTitleAndSubtitleArgs = {
  draftIDHash: Scalars['String'];
  subtitle?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};


export type MutationUpdateUserGenresArgs = {
  genreIDHashes: Array<Scalars['String']>;
};


export type MutationVerifyTwoFaCodeArgs = {
  code: Scalars['String'];
  codeEntryTime: Scalars['Long'];
  saveCode: Scalars['Boolean'];
};

export type PhotoDto = {
  __typename?: 'PhotoDTO';
  author?: Maybe<Scalars['String']>;
  authorUrl?: Maybe<Scalars['String']>;
  height: Scalars['Int'];
  imageUrl: Scalars['String'];
  license?: Maybe<Scalars['String']>;
  licenseUrl?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  thumbImageUrl: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  width: Scalars['Int'];
};

export enum PhotoPickerType {
  MediaLibrary = 'MEDIA_LIBRARY',
  None = 'NONE',
  Openverse = 'OPENVERSE',
  Pexels = 'PEXELS',
  Pixabay = 'PIXABAY',
  Unsplash = 'UNSPLASH'
}

export type PickerPhotosDto = {
  __typename?: 'PickerPhotosDTO';
  error?: Maybe<Scalars['String']>;
  nextPage?: Maybe<Scalars['Int']>;
  photos: Array<PhotoDto>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type PlanChangeDto = {
  __typename?: 'PlanChangeDTO';
  error?: Maybe<Scalars['String']>;
  user?: Maybe<UserDto>;
  userSubscription?: Maybe<UserSubscriptionDto>;
};

export type Query = {
  __typename?: 'Query';
  avatarUploadURL: Scalars['String'];
  currentUser?: Maybe<UserDto>;
  currentUserSubscription?: Maybe<UserSubscriptionDto>;
  currentUserUpdateSubscriptionCardURL?: Maybe<Scalars['String']>;
  currentUserUpdateSubscriptionURL?: Maybe<Scalars['String']>;
  isEmailFree: Scalars['Boolean'];
  listCountries: Array<CountryDto>;
  listGenres: Array<GenreDto>;
  listUserDrafts: Array<DraftDto>;
  listUserGenres: Array<Scalars['String']>;
  mediaSAS: StringDto;
  pickerPhotos: PickerPhotosDto;
  twoFASetup?: Maybe<Setup2Fadto>;
  user?: Maybe<UserDto>;
  userDraft: DraftDetailDto;
  verifyEmailCode: Scalars['Boolean'];
};


export type QueryCurrentUserUpdateSubscriptionUrlArgs = {
  planCode: Scalars['String'];
};


export type QueryIsEmailFreeArgs = {
  email: Scalars['String'];
};


export type QueryMediaSasArgs = {
  importSizeBytes: Scalars['Long'];
};


export type QueryPickerPhotosArgs = {
  initialSearch: Scalars['Boolean'];
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  pickerType: PhotoPickerType;
  query: Scalars['String'];
  totalRecords?: InputMaybe<Scalars['Int']>;
};


export type QueryUserArgs = {
  userIDHash: Scalars['String'];
};


export type QueryUserDraftArgs = {
  draftIDHash: Scalars['String'];
};


export type QueryVerifyEmailCodeArgs = {
  code: Scalars['String'];
  isCurrentEmail: Scalars['Boolean'];
};

export type Setup2Fadto = {
  __typename?: 'Setup2FADTO';
  error?: Maybe<Scalars['String']>;
  twoFASecret: Scalars['String'];
};

export type StringDto = {
  __typename?: 'StringDTO';
  error?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type UserDto = {
  __typename?: 'UserDTO';
  avatarVersion?: Maybe<Scalars['Int']>;
  bio?: Maybe<Scalars['String']>;
  country?: Maybe<CountryDto>;
  createdDate?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  is2FASetup?: Maybe<Scalars['Boolean']>;
  isCreator?: Maybe<Scalars['Boolean']>;
  recoveryEmail?: Maybe<Scalars['String']>;
  registrationComplete?: Maybe<Scalars['Boolean']>;
  registrationStep?: Maybe<Scalars['Int']>;
  sSOID?: Maybe<Scalars['String']>;
  showActiveStatus?: Maybe<Scalars['Boolean']>;
  subscriptionPlanID?: Maybe<Scalars['Int']>;
  userIDHash?: Maybe<Scalars['String']>;
  weblink?: Maybe<Scalars['String']>;
};

export type UserSubscriptionDto = {
  __typename?: 'UserSubscriptionDTO';
  error?: Maybe<Scalars['String']>;
  hostedPageId?: Maybe<Scalars['String']>;
  planCost?: Maybe<Scalars['String']>;
  renewalDateString?: Maybe<Scalars['String']>;
  subscriptionActionId?: Maybe<Scalars['Int']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  subscriptionPlanCode?: Maybe<Scalars['String']>;
  subscriptionTerms?: Maybe<Scalars['String']>;
};

export type CurrentUserFragment = { __typename?: 'UserDTO', userIDHash?: string | null, sSOID?: string | null, email?: string | null, recoveryEmail?: string | null, displayName?: string | null, weblink?: string | null, bio?: string | null, showActiveStatus?: boolean | null, isCreator?: boolean | null, avatarVersion?: number | null, subscriptionPlanID?: number | null, registrationStep?: number | null, registrationComplete?: boolean | null, is2FASetup?: boolean | null, error?: string | null, country?: { __typename?: 'CountryDTO', countryCode: string, name: string } | null };

export type CurrentUserSubscriptionFragment = { __typename?: 'UserSubscriptionDTO', subscriptionPlan?: string | null, subscriptionPlanCode?: string | null, subscriptionTerms?: string | null, subscriptionId?: string | null, renewalDateString?: string | null, planCost?: string | null, error?: string | null };

export type DraftFragment = { __typename?: 'DraftDTO', draftIDHash: string, title: string, subtitle?: string | null, created: any, modified?: any | null, error?: string | null };

export type PhotoFragment = { __typename?: 'PhotoDTO', width: number, height: number, title?: string | null, tags?: string | null, author?: string | null, authorUrl?: string | null, imageUrl: string, thumbImageUrl: string, license?: string | null, licenseUrl?: string | null };

export type PickerPhotosFragment = { __typename?: 'PickerPhotosDTO', nextPage?: number | null, totalRecords?: number | null, error?: string | null, photos: Array<{ __typename?: 'PhotoDTO', width: number, height: number, title?: string | null, tags?: string | null, author?: string | null, authorUrl?: string | null, imageUrl: string, thumbImageUrl: string, license?: string | null, licenseUrl?: string | null }> };

export type RegisterUserMutationVariables = Exact<{ [key: string]: never; }>;


export type RegisterUserMutation = { __typename?: 'Mutation', registerUser?: { __typename?: 'UserDTO', userIDHash?: string | null, sSOID?: string | null, email?: string | null, recoveryEmail?: string | null, displayName?: string | null, weblink?: string | null, bio?: string | null, showActiveStatus?: boolean | null, isCreator?: boolean | null, avatarVersion?: number | null, subscriptionPlanID?: number | null, registrationStep?: number | null, registrationComplete?: boolean | null, is2FASetup?: boolean | null, error?: string | null, country?: { __typename?: 'CountryDTO', countryCode: string, name: string } | null } | null };

export type ProcessRegistrationPlanMutationVariables = Exact<{
  hostedPageId: Scalars['String'];
}>;


export type ProcessRegistrationPlanMutation = { __typename?: 'Mutation', processRegistrationPlan?: { __typename?: 'PlanChangeDTO', error?: string | null, user?: { __typename?: 'UserDTO', userIDHash?: string | null, sSOID?: string | null, email?: string | null, recoveryEmail?: string | null, displayName?: string | null, weblink?: string | null, bio?: string | null, showActiveStatus?: boolean | null, isCreator?: boolean | null, avatarVersion?: number | null, subscriptionPlanID?: number | null, registrationStep?: number | null, registrationComplete?: boolean | null, is2FASetup?: boolean | null, error?: string | null, country?: { __typename?: 'CountryDTO', countryCode: string, name: string } | null } | null, userSubscription?: { __typename?: 'UserSubscriptionDTO', subscriptionPlan?: string | null, subscriptionPlanCode?: string | null, subscriptionTerms?: string | null, subscriptionId?: string | null, renewalDateString?: string | null, planCost?: string | null, error?: string | null } | null } | null };

export type CancelCurrentUserPlanMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelCurrentUserPlanMutation = { __typename?: 'Mutation', cancelCurrentUserPlan?: { __typename?: 'PlanChangeDTO', user?: { __typename?: 'UserDTO', userIDHash?: string | null, sSOID?: string | null, email?: string | null, recoveryEmail?: string | null, displayName?: string | null, weblink?: string | null, bio?: string | null, showActiveStatus?: boolean | null, isCreator?: boolean | null, avatarVersion?: number | null, subscriptionPlanID?: number | null, registrationStep?: number | null, registrationComplete?: boolean | null, is2FASetup?: boolean | null, error?: string | null, country?: { __typename?: 'CountryDTO', countryCode: string, name: string } | null } | null, userSubscription?: { __typename?: 'UserSubscriptionDTO', subscriptionPlan?: string | null, subscriptionPlanCode?: string | null, subscriptionTerms?: string | null, subscriptionId?: string | null, renewalDateString?: string | null, planCost?: string | null, error?: string | null } | null } | null };

export type ChangeAvatarVersionMutationVariables = Exact<{
  avatarVersion?: InputMaybe<Scalars['Int']>;
}>;


export type ChangeAvatarVersionMutation = { __typename?: 'Mutation', changeAvatarVersion?: { __typename?: 'UserDTO', userIDHash?: string | null, sSOID?: string | null, email?: string | null, recoveryEmail?: string | null, displayName?: string | null, weblink?: string | null, bio?: string | null, showActiveStatus?: boolean | null, isCreator?: boolean | null, avatarVersion?: number | null, subscriptionPlanID?: number | null, registrationStep?: number | null, registrationComplete?: boolean | null, is2FASetup?: boolean | null, error?: string | null, country?: { __typename?: 'CountryDTO', countryCode: string, name: string } | null } | null };

export type SubscribeFreePlanMutationVariables = Exact<{ [key: string]: never; }>;


export type SubscribeFreePlanMutation = { __typename?: 'Mutation', subscribeFreePlan?: { __typename?: 'UserDTO', userIDHash?: string | null, sSOID?: string | null, email?: string | null, recoveryEmail?: string | null, displayName?: string | null, weblink?: string | null, bio?: string | null, showActiveStatus?: boolean | null, isCreator?: boolean | null, avatarVersion?: number | null, subscriptionPlanID?: number | null, registrationStep?: number | null, registrationComplete?: boolean | null, is2FASetup?: boolean | null, error?: string | null, country?: { __typename?: 'CountryDTO', countryCode: string, name: string } | null } | null };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser?: { __typename?: 'UserDTO', userIDHash?: string | null, sSOID?: string | null, email?: string | null, recoveryEmail?: string | null, displayName?: string | null, weblink?: string | null, bio?: string | null, showActiveStatus?: boolean | null, isCreator?: boolean | null, avatarVersion?: number | null, subscriptionPlanID?: number | null, registrationStep?: number | null, registrationComplete?: boolean | null, is2FASetup?: boolean | null, error?: string | null, country?: { __typename?: 'CountryDTO', countryCode: string, name: string } | null } | null };

export type CurrentUserSubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserSubscriptionQuery = { __typename?: 'Query', currentUserSubscription?: { __typename?: 'UserSubscriptionDTO', subscriptionPlan?: string | null, subscriptionPlanCode?: string | null, subscriptionTerms?: string | null, subscriptionId?: string | null, renewalDateString?: string | null, planCost?: string | null, error?: string | null } | null };

export type CurrentUserUpdateSubscriptionCardUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserUpdateSubscriptionCardUrlQuery = { __typename?: 'Query', currentUserUpdateSubscriptionCardURL?: string | null };

export type TwoFaSetupQueryVariables = Exact<{ [key: string]: never; }>;


export type TwoFaSetupQuery = { __typename?: 'Query', twoFASetup?: { __typename?: 'Setup2FADTO', twoFASecret: string } | null };

export type RemoveTwoFaMutationVariables = Exact<{
  code: Scalars['String'];
  codeEntryTime: Scalars['Long'];
}>;


export type RemoveTwoFaMutation = { __typename?: 'Mutation', removeTwoFA?: { __typename?: 'BooleanDTO', success: boolean, error?: string | null } | null };

export type InitializeTwoFaMutationVariables = Exact<{
  code: Scalars['String'];
  codeEntryTime: Scalars['Long'];
}>;


export type InitializeTwoFaMutation = { __typename?: 'Mutation', initializeTwoFA?: { __typename?: 'Initialize2FADTO', recoveryCodes?: Array<string> | null, error?: string | null } | null };

export type VerifyTwoFaCodeMutationVariables = Exact<{
  code: Scalars['String'];
  codeEntryTime: Scalars['Long'];
  saveCode: Scalars['Boolean'];
}>;


export type VerifyTwoFaCodeMutation = { __typename?: 'Mutation', verifyTwoFACode?: { __typename?: 'BooleanDTO', success: boolean, error?: string | null } | null };

export type SendCurrentEmailCodeMutationVariables = Exact<{ [key: string]: never; }>;


export type SendCurrentEmailCodeMutation = { __typename?: 'Mutation', sendCurrentEmailCode: { __typename?: 'BooleanDTO', success: boolean, error?: string | null } };

export type SendEmailCodeMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendEmailCodeMutation = { __typename?: 'Mutation', sendEmailCode: { __typename?: 'BooleanDTO', success: boolean, error?: string | null } };

export type IsEmailFreeQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type IsEmailFreeQuery = { __typename?: 'Query', isEmailFree: boolean };

export type VerifyEmailCodeQueryVariables = Exact<{
  code: Scalars['String'];
  isCurrentEmail: Scalars['Boolean'];
}>;


export type VerifyEmailCodeQuery = { __typename?: 'Query', verifyEmailCode: boolean };

export type ChangeCurrentUserEmailMutationVariables = Exact<{
  newEmail: Scalars['String'];
  code: Scalars['String'];
  codeEntryTime: Scalars['Long'];
}>;


export type ChangeCurrentUserEmailMutation = { __typename?: 'Mutation', changeCurrentUserEmail: { __typename?: 'BooleanDTO', success: boolean, error?: string | null } };

export type ChangeCurrentUserRecoveryEmailMutationVariables = Exact<{
  newRecoveryEmail: Scalars['String'];
  code: Scalars['String'];
  codeEntryTime: Scalars['Long'];
}>;


export type ChangeCurrentUserRecoveryEmailMutation = { __typename?: 'Mutation', changeCurrentUserRecoveryEmail: { __typename?: 'BooleanDTO', success: boolean, error?: string | null } };

export type ChangeCurrentUserDisplayNameMutationVariables = Exact<{
  newDisplayName: Scalars['String'];
  code: Scalars['String'];
  codeEntryTime: Scalars['Long'];
}>;


export type ChangeCurrentUserDisplayNameMutation = { __typename?: 'Mutation', changeCurrentUserDisplayName: { __typename?: 'BooleanDTO', success: boolean, error?: string | null } };

export type ChangeCurrentUserWebLinkMutationVariables = Exact<{
  webLink: Scalars['String'];
}>;


export type ChangeCurrentUserWebLinkMutation = { __typename?: 'Mutation', changeCurrentUserWebLink: { __typename?: 'BooleanDTO', success: boolean, error?: string | null } };

export type ChangeCurrentUserBioMutationVariables = Exact<{
  bio: Scalars['String'];
}>;


export type ChangeCurrentUserBioMutation = { __typename?: 'Mutation', changeCurrentUserBio: { __typename?: 'BooleanDTO', success: boolean, error?: string | null } };

export type ChangeCurrentUserCountryMutationVariables = Exact<{
  countryCode?: InputMaybe<Scalars['String']>;
}>;


export type ChangeCurrentUserCountryMutation = { __typename?: 'Mutation', changeCurrentUserCountry: { __typename?: 'UserDTO', userIDHash?: string | null, sSOID?: string | null, email?: string | null, recoveryEmail?: string | null, displayName?: string | null, weblink?: string | null, bio?: string | null, showActiveStatus?: boolean | null, isCreator?: boolean | null, avatarVersion?: number | null, subscriptionPlanID?: number | null, registrationStep?: number | null, registrationComplete?: boolean | null, is2FASetup?: boolean | null, error?: string | null, country?: { __typename?: 'CountryDTO', countryCode: string, name: string } | null } };

export type ChangeCurrentUserShowActiveStatusMutationVariables = Exact<{
  status: Scalars['Boolean'];
}>;


export type ChangeCurrentUserShowActiveStatusMutation = { __typename?: 'Mutation', changeCurrentUserShowActiveStatus: { __typename?: 'BooleanDTO', success: boolean, error?: string | null } };

export type CurrentUserUpdateSubscriptionUrlQueryVariables = Exact<{
  planCode: Scalars['String'];
}>;


export type CurrentUserUpdateSubscriptionUrlQuery = { __typename?: 'Query', currentUserUpdateSubscriptionURL?: string | null };

export type ListGenresQueryVariables = Exact<{ [key: string]: never; }>;


export type ListGenresQuery = { __typename?: 'Query', listGenres: Array<{ __typename?: 'GenreDTO', genreIDHash: string, name: string, description: string }> };

export type ListUserGenresQueryVariables = Exact<{ [key: string]: never; }>;


export type ListUserGenresQuery = { __typename?: 'Query', listUserGenres: Array<string> };

export type ListUserDraftsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListUserDraftsQuery = { __typename?: 'Query', listUserDrafts: Array<{ __typename?: 'DraftDTO', draftIDHash: string, title: string, subtitle?: string | null, created: any, modified?: any | null }> };

export type UpdateUserGenresMutationVariables = Exact<{
  genreIDHashes: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateUserGenresMutation = { __typename?: 'Mutation', updateUserGenres: { __typename?: 'BooleanDTO', success: boolean, error?: string | null } };

export type ListCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListCountriesQuery = { __typename?: 'Query', listCountries: Array<{ __typename?: 'CountryDTO', countryCode: string, name: string }> };

export type MediaSasQueryVariables = Exact<{
  importSizeBytes: Scalars['Long'];
}>;


export type MediaSasQuery = { __typename?: 'Query', mediaSAS: { __typename?: 'StringDTO', value?: string | null, error?: string | null } };

export type CreateMediaMutationVariables = Exact<{
  title: Scalars['String'];
  tags?: InputMaybe<Scalars['String']>;
  fileType: Scalars['String'];
  fileSize: Scalars['Long'];
  width: Scalars['Short'];
  height: Scalars['Short'];
}>;


export type CreateMediaMutation = { __typename?: 'Mutation', createMedia: { __typename?: 'CreateMediaDTO', error?: string | null, value?: { __typename?: 'CreateMediaValue', mediaLibraryIDHash: string, imageId: string, filePath: string, thumbFilePath: string } | null } };

export type MediaUploadCompleteMutationVariables = Exact<{
  mediaLibraryIDHash: Scalars['String'];
}>;


export type MediaUploadCompleteMutation = { __typename?: 'Mutation', mediaUploadComplete: { __typename?: 'BooleanDTO', success: boolean, error?: string | null } };

export type CompleteRegistrationMutationVariables = Exact<{ [key: string]: never; }>;


export type CompleteRegistrationMutation = { __typename?: 'Mutation', completeRegistration?: { __typename?: 'UserDTO', userIDHash?: string | null, sSOID?: string | null, email?: string | null, recoveryEmail?: string | null, displayName?: string | null, weblink?: string | null, bio?: string | null, showActiveStatus?: boolean | null, isCreator?: boolean | null, avatarVersion?: number | null, subscriptionPlanID?: number | null, registrationStep?: number | null, registrationComplete?: boolean | null, is2FASetup?: boolean | null, error?: string | null, country?: { __typename?: 'CountryDTO', countryCode: string, name: string } | null } | null };

export type UserDraftQueryVariables = Exact<{
  draftIDHash: Scalars['String'];
}>;


export type UserDraftQuery = { __typename?: 'Query', userDraft: { __typename?: 'DraftDetailDTO', draftIDHash: string, title: string, subtitle?: string | null, created: any, modified?: any | null, error?: string | null, virtualSections: Array<{ __typename?: 'DraftContentDTO', draftContentIDHash: string, content: string }> } };

export type PickerPhotosQueryVariables = Exact<{
  pickerType: PhotoPickerType;
  initialSearch: Scalars['Boolean'];
  totalRecords?: InputMaybe<Scalars['Int']>;
  pageSize: Scalars['Int'];
  pageNumber: Scalars['Int'];
  query: Scalars['String'];
}>;


export type PickerPhotosQuery = { __typename?: 'Query', pickerPhotos: { __typename?: 'PickerPhotosDTO', nextPage?: number | null, totalRecords?: number | null, error?: string | null, photos: Array<{ __typename?: 'PhotoDTO', width: number, height: number, title?: string | null, tags?: string | null, author?: string | null, authorUrl?: string | null, imageUrl: string, thumbImageUrl: string, license?: string | null, licenseUrl?: string | null }> } };

export type CreateDraftMutationVariables = Exact<{
  title: Scalars['String'];
  subtitle?: InputMaybe<Scalars['String']>;
}>;


export type CreateDraftMutation = { __typename?: 'Mutation', createDraft: { __typename?: 'StringDTO', value?: string | null, error?: string | null } };

export type SaveDraftContentMutationVariables = Exact<{
  draftContentIDHash: Scalars['String'];
  content: Scalars['String'];
}>;


export type SaveDraftContentMutation = { __typename?: 'Mutation', saveDraftContent: { __typename?: 'BooleanDTO', success: boolean, error?: string | null } };

export type DeleteDraftMutationVariables = Exact<{
  draftIDHash: Scalars['String'];
}>;


export type DeleteDraftMutation = { __typename?: 'Mutation', deleteDraft: { __typename?: 'BooleanDTO', success: boolean, error?: string | null } };

export type UpdateDraftTitleAndSubtitleMutationVariables = Exact<{
  draftIDHash: Scalars['String'];
  title: Scalars['String'];
  subtitle?: InputMaybe<Scalars['String']>;
}>;


export type UpdateDraftTitleAndSubtitleMutation = { __typename?: 'Mutation', updateDraftTitleAndSubtitle: { __typename?: 'BooleanDTO', success: boolean, error?: string | null } };

export type LogErrorMutationVariables = Exact<{
  error: ErrorInput;
  errorInfo: ErrorInfoInput;
}>;


export type LogErrorMutation = { __typename?: 'Mutation', logError: boolean };

export const CurrentUserFragmentDoc = gql`
    fragment CurrentUser on UserDTO {
  userIDHash
  sSOID
  email
  recoveryEmail
  displayName
  weblink
  bio
  country {
    countryCode
    name
  }
  showActiveStatus
  isCreator
  avatarVersion
  subscriptionPlanID
  registrationStep
  registrationComplete
  is2FASetup
  error
}
    `;
export const CurrentUserSubscriptionFragmentDoc = gql`
    fragment CurrentUserSubscription on UserSubscriptionDTO {
  subscriptionPlan
  subscriptionPlanCode
  subscriptionTerms
  subscriptionId
  renewalDateString
  planCost
  error
}
    `;
export const DraftFragmentDoc = gql`
    fragment Draft on DraftDTO {
  draftIDHash
  title
  subtitle
  created
  modified
  error
}
    `;
export const PhotoFragmentDoc = gql`
    fragment Photo on PhotoDTO {
  width
  height
  title
  tags
  author
  authorUrl
  imageUrl
  thumbImageUrl
  license
  licenseUrl
}
    `;
export const PickerPhotosFragmentDoc = gql`
    fragment PickerPhotos on PickerPhotosDTO {
  nextPage
  photos {
    ...Photo
  }
  totalRecords
  error
}
    ${PhotoFragmentDoc}`;
export const RegisterUserDocument = gql`
    mutation registerUser {
  registerUser {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const ProcessRegistrationPlanDocument = gql`
    mutation processRegistrationPlan($hostedPageId: String!) {
  processRegistrationPlan(hostedPageId: $hostedPageId) {
    user {
      ...CurrentUser
    }
    userSubscription {
      ...CurrentUserSubscription
    }
    error
  }
}
    ${CurrentUserFragmentDoc}
${CurrentUserSubscriptionFragmentDoc}`;
export type ProcessRegistrationPlanMutationFn = Apollo.MutationFunction<ProcessRegistrationPlanMutation, ProcessRegistrationPlanMutationVariables>;

/**
 * __useProcessRegistrationPlanMutation__
 *
 * To run a mutation, you first call `useProcessRegistrationPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessRegistrationPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processRegistrationPlanMutation, { data, loading, error }] = useProcessRegistrationPlanMutation({
 *   variables: {
 *      hostedPageId: // value for 'hostedPageId'
 *   },
 * });
 */
export function useProcessRegistrationPlanMutation(baseOptions?: Apollo.MutationHookOptions<ProcessRegistrationPlanMutation, ProcessRegistrationPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessRegistrationPlanMutation, ProcessRegistrationPlanMutationVariables>(ProcessRegistrationPlanDocument, options);
      }
export type ProcessRegistrationPlanMutationHookResult = ReturnType<typeof useProcessRegistrationPlanMutation>;
export type ProcessRegistrationPlanMutationResult = Apollo.MutationResult<ProcessRegistrationPlanMutation>;
export type ProcessRegistrationPlanMutationOptions = Apollo.BaseMutationOptions<ProcessRegistrationPlanMutation, ProcessRegistrationPlanMutationVariables>;
export const CancelCurrentUserPlanDocument = gql`
    mutation cancelCurrentUserPlan {
  cancelCurrentUserPlan {
    user {
      ...CurrentUser
    }
    userSubscription {
      ...CurrentUserSubscription
    }
  }
}
    ${CurrentUserFragmentDoc}
${CurrentUserSubscriptionFragmentDoc}`;
export type CancelCurrentUserPlanMutationFn = Apollo.MutationFunction<CancelCurrentUserPlanMutation, CancelCurrentUserPlanMutationVariables>;

/**
 * __useCancelCurrentUserPlanMutation__
 *
 * To run a mutation, you first call `useCancelCurrentUserPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCurrentUserPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCurrentUserPlanMutation, { data, loading, error }] = useCancelCurrentUserPlanMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelCurrentUserPlanMutation(baseOptions?: Apollo.MutationHookOptions<CancelCurrentUserPlanMutation, CancelCurrentUserPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelCurrentUserPlanMutation, CancelCurrentUserPlanMutationVariables>(CancelCurrentUserPlanDocument, options);
      }
export type CancelCurrentUserPlanMutationHookResult = ReturnType<typeof useCancelCurrentUserPlanMutation>;
export type CancelCurrentUserPlanMutationResult = Apollo.MutationResult<CancelCurrentUserPlanMutation>;
export type CancelCurrentUserPlanMutationOptions = Apollo.BaseMutationOptions<CancelCurrentUserPlanMutation, CancelCurrentUserPlanMutationVariables>;
export const ChangeAvatarVersionDocument = gql`
    mutation changeAvatarVersion($avatarVersion: Int) {
  changeAvatarVersion(avatarVersion: $avatarVersion) {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;
export type ChangeAvatarVersionMutationFn = Apollo.MutationFunction<ChangeAvatarVersionMutation, ChangeAvatarVersionMutationVariables>;

/**
 * __useChangeAvatarVersionMutation__
 *
 * To run a mutation, you first call `useChangeAvatarVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAvatarVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAvatarVersionMutation, { data, loading, error }] = useChangeAvatarVersionMutation({
 *   variables: {
 *      avatarVersion: // value for 'avatarVersion'
 *   },
 * });
 */
export function useChangeAvatarVersionMutation(baseOptions?: Apollo.MutationHookOptions<ChangeAvatarVersionMutation, ChangeAvatarVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeAvatarVersionMutation, ChangeAvatarVersionMutationVariables>(ChangeAvatarVersionDocument, options);
      }
export type ChangeAvatarVersionMutationHookResult = ReturnType<typeof useChangeAvatarVersionMutation>;
export type ChangeAvatarVersionMutationResult = Apollo.MutationResult<ChangeAvatarVersionMutation>;
export type ChangeAvatarVersionMutationOptions = Apollo.BaseMutationOptions<ChangeAvatarVersionMutation, ChangeAvatarVersionMutationVariables>;
export const SubscribeFreePlanDocument = gql`
    mutation subscribeFreePlan {
  subscribeFreePlan {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;
export type SubscribeFreePlanMutationFn = Apollo.MutationFunction<SubscribeFreePlanMutation, SubscribeFreePlanMutationVariables>;

/**
 * __useSubscribeFreePlanMutation__
 *
 * To run a mutation, you first call `useSubscribeFreePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeFreePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeFreePlanMutation, { data, loading, error }] = useSubscribeFreePlanMutation({
 *   variables: {
 *   },
 * });
 */
export function useSubscribeFreePlanMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeFreePlanMutation, SubscribeFreePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscribeFreePlanMutation, SubscribeFreePlanMutationVariables>(SubscribeFreePlanDocument, options);
      }
export type SubscribeFreePlanMutationHookResult = ReturnType<typeof useSubscribeFreePlanMutation>;
export type SubscribeFreePlanMutationResult = Apollo.MutationResult<SubscribeFreePlanMutation>;
export type SubscribeFreePlanMutationOptions = Apollo.BaseMutationOptions<SubscribeFreePlanMutation, SubscribeFreePlanMutationVariables>;
export const CurrentUserDocument = gql`
    query currentUser {
  currentUser {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const CurrentUserSubscriptionDocument = gql`
    query currentUserSubscription {
  currentUserSubscription {
    ...CurrentUserSubscription
  }
}
    ${CurrentUserSubscriptionFragmentDoc}`;

/**
 * __useCurrentUserSubscriptionQuery__
 *
 * To run a query within a React component, call `useCurrentUserSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserSubscriptionQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserSubscriptionQuery, CurrentUserSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserSubscriptionQuery, CurrentUserSubscriptionQueryVariables>(CurrentUserSubscriptionDocument, options);
      }
export function useCurrentUserSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserSubscriptionQuery, CurrentUserSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserSubscriptionQuery, CurrentUserSubscriptionQueryVariables>(CurrentUserSubscriptionDocument, options);
        }
export type CurrentUserSubscriptionQueryHookResult = ReturnType<typeof useCurrentUserSubscriptionQuery>;
export type CurrentUserSubscriptionLazyQueryHookResult = ReturnType<typeof useCurrentUserSubscriptionLazyQuery>;
export type CurrentUserSubscriptionQueryResult = Apollo.QueryResult<CurrentUserSubscriptionQuery, CurrentUserSubscriptionQueryVariables>;
export const CurrentUserUpdateSubscriptionCardUrlDocument = gql`
    query currentUserUpdateSubscriptionCardURL {
  currentUserUpdateSubscriptionCardURL
}
    `;

/**
 * __useCurrentUserUpdateSubscriptionCardUrlQuery__
 *
 * To run a query within a React component, call `useCurrentUserUpdateSubscriptionCardUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserUpdateSubscriptionCardUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserUpdateSubscriptionCardUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserUpdateSubscriptionCardUrlQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserUpdateSubscriptionCardUrlQuery, CurrentUserUpdateSubscriptionCardUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserUpdateSubscriptionCardUrlQuery, CurrentUserUpdateSubscriptionCardUrlQueryVariables>(CurrentUserUpdateSubscriptionCardUrlDocument, options);
      }
export function useCurrentUserUpdateSubscriptionCardUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserUpdateSubscriptionCardUrlQuery, CurrentUserUpdateSubscriptionCardUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserUpdateSubscriptionCardUrlQuery, CurrentUserUpdateSubscriptionCardUrlQueryVariables>(CurrentUserUpdateSubscriptionCardUrlDocument, options);
        }
export type CurrentUserUpdateSubscriptionCardUrlQueryHookResult = ReturnType<typeof useCurrentUserUpdateSubscriptionCardUrlQuery>;
export type CurrentUserUpdateSubscriptionCardUrlLazyQueryHookResult = ReturnType<typeof useCurrentUserUpdateSubscriptionCardUrlLazyQuery>;
export type CurrentUserUpdateSubscriptionCardUrlQueryResult = Apollo.QueryResult<CurrentUserUpdateSubscriptionCardUrlQuery, CurrentUserUpdateSubscriptionCardUrlQueryVariables>;
export const TwoFaSetupDocument = gql`
    query twoFASetup {
  twoFASetup {
    twoFASecret
  }
}
    `;

/**
 * __useTwoFaSetupQuery__
 *
 * To run a query within a React component, call `useTwoFaSetupQuery` and pass it any options that fit your needs.
 * When your component renders, `useTwoFaSetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTwoFaSetupQuery({
 *   variables: {
 *   },
 * });
 */
export function useTwoFaSetupQuery(baseOptions?: Apollo.QueryHookOptions<TwoFaSetupQuery, TwoFaSetupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TwoFaSetupQuery, TwoFaSetupQueryVariables>(TwoFaSetupDocument, options);
      }
export function useTwoFaSetupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TwoFaSetupQuery, TwoFaSetupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TwoFaSetupQuery, TwoFaSetupQueryVariables>(TwoFaSetupDocument, options);
        }
export type TwoFaSetupQueryHookResult = ReturnType<typeof useTwoFaSetupQuery>;
export type TwoFaSetupLazyQueryHookResult = ReturnType<typeof useTwoFaSetupLazyQuery>;
export type TwoFaSetupQueryResult = Apollo.QueryResult<TwoFaSetupQuery, TwoFaSetupQueryVariables>;
export const RemoveTwoFaDocument = gql`
    mutation removeTwoFA($code: String!, $codeEntryTime: Long!) {
  removeTwoFA(code: $code, codeEntryTime: $codeEntryTime) {
    success
    error
  }
}
    `;
export type RemoveTwoFaMutationFn = Apollo.MutationFunction<RemoveTwoFaMutation, RemoveTwoFaMutationVariables>;

/**
 * __useRemoveTwoFaMutation__
 *
 * To run a mutation, you first call `useRemoveTwoFaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTwoFaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTwoFaMutation, { data, loading, error }] = useRemoveTwoFaMutation({
 *   variables: {
 *      code: // value for 'code'
 *      codeEntryTime: // value for 'codeEntryTime'
 *   },
 * });
 */
export function useRemoveTwoFaMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTwoFaMutation, RemoveTwoFaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTwoFaMutation, RemoveTwoFaMutationVariables>(RemoveTwoFaDocument, options);
      }
export type RemoveTwoFaMutationHookResult = ReturnType<typeof useRemoveTwoFaMutation>;
export type RemoveTwoFaMutationResult = Apollo.MutationResult<RemoveTwoFaMutation>;
export type RemoveTwoFaMutationOptions = Apollo.BaseMutationOptions<RemoveTwoFaMutation, RemoveTwoFaMutationVariables>;
export const InitializeTwoFaDocument = gql`
    mutation initializeTwoFA($code: String!, $codeEntryTime: Long!) {
  initializeTwoFA(code: $code, codeEntryTime: $codeEntryTime) {
    recoveryCodes
    error
  }
}
    `;
export type InitializeTwoFaMutationFn = Apollo.MutationFunction<InitializeTwoFaMutation, InitializeTwoFaMutationVariables>;

/**
 * __useInitializeTwoFaMutation__
 *
 * To run a mutation, you first call `useInitializeTwoFaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitializeTwoFaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initializeTwoFaMutation, { data, loading, error }] = useInitializeTwoFaMutation({
 *   variables: {
 *      code: // value for 'code'
 *      codeEntryTime: // value for 'codeEntryTime'
 *   },
 * });
 */
export function useInitializeTwoFaMutation(baseOptions?: Apollo.MutationHookOptions<InitializeTwoFaMutation, InitializeTwoFaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitializeTwoFaMutation, InitializeTwoFaMutationVariables>(InitializeTwoFaDocument, options);
      }
export type InitializeTwoFaMutationHookResult = ReturnType<typeof useInitializeTwoFaMutation>;
export type InitializeTwoFaMutationResult = Apollo.MutationResult<InitializeTwoFaMutation>;
export type InitializeTwoFaMutationOptions = Apollo.BaseMutationOptions<InitializeTwoFaMutation, InitializeTwoFaMutationVariables>;
export const VerifyTwoFaCodeDocument = gql`
    mutation verifyTwoFACode($code: String!, $codeEntryTime: Long!, $saveCode: Boolean!) {
  verifyTwoFACode(code: $code, codeEntryTime: $codeEntryTime, saveCode: $saveCode) {
    success
    error
  }
}
    `;
export type VerifyTwoFaCodeMutationFn = Apollo.MutationFunction<VerifyTwoFaCodeMutation, VerifyTwoFaCodeMutationVariables>;

/**
 * __useVerifyTwoFaCodeMutation__
 *
 * To run a mutation, you first call `useVerifyTwoFaCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTwoFaCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTwoFaCodeMutation, { data, loading, error }] = useVerifyTwoFaCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *      codeEntryTime: // value for 'codeEntryTime'
 *      saveCode: // value for 'saveCode'
 *   },
 * });
 */
export function useVerifyTwoFaCodeMutation(baseOptions?: Apollo.MutationHookOptions<VerifyTwoFaCodeMutation, VerifyTwoFaCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyTwoFaCodeMutation, VerifyTwoFaCodeMutationVariables>(VerifyTwoFaCodeDocument, options);
      }
export type VerifyTwoFaCodeMutationHookResult = ReturnType<typeof useVerifyTwoFaCodeMutation>;
export type VerifyTwoFaCodeMutationResult = Apollo.MutationResult<VerifyTwoFaCodeMutation>;
export type VerifyTwoFaCodeMutationOptions = Apollo.BaseMutationOptions<VerifyTwoFaCodeMutation, VerifyTwoFaCodeMutationVariables>;
export const SendCurrentEmailCodeDocument = gql`
    mutation sendCurrentEmailCode {
  sendCurrentEmailCode {
    success
    error
  }
}
    `;
export type SendCurrentEmailCodeMutationFn = Apollo.MutationFunction<SendCurrentEmailCodeMutation, SendCurrentEmailCodeMutationVariables>;

/**
 * __useSendCurrentEmailCodeMutation__
 *
 * To run a mutation, you first call `useSendCurrentEmailCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCurrentEmailCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCurrentEmailCodeMutation, { data, loading, error }] = useSendCurrentEmailCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendCurrentEmailCodeMutation(baseOptions?: Apollo.MutationHookOptions<SendCurrentEmailCodeMutation, SendCurrentEmailCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCurrentEmailCodeMutation, SendCurrentEmailCodeMutationVariables>(SendCurrentEmailCodeDocument, options);
      }
export type SendCurrentEmailCodeMutationHookResult = ReturnType<typeof useSendCurrentEmailCodeMutation>;
export type SendCurrentEmailCodeMutationResult = Apollo.MutationResult<SendCurrentEmailCodeMutation>;
export type SendCurrentEmailCodeMutationOptions = Apollo.BaseMutationOptions<SendCurrentEmailCodeMutation, SendCurrentEmailCodeMutationVariables>;
export const SendEmailCodeDocument = gql`
    mutation sendEmailCode($email: String!) {
  sendEmailCode(email: $email) {
    success
    error
  }
}
    `;
export type SendEmailCodeMutationFn = Apollo.MutationFunction<SendEmailCodeMutation, SendEmailCodeMutationVariables>;

/**
 * __useSendEmailCodeMutation__
 *
 * To run a mutation, you first call `useSendEmailCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailCodeMutation, { data, loading, error }] = useSendEmailCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendEmailCodeMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailCodeMutation, SendEmailCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailCodeMutation, SendEmailCodeMutationVariables>(SendEmailCodeDocument, options);
      }
export type SendEmailCodeMutationHookResult = ReturnType<typeof useSendEmailCodeMutation>;
export type SendEmailCodeMutationResult = Apollo.MutationResult<SendEmailCodeMutation>;
export type SendEmailCodeMutationOptions = Apollo.BaseMutationOptions<SendEmailCodeMutation, SendEmailCodeMutationVariables>;
export const IsEmailFreeDocument = gql`
    query isEmailFree($email: String!) {
  isEmailFree(email: $email)
}
    `;

/**
 * __useIsEmailFreeQuery__
 *
 * To run a query within a React component, call `useIsEmailFreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEmailFreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEmailFreeQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useIsEmailFreeQuery(baseOptions: Apollo.QueryHookOptions<IsEmailFreeQuery, IsEmailFreeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsEmailFreeQuery, IsEmailFreeQueryVariables>(IsEmailFreeDocument, options);
      }
export function useIsEmailFreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsEmailFreeQuery, IsEmailFreeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsEmailFreeQuery, IsEmailFreeQueryVariables>(IsEmailFreeDocument, options);
        }
export type IsEmailFreeQueryHookResult = ReturnType<typeof useIsEmailFreeQuery>;
export type IsEmailFreeLazyQueryHookResult = ReturnType<typeof useIsEmailFreeLazyQuery>;
export type IsEmailFreeQueryResult = Apollo.QueryResult<IsEmailFreeQuery, IsEmailFreeQueryVariables>;
export const VerifyEmailCodeDocument = gql`
    query verifyEmailCode($code: String!, $isCurrentEmail: Boolean!) {
  verifyEmailCode(code: $code, isCurrentEmail: $isCurrentEmail)
}
    `;

/**
 * __useVerifyEmailCodeQuery__
 *
 * To run a query within a React component, call `useVerifyEmailCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyEmailCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *      isCurrentEmail: // value for 'isCurrentEmail'
 *   },
 * });
 */
export function useVerifyEmailCodeQuery(baseOptions: Apollo.QueryHookOptions<VerifyEmailCodeQuery, VerifyEmailCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyEmailCodeQuery, VerifyEmailCodeQueryVariables>(VerifyEmailCodeDocument, options);
      }
export function useVerifyEmailCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyEmailCodeQuery, VerifyEmailCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyEmailCodeQuery, VerifyEmailCodeQueryVariables>(VerifyEmailCodeDocument, options);
        }
export type VerifyEmailCodeQueryHookResult = ReturnType<typeof useVerifyEmailCodeQuery>;
export type VerifyEmailCodeLazyQueryHookResult = ReturnType<typeof useVerifyEmailCodeLazyQuery>;
export type VerifyEmailCodeQueryResult = Apollo.QueryResult<VerifyEmailCodeQuery, VerifyEmailCodeQueryVariables>;
export const ChangeCurrentUserEmailDocument = gql`
    mutation changeCurrentUserEmail($newEmail: String!, $code: String!, $codeEntryTime: Long!) {
  changeCurrentUserEmail(
    newEmail: $newEmail
    code: $code
    codeEntryTime: $codeEntryTime
  ) {
    success
    error
  }
}
    `;
export type ChangeCurrentUserEmailMutationFn = Apollo.MutationFunction<ChangeCurrentUserEmailMutation, ChangeCurrentUserEmailMutationVariables>;

/**
 * __useChangeCurrentUserEmailMutation__
 *
 * To run a mutation, you first call `useChangeCurrentUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCurrentUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCurrentUserEmailMutation, { data, loading, error }] = useChangeCurrentUserEmailMutation({
 *   variables: {
 *      newEmail: // value for 'newEmail'
 *      code: // value for 'code'
 *      codeEntryTime: // value for 'codeEntryTime'
 *   },
 * });
 */
export function useChangeCurrentUserEmailMutation(baseOptions?: Apollo.MutationHookOptions<ChangeCurrentUserEmailMutation, ChangeCurrentUserEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeCurrentUserEmailMutation, ChangeCurrentUserEmailMutationVariables>(ChangeCurrentUserEmailDocument, options);
      }
export type ChangeCurrentUserEmailMutationHookResult = ReturnType<typeof useChangeCurrentUserEmailMutation>;
export type ChangeCurrentUserEmailMutationResult = Apollo.MutationResult<ChangeCurrentUserEmailMutation>;
export type ChangeCurrentUserEmailMutationOptions = Apollo.BaseMutationOptions<ChangeCurrentUserEmailMutation, ChangeCurrentUserEmailMutationVariables>;
export const ChangeCurrentUserRecoveryEmailDocument = gql`
    mutation changeCurrentUserRecoveryEmail($newRecoveryEmail: String!, $code: String!, $codeEntryTime: Long!) {
  changeCurrentUserRecoveryEmail(
    newRecoveryEmail: $newRecoveryEmail
    code: $code
    codeEntryTime: $codeEntryTime
  ) {
    success
    error
  }
}
    `;
export type ChangeCurrentUserRecoveryEmailMutationFn = Apollo.MutationFunction<ChangeCurrentUserRecoveryEmailMutation, ChangeCurrentUserRecoveryEmailMutationVariables>;

/**
 * __useChangeCurrentUserRecoveryEmailMutation__
 *
 * To run a mutation, you first call `useChangeCurrentUserRecoveryEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCurrentUserRecoveryEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCurrentUserRecoveryEmailMutation, { data, loading, error }] = useChangeCurrentUserRecoveryEmailMutation({
 *   variables: {
 *      newRecoveryEmail: // value for 'newRecoveryEmail'
 *      code: // value for 'code'
 *      codeEntryTime: // value for 'codeEntryTime'
 *   },
 * });
 */
export function useChangeCurrentUserRecoveryEmailMutation(baseOptions?: Apollo.MutationHookOptions<ChangeCurrentUserRecoveryEmailMutation, ChangeCurrentUserRecoveryEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeCurrentUserRecoveryEmailMutation, ChangeCurrentUserRecoveryEmailMutationVariables>(ChangeCurrentUserRecoveryEmailDocument, options);
      }
export type ChangeCurrentUserRecoveryEmailMutationHookResult = ReturnType<typeof useChangeCurrentUserRecoveryEmailMutation>;
export type ChangeCurrentUserRecoveryEmailMutationResult = Apollo.MutationResult<ChangeCurrentUserRecoveryEmailMutation>;
export type ChangeCurrentUserRecoveryEmailMutationOptions = Apollo.BaseMutationOptions<ChangeCurrentUserRecoveryEmailMutation, ChangeCurrentUserRecoveryEmailMutationVariables>;
export const ChangeCurrentUserDisplayNameDocument = gql`
    mutation changeCurrentUserDisplayName($newDisplayName: String!, $code: String!, $codeEntryTime: Long!) {
  changeCurrentUserDisplayName(
    newDisplayName: $newDisplayName
    code: $code
    codeEntryTime: $codeEntryTime
  ) {
    success
    error
  }
}
    `;
export type ChangeCurrentUserDisplayNameMutationFn = Apollo.MutationFunction<ChangeCurrentUserDisplayNameMutation, ChangeCurrentUserDisplayNameMutationVariables>;

/**
 * __useChangeCurrentUserDisplayNameMutation__
 *
 * To run a mutation, you first call `useChangeCurrentUserDisplayNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCurrentUserDisplayNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCurrentUserDisplayNameMutation, { data, loading, error }] = useChangeCurrentUserDisplayNameMutation({
 *   variables: {
 *      newDisplayName: // value for 'newDisplayName'
 *      code: // value for 'code'
 *      codeEntryTime: // value for 'codeEntryTime'
 *   },
 * });
 */
export function useChangeCurrentUserDisplayNameMutation(baseOptions?: Apollo.MutationHookOptions<ChangeCurrentUserDisplayNameMutation, ChangeCurrentUserDisplayNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeCurrentUserDisplayNameMutation, ChangeCurrentUserDisplayNameMutationVariables>(ChangeCurrentUserDisplayNameDocument, options);
      }
export type ChangeCurrentUserDisplayNameMutationHookResult = ReturnType<typeof useChangeCurrentUserDisplayNameMutation>;
export type ChangeCurrentUserDisplayNameMutationResult = Apollo.MutationResult<ChangeCurrentUserDisplayNameMutation>;
export type ChangeCurrentUserDisplayNameMutationOptions = Apollo.BaseMutationOptions<ChangeCurrentUserDisplayNameMutation, ChangeCurrentUserDisplayNameMutationVariables>;
export const ChangeCurrentUserWebLinkDocument = gql`
    mutation changeCurrentUserWebLink($webLink: String!) {
  changeCurrentUserWebLink(webLink: $webLink) {
    success
    error
  }
}
    `;
export type ChangeCurrentUserWebLinkMutationFn = Apollo.MutationFunction<ChangeCurrentUserWebLinkMutation, ChangeCurrentUserWebLinkMutationVariables>;

/**
 * __useChangeCurrentUserWebLinkMutation__
 *
 * To run a mutation, you first call `useChangeCurrentUserWebLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCurrentUserWebLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCurrentUserWebLinkMutation, { data, loading, error }] = useChangeCurrentUserWebLinkMutation({
 *   variables: {
 *      webLink: // value for 'webLink'
 *   },
 * });
 */
export function useChangeCurrentUserWebLinkMutation(baseOptions?: Apollo.MutationHookOptions<ChangeCurrentUserWebLinkMutation, ChangeCurrentUserWebLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeCurrentUserWebLinkMutation, ChangeCurrentUserWebLinkMutationVariables>(ChangeCurrentUserWebLinkDocument, options);
      }
export type ChangeCurrentUserWebLinkMutationHookResult = ReturnType<typeof useChangeCurrentUserWebLinkMutation>;
export type ChangeCurrentUserWebLinkMutationResult = Apollo.MutationResult<ChangeCurrentUserWebLinkMutation>;
export type ChangeCurrentUserWebLinkMutationOptions = Apollo.BaseMutationOptions<ChangeCurrentUserWebLinkMutation, ChangeCurrentUserWebLinkMutationVariables>;
export const ChangeCurrentUserBioDocument = gql`
    mutation changeCurrentUserBio($bio: String!) {
  changeCurrentUserBio(bio: $bio) {
    success
    error
  }
}
    `;
export type ChangeCurrentUserBioMutationFn = Apollo.MutationFunction<ChangeCurrentUserBioMutation, ChangeCurrentUserBioMutationVariables>;

/**
 * __useChangeCurrentUserBioMutation__
 *
 * To run a mutation, you first call `useChangeCurrentUserBioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCurrentUserBioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCurrentUserBioMutation, { data, loading, error }] = useChangeCurrentUserBioMutation({
 *   variables: {
 *      bio: // value for 'bio'
 *   },
 * });
 */
export function useChangeCurrentUserBioMutation(baseOptions?: Apollo.MutationHookOptions<ChangeCurrentUserBioMutation, ChangeCurrentUserBioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeCurrentUserBioMutation, ChangeCurrentUserBioMutationVariables>(ChangeCurrentUserBioDocument, options);
      }
export type ChangeCurrentUserBioMutationHookResult = ReturnType<typeof useChangeCurrentUserBioMutation>;
export type ChangeCurrentUserBioMutationResult = Apollo.MutationResult<ChangeCurrentUserBioMutation>;
export type ChangeCurrentUserBioMutationOptions = Apollo.BaseMutationOptions<ChangeCurrentUserBioMutation, ChangeCurrentUserBioMutationVariables>;
export const ChangeCurrentUserCountryDocument = gql`
    mutation changeCurrentUserCountry($countryCode: String) {
  changeCurrentUserCountry(countryCode: $countryCode) {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;
export type ChangeCurrentUserCountryMutationFn = Apollo.MutationFunction<ChangeCurrentUserCountryMutation, ChangeCurrentUserCountryMutationVariables>;

/**
 * __useChangeCurrentUserCountryMutation__
 *
 * To run a mutation, you first call `useChangeCurrentUserCountryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCurrentUserCountryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCurrentUserCountryMutation, { data, loading, error }] = useChangeCurrentUserCountryMutation({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useChangeCurrentUserCountryMutation(baseOptions?: Apollo.MutationHookOptions<ChangeCurrentUserCountryMutation, ChangeCurrentUserCountryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeCurrentUserCountryMutation, ChangeCurrentUserCountryMutationVariables>(ChangeCurrentUserCountryDocument, options);
      }
export type ChangeCurrentUserCountryMutationHookResult = ReturnType<typeof useChangeCurrentUserCountryMutation>;
export type ChangeCurrentUserCountryMutationResult = Apollo.MutationResult<ChangeCurrentUserCountryMutation>;
export type ChangeCurrentUserCountryMutationOptions = Apollo.BaseMutationOptions<ChangeCurrentUserCountryMutation, ChangeCurrentUserCountryMutationVariables>;
export const ChangeCurrentUserShowActiveStatusDocument = gql`
    mutation changeCurrentUserShowActiveStatus($status: Boolean!) {
  changeCurrentUserShowActiveStatus(status: $status) {
    success
    error
  }
}
    `;
export type ChangeCurrentUserShowActiveStatusMutationFn = Apollo.MutationFunction<ChangeCurrentUserShowActiveStatusMutation, ChangeCurrentUserShowActiveStatusMutationVariables>;

/**
 * __useChangeCurrentUserShowActiveStatusMutation__
 *
 * To run a mutation, you first call `useChangeCurrentUserShowActiveStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCurrentUserShowActiveStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCurrentUserShowActiveStatusMutation, { data, loading, error }] = useChangeCurrentUserShowActiveStatusMutation({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useChangeCurrentUserShowActiveStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeCurrentUserShowActiveStatusMutation, ChangeCurrentUserShowActiveStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeCurrentUserShowActiveStatusMutation, ChangeCurrentUserShowActiveStatusMutationVariables>(ChangeCurrentUserShowActiveStatusDocument, options);
      }
export type ChangeCurrentUserShowActiveStatusMutationHookResult = ReturnType<typeof useChangeCurrentUserShowActiveStatusMutation>;
export type ChangeCurrentUserShowActiveStatusMutationResult = Apollo.MutationResult<ChangeCurrentUserShowActiveStatusMutation>;
export type ChangeCurrentUserShowActiveStatusMutationOptions = Apollo.BaseMutationOptions<ChangeCurrentUserShowActiveStatusMutation, ChangeCurrentUserShowActiveStatusMutationVariables>;
export const CurrentUserUpdateSubscriptionUrlDocument = gql`
    query currentUserUpdateSubscriptionURL($planCode: String!) {
  currentUserUpdateSubscriptionURL(planCode: $planCode)
}
    `;

/**
 * __useCurrentUserUpdateSubscriptionUrlQuery__
 *
 * To run a query within a React component, call `useCurrentUserUpdateSubscriptionUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserUpdateSubscriptionUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserUpdateSubscriptionUrlQuery({
 *   variables: {
 *      planCode: // value for 'planCode'
 *   },
 * });
 */
export function useCurrentUserUpdateSubscriptionUrlQuery(baseOptions: Apollo.QueryHookOptions<CurrentUserUpdateSubscriptionUrlQuery, CurrentUserUpdateSubscriptionUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserUpdateSubscriptionUrlQuery, CurrentUserUpdateSubscriptionUrlQueryVariables>(CurrentUserUpdateSubscriptionUrlDocument, options);
      }
export function useCurrentUserUpdateSubscriptionUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserUpdateSubscriptionUrlQuery, CurrentUserUpdateSubscriptionUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserUpdateSubscriptionUrlQuery, CurrentUserUpdateSubscriptionUrlQueryVariables>(CurrentUserUpdateSubscriptionUrlDocument, options);
        }
export type CurrentUserUpdateSubscriptionUrlQueryHookResult = ReturnType<typeof useCurrentUserUpdateSubscriptionUrlQuery>;
export type CurrentUserUpdateSubscriptionUrlLazyQueryHookResult = ReturnType<typeof useCurrentUserUpdateSubscriptionUrlLazyQuery>;
export type CurrentUserUpdateSubscriptionUrlQueryResult = Apollo.QueryResult<CurrentUserUpdateSubscriptionUrlQuery, CurrentUserUpdateSubscriptionUrlQueryVariables>;
export const ListGenresDocument = gql`
    query listGenres {
  listGenres {
    genreIDHash
    name
    description
  }
}
    `;

/**
 * __useListGenresQuery__
 *
 * To run a query within a React component, call `useListGenresQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGenresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGenresQuery({
 *   variables: {
 *   },
 * });
 */
export function useListGenresQuery(baseOptions?: Apollo.QueryHookOptions<ListGenresQuery, ListGenresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListGenresQuery, ListGenresQueryVariables>(ListGenresDocument, options);
      }
export function useListGenresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListGenresQuery, ListGenresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListGenresQuery, ListGenresQueryVariables>(ListGenresDocument, options);
        }
export type ListGenresQueryHookResult = ReturnType<typeof useListGenresQuery>;
export type ListGenresLazyQueryHookResult = ReturnType<typeof useListGenresLazyQuery>;
export type ListGenresQueryResult = Apollo.QueryResult<ListGenresQuery, ListGenresQueryVariables>;
export const ListUserGenresDocument = gql`
    query listUserGenres {
  listUserGenres
}
    `;

/**
 * __useListUserGenresQuery__
 *
 * To run a query within a React component, call `useListUserGenresQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserGenresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserGenresQuery({
 *   variables: {
 *   },
 * });
 */
export function useListUserGenresQuery(baseOptions?: Apollo.QueryHookOptions<ListUserGenresQuery, ListUserGenresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUserGenresQuery, ListUserGenresQueryVariables>(ListUserGenresDocument, options);
      }
export function useListUserGenresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUserGenresQuery, ListUserGenresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUserGenresQuery, ListUserGenresQueryVariables>(ListUserGenresDocument, options);
        }
export type ListUserGenresQueryHookResult = ReturnType<typeof useListUserGenresQuery>;
export type ListUserGenresLazyQueryHookResult = ReturnType<typeof useListUserGenresLazyQuery>;
export type ListUserGenresQueryResult = Apollo.QueryResult<ListUserGenresQuery, ListUserGenresQueryVariables>;
export const ListUserDraftsDocument = gql`
    query listUserDrafts {
  listUserDrafts {
    draftIDHash
    title
    subtitle
    created
    modified
  }
}
    `;

/**
 * __useListUserDraftsQuery__
 *
 * To run a query within a React component, call `useListUserDraftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserDraftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserDraftsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListUserDraftsQuery(baseOptions?: Apollo.QueryHookOptions<ListUserDraftsQuery, ListUserDraftsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUserDraftsQuery, ListUserDraftsQueryVariables>(ListUserDraftsDocument, options);
      }
export function useListUserDraftsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUserDraftsQuery, ListUserDraftsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUserDraftsQuery, ListUserDraftsQueryVariables>(ListUserDraftsDocument, options);
        }
export type ListUserDraftsQueryHookResult = ReturnType<typeof useListUserDraftsQuery>;
export type ListUserDraftsLazyQueryHookResult = ReturnType<typeof useListUserDraftsLazyQuery>;
export type ListUserDraftsQueryResult = Apollo.QueryResult<ListUserDraftsQuery, ListUserDraftsQueryVariables>;
export const UpdateUserGenresDocument = gql`
    mutation updateUserGenres($genreIDHashes: [String!]!) {
  updateUserGenres(genreIDHashes: $genreIDHashes) {
    success
    error
  }
}
    `;
export type UpdateUserGenresMutationFn = Apollo.MutationFunction<UpdateUserGenresMutation, UpdateUserGenresMutationVariables>;

/**
 * __useUpdateUserGenresMutation__
 *
 * To run a mutation, you first call `useUpdateUserGenresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserGenresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserGenresMutation, { data, loading, error }] = useUpdateUserGenresMutation({
 *   variables: {
 *      genreIDHashes: // value for 'genreIDHashes'
 *   },
 * });
 */
export function useUpdateUserGenresMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserGenresMutation, UpdateUserGenresMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserGenresMutation, UpdateUserGenresMutationVariables>(UpdateUserGenresDocument, options);
      }
export type UpdateUserGenresMutationHookResult = ReturnType<typeof useUpdateUserGenresMutation>;
export type UpdateUserGenresMutationResult = Apollo.MutationResult<UpdateUserGenresMutation>;
export type UpdateUserGenresMutationOptions = Apollo.BaseMutationOptions<UpdateUserGenresMutation, UpdateUserGenresMutationVariables>;
export const ListCountriesDocument = gql`
    query listCountries {
  listCountries {
    countryCode
    name
  }
}
    `;

/**
 * __useListCountriesQuery__
 *
 * To run a query within a React component, call `useListCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListCountriesQuery(baseOptions?: Apollo.QueryHookOptions<ListCountriesQuery, ListCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCountriesQuery, ListCountriesQueryVariables>(ListCountriesDocument, options);
      }
export function useListCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCountriesQuery, ListCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCountriesQuery, ListCountriesQueryVariables>(ListCountriesDocument, options);
        }
export type ListCountriesQueryHookResult = ReturnType<typeof useListCountriesQuery>;
export type ListCountriesLazyQueryHookResult = ReturnType<typeof useListCountriesLazyQuery>;
export type ListCountriesQueryResult = Apollo.QueryResult<ListCountriesQuery, ListCountriesQueryVariables>;
export const MediaSasDocument = gql`
    query mediaSAS($importSizeBytes: Long!) {
  mediaSAS(importSizeBytes: $importSizeBytes) {
    value
    error
  }
}
    `;

/**
 * __useMediaSasQuery__
 *
 * To run a query within a React component, call `useMediaSasQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaSasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaSasQuery({
 *   variables: {
 *      importSizeBytes: // value for 'importSizeBytes'
 *   },
 * });
 */
export function useMediaSasQuery(baseOptions: Apollo.QueryHookOptions<MediaSasQuery, MediaSasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MediaSasQuery, MediaSasQueryVariables>(MediaSasDocument, options);
      }
export function useMediaSasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MediaSasQuery, MediaSasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MediaSasQuery, MediaSasQueryVariables>(MediaSasDocument, options);
        }
export type MediaSasQueryHookResult = ReturnType<typeof useMediaSasQuery>;
export type MediaSasLazyQueryHookResult = ReturnType<typeof useMediaSasLazyQuery>;
export type MediaSasQueryResult = Apollo.QueryResult<MediaSasQuery, MediaSasQueryVariables>;
export const CreateMediaDocument = gql`
    mutation createMedia($title: String!, $tags: String, $fileType: String!, $fileSize: Long!, $width: Short!, $height: Short!) {
  createMedia(
    title: $title
    tags: $tags
    fileType: $fileType
    fileSize: $fileSize
    width: $width
    height: $height
  ) {
    value {
      mediaLibraryIDHash
      imageId
      filePath
      thumbFilePath
    }
    error
  }
}
    `;
export type CreateMediaMutationFn = Apollo.MutationFunction<CreateMediaMutation, CreateMediaMutationVariables>;

/**
 * __useCreateMediaMutation__
 *
 * To run a mutation, you first call `useCreateMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaMutation, { data, loading, error }] = useCreateMediaMutation({
 *   variables: {
 *      title: // value for 'title'
 *      tags: // value for 'tags'
 *      fileType: // value for 'fileType'
 *      fileSize: // value for 'fileSize'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *   },
 * });
 */
export function useCreateMediaMutation(baseOptions?: Apollo.MutationHookOptions<CreateMediaMutation, CreateMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMediaMutation, CreateMediaMutationVariables>(CreateMediaDocument, options);
      }
export type CreateMediaMutationHookResult = ReturnType<typeof useCreateMediaMutation>;
export type CreateMediaMutationResult = Apollo.MutationResult<CreateMediaMutation>;
export type CreateMediaMutationOptions = Apollo.BaseMutationOptions<CreateMediaMutation, CreateMediaMutationVariables>;
export const MediaUploadCompleteDocument = gql`
    mutation mediaUploadComplete($mediaLibraryIDHash: String!) {
  mediaUploadComplete(mediaLibraryIDHash: $mediaLibraryIDHash) {
    success
    error
  }
}
    `;
export type MediaUploadCompleteMutationFn = Apollo.MutationFunction<MediaUploadCompleteMutation, MediaUploadCompleteMutationVariables>;

/**
 * __useMediaUploadCompleteMutation__
 *
 * To run a mutation, you first call `useMediaUploadCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaUploadCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaUploadCompleteMutation, { data, loading, error }] = useMediaUploadCompleteMutation({
 *   variables: {
 *      mediaLibraryIDHash: // value for 'mediaLibraryIDHash'
 *   },
 * });
 */
export function useMediaUploadCompleteMutation(baseOptions?: Apollo.MutationHookOptions<MediaUploadCompleteMutation, MediaUploadCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MediaUploadCompleteMutation, MediaUploadCompleteMutationVariables>(MediaUploadCompleteDocument, options);
      }
export type MediaUploadCompleteMutationHookResult = ReturnType<typeof useMediaUploadCompleteMutation>;
export type MediaUploadCompleteMutationResult = Apollo.MutationResult<MediaUploadCompleteMutation>;
export type MediaUploadCompleteMutationOptions = Apollo.BaseMutationOptions<MediaUploadCompleteMutation, MediaUploadCompleteMutationVariables>;
export const CompleteRegistrationDocument = gql`
    mutation completeRegistration {
  completeRegistration {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;
export type CompleteRegistrationMutationFn = Apollo.MutationFunction<CompleteRegistrationMutation, CompleteRegistrationMutationVariables>;

/**
 * __useCompleteRegistrationMutation__
 *
 * To run a mutation, you first call `useCompleteRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeRegistrationMutation, { data, loading, error }] = useCompleteRegistrationMutation({
 *   variables: {
 *   },
 * });
 */
export function useCompleteRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<CompleteRegistrationMutation, CompleteRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteRegistrationMutation, CompleteRegistrationMutationVariables>(CompleteRegistrationDocument, options);
      }
export type CompleteRegistrationMutationHookResult = ReturnType<typeof useCompleteRegistrationMutation>;
export type CompleteRegistrationMutationResult = Apollo.MutationResult<CompleteRegistrationMutation>;
export type CompleteRegistrationMutationOptions = Apollo.BaseMutationOptions<CompleteRegistrationMutation, CompleteRegistrationMutationVariables>;
export const UserDraftDocument = gql`
    query userDraft($draftIDHash: String!) {
  userDraft(draftIDHash: $draftIDHash) {
    draftIDHash
    title
    subtitle
    created
    modified
    virtualSections {
      draftContentIDHash
      content
    }
    error
  }
}
    `;

/**
 * __useUserDraftQuery__
 *
 * To run a query within a React component, call `useUserDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDraftQuery({
 *   variables: {
 *      draftIDHash: // value for 'draftIDHash'
 *   },
 * });
 */
export function useUserDraftQuery(baseOptions: Apollo.QueryHookOptions<UserDraftQuery, UserDraftQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserDraftQuery, UserDraftQueryVariables>(UserDraftDocument, options);
      }
export function useUserDraftLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserDraftQuery, UserDraftQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserDraftQuery, UserDraftQueryVariables>(UserDraftDocument, options);
        }
export type UserDraftQueryHookResult = ReturnType<typeof useUserDraftQuery>;
export type UserDraftLazyQueryHookResult = ReturnType<typeof useUserDraftLazyQuery>;
export type UserDraftQueryResult = Apollo.QueryResult<UserDraftQuery, UserDraftQueryVariables>;
export const PickerPhotosDocument = gql`
    query pickerPhotos($pickerType: PhotoPickerType!, $initialSearch: Boolean!, $totalRecords: Int, $pageSize: Int!, $pageNumber: Int!, $query: String!) {
  pickerPhotos(
    pickerType: $pickerType
    initialSearch: $initialSearch
    totalRecords: $totalRecords
    pageSize: $pageSize
    pageNumber: $pageNumber
    query: $query
  ) {
    ...PickerPhotos
  }
}
    ${PickerPhotosFragmentDoc}`;

/**
 * __usePickerPhotosQuery__
 *
 * To run a query within a React component, call `usePickerPhotosQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickerPhotosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickerPhotosQuery({
 *   variables: {
 *      pickerType: // value for 'pickerType'
 *      initialSearch: // value for 'initialSearch'
 *      totalRecords: // value for 'totalRecords'
 *      pageSize: // value for 'pageSize'
 *      pageNumber: // value for 'pageNumber'
 *      query: // value for 'query'
 *   },
 * });
 */
export function usePickerPhotosQuery(baseOptions: Apollo.QueryHookOptions<PickerPhotosQuery, PickerPhotosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PickerPhotosQuery, PickerPhotosQueryVariables>(PickerPhotosDocument, options);
      }
export function usePickerPhotosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PickerPhotosQuery, PickerPhotosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PickerPhotosQuery, PickerPhotosQueryVariables>(PickerPhotosDocument, options);
        }
export type PickerPhotosQueryHookResult = ReturnType<typeof usePickerPhotosQuery>;
export type PickerPhotosLazyQueryHookResult = ReturnType<typeof usePickerPhotosLazyQuery>;
export type PickerPhotosQueryResult = Apollo.QueryResult<PickerPhotosQuery, PickerPhotosQueryVariables>;
export const CreateDraftDocument = gql`
    mutation createDraft($title: String!, $subtitle: String) {
  createDraft(title: $title, subtitle: $subtitle) {
    value
    error
  }
}
    `;
export type CreateDraftMutationFn = Apollo.MutationFunction<CreateDraftMutation, CreateDraftMutationVariables>;

/**
 * __useCreateDraftMutation__
 *
 * To run a mutation, you first call `useCreateDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftMutation, { data, loading, error }] = useCreateDraftMutation({
 *   variables: {
 *      title: // value for 'title'
 *      subtitle: // value for 'subtitle'
 *   },
 * });
 */
export function useCreateDraftMutation(baseOptions?: Apollo.MutationHookOptions<CreateDraftMutation, CreateDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDraftMutation, CreateDraftMutationVariables>(CreateDraftDocument, options);
      }
export type CreateDraftMutationHookResult = ReturnType<typeof useCreateDraftMutation>;
export type CreateDraftMutationResult = Apollo.MutationResult<CreateDraftMutation>;
export type CreateDraftMutationOptions = Apollo.BaseMutationOptions<CreateDraftMutation, CreateDraftMutationVariables>;
export const SaveDraftContentDocument = gql`
    mutation saveDraftContent($draftContentIDHash: String!, $content: String!) {
  saveDraftContent(draftContentIDHash: $draftContentIDHash, content: $content) {
    success
    error
  }
}
    `;
export type SaveDraftContentMutationFn = Apollo.MutationFunction<SaveDraftContentMutation, SaveDraftContentMutationVariables>;

/**
 * __useSaveDraftContentMutation__
 *
 * To run a mutation, you first call `useSaveDraftContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDraftContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDraftContentMutation, { data, loading, error }] = useSaveDraftContentMutation({
 *   variables: {
 *      draftContentIDHash: // value for 'draftContentIDHash'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useSaveDraftContentMutation(baseOptions?: Apollo.MutationHookOptions<SaveDraftContentMutation, SaveDraftContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDraftContentMutation, SaveDraftContentMutationVariables>(SaveDraftContentDocument, options);
      }
export type SaveDraftContentMutationHookResult = ReturnType<typeof useSaveDraftContentMutation>;
export type SaveDraftContentMutationResult = Apollo.MutationResult<SaveDraftContentMutation>;
export type SaveDraftContentMutationOptions = Apollo.BaseMutationOptions<SaveDraftContentMutation, SaveDraftContentMutationVariables>;
export const DeleteDraftDocument = gql`
    mutation deleteDraft($draftIDHash: String!) {
  deleteDraft(draftIDHash: $draftIDHash) {
    success
    error
  }
}
    `;
export type DeleteDraftMutationFn = Apollo.MutationFunction<DeleteDraftMutation, DeleteDraftMutationVariables>;

/**
 * __useDeleteDraftMutation__
 *
 * To run a mutation, you first call `useDeleteDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDraftMutation, { data, loading, error }] = useDeleteDraftMutation({
 *   variables: {
 *      draftIDHash: // value for 'draftIDHash'
 *   },
 * });
 */
export function useDeleteDraftMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDraftMutation, DeleteDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDraftMutation, DeleteDraftMutationVariables>(DeleteDraftDocument, options);
      }
export type DeleteDraftMutationHookResult = ReturnType<typeof useDeleteDraftMutation>;
export type DeleteDraftMutationResult = Apollo.MutationResult<DeleteDraftMutation>;
export type DeleteDraftMutationOptions = Apollo.BaseMutationOptions<DeleteDraftMutation, DeleteDraftMutationVariables>;
export const UpdateDraftTitleAndSubtitleDocument = gql`
    mutation updateDraftTitleAndSubtitle($draftIDHash: String!, $title: String!, $subtitle: String) {
  updateDraftTitleAndSubtitle(
    draftIDHash: $draftIDHash
    title: $title
    subtitle: $subtitle
  ) {
    success
    error
  }
}
    `;
export type UpdateDraftTitleAndSubtitleMutationFn = Apollo.MutationFunction<UpdateDraftTitleAndSubtitleMutation, UpdateDraftTitleAndSubtitleMutationVariables>;

/**
 * __useUpdateDraftTitleAndSubtitleMutation__
 *
 * To run a mutation, you first call `useUpdateDraftTitleAndSubtitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftTitleAndSubtitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftTitleAndSubtitleMutation, { data, loading, error }] = useUpdateDraftTitleAndSubtitleMutation({
 *   variables: {
 *      draftIDHash: // value for 'draftIDHash'
 *      title: // value for 'title'
 *      subtitle: // value for 'subtitle'
 *   },
 * });
 */
export function useUpdateDraftTitleAndSubtitleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDraftTitleAndSubtitleMutation, UpdateDraftTitleAndSubtitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDraftTitleAndSubtitleMutation, UpdateDraftTitleAndSubtitleMutationVariables>(UpdateDraftTitleAndSubtitleDocument, options);
      }
export type UpdateDraftTitleAndSubtitleMutationHookResult = ReturnType<typeof useUpdateDraftTitleAndSubtitleMutation>;
export type UpdateDraftTitleAndSubtitleMutationResult = Apollo.MutationResult<UpdateDraftTitleAndSubtitleMutation>;
export type UpdateDraftTitleAndSubtitleMutationOptions = Apollo.BaseMutationOptions<UpdateDraftTitleAndSubtitleMutation, UpdateDraftTitleAndSubtitleMutationVariables>;
export const LogErrorDocument = gql`
    mutation logError($error: ErrorInput!, $errorInfo: ErrorInfoInput!) {
  logError(error: $error, errorInfo: $errorInfo)
}
    `;
export type LogErrorMutationFn = Apollo.MutationFunction<LogErrorMutation, LogErrorMutationVariables>;

/**
 * __useLogErrorMutation__
 *
 * To run a mutation, you first call `useLogErrorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogErrorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logErrorMutation, { data, loading, error }] = useLogErrorMutation({
 *   variables: {
 *      error: // value for 'error'
 *      errorInfo: // value for 'errorInfo'
 *   },
 * });
 */
export function useLogErrorMutation(baseOptions?: Apollo.MutationHookOptions<LogErrorMutation, LogErrorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogErrorMutation, LogErrorMutationVariables>(LogErrorDocument, options);
      }
export type LogErrorMutationHookResult = ReturnType<typeof useLogErrorMutation>;
export type LogErrorMutationResult = Apollo.MutationResult<LogErrorMutation>;
export type LogErrorMutationOptions = Apollo.BaseMutationOptions<LogErrorMutation, LogErrorMutationVariables>;