export enum GlobalCacheKeys {
    /* Authorization */
    AUTHORIZATION_ACCESS_TOKEN = "AUTHORIZATION_ACCESS_TOKEN",
    AUTHENTICATION_ACTIVE_AUTHORITY = "AUTHENTICATION_ACTIVE_AUTHORITY",

    /* user Management */
    CURRENT_USER = "CURRENT_USER",
    
    /* miscellaneous */
    LAST_VISITED_PAGE = "LAST_VISITED_PAGE"
}