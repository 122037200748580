import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grow, TextField, Typography } from "@mui/material";
import moment from 'moment';
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { toast } from "react-toastify";
import { TwoFAVerificationResult } from "../ServiceWrapper";
import NavigateNext from "@mui/icons-material/NavigateNext";
import CancelIcon from "@mui/icons-material/Cancel";


export interface IAuthenticatorReference {
  showAuthenticatorCodeVerification: (successCallback: (result: TwoFAVerificationResult) => Promise<boolean>, cancelCallback?: () => void) => void
}

const AuthenticatorController = forwardRef<IAuthenticatorReference>((props, ref) => {
  
  const [open, setOpen] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [twoFACode, setTwoFACode] = useState<string>("");
  const [codeTime, setCodeTime] = useState<number>(0);
  const [successCallback, setSuccessCallback] = useState<((result: TwoFAVerificationResult) => Promise<boolean>) | null>(null);
  const [cancelCallback, setCancelCallback] = useState<(() => void) | null>(null);
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleModalClose = (event: any, reason: any) => {

    if (reason && reason === "backdropClick") 
        return;

    handleClose();
    
    if (cancelCallback)
      cancelCallback();
  };

  
  const verifyTwoFA = () => {

    if (twoFACode === "") {
      toast.error('You must enter a code first.');
      return;
    }

    setProcessing(true);
    if (successCallback)
      successCallback({ code: twoFACode, time: codeTime}).then((val: boolean) => {
        setProcessing(false);
        if (val)
          setOpen(false);
      });

  };

  const showAuthenticatorCodeVerification = (successCallback: (result: TwoFAVerificationResult) => Promise<boolean>, cancelCallback?: () => void) => {
    setTwoFACode('');
    setSuccessCallback(() => successCallback);
    
    if (cancelCallback)
      setCancelCallback(() => cancelCallback);
    
      setOpen(true);
  }
  
  useImperativeHandle(ref, () => ({
    showAuthenticatorCodeVerification
  }));

  const handleTwoFACodeChange = (event: any) => {
    setTwoFACode(event.target.value);
    setCodeTime(moment().unix());
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Grow as React.ComponentType}
      keepMounted
      fullWidth={true}
      maxWidth={'xs'}
      hideBackdrop={true}
      onClose={handleModalClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Two-Factor Verification</DialogTitle>
      <DialogContent>
        <Typography>Enter the code generated by your authenticator app or one of the recovery codes if you can't access your device.</Typography>
        <TextField id="twoFACode" type="text" onChange={handleTwoFACodeChange} inputProps={{maxLength: 8}} value={twoFACode} style={{marginTop:10}} label="Code" variant="standard" />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={() => {handleModalClose(null, null);}}><CancelIcon color="secondary" style={{marginRight:5}}/>Cancel</Button>
        <Button color="primary" variant="contained" onClick={() => {verifyTwoFA()}}>{processing && <CircularProgress size={20} />}{!processing && <>Next<NavigateNext style={{marginLeft:5}}/></>}</Button>
      </DialogActions>
    </Dialog>
  );
});

export default AuthenticatorController;