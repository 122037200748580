import { useApolloClient } from "@apollo/client";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal
} from "@azure/msal-react";
import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, Button, styled, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useContext, useMemo, useState } from "react";
import { useNavigate, useHref, useLocation } from "react-router-dom";
import headerLogo from "../images/MiadaraLogoText.png";
import headerLogoMark from "../images/inkwell_blueink_v2_100x100.png";
import { UserContext } from "../ServiceWrapper";
import { b2cPolicies } from "../authConfig";
import { GlobalCacheKeys } from "../cache/globalCacheKeys";
import { getCurrentPage } from "../shared/urlUtility";
import CreateIcon from "@mui/icons-material/Create";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";

export default function Header() {
  const apolloClient = useApolloClient();
  const { instance,accounts } = useMsal();
  const navigate = useNavigate();
  const loc = useLocation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { currentUser, globalCache } = useContext(UserContext);
  const theme = useTheme();
  const avatarURL = useMemo(() => {
    let url = `${process.env.REACT_APP_CLOUDINARY_URL}c_scale,h_50,w_50/v${currentUser?.avatarVersion}/users/${currentUser?.userIDHash}/avatar.jpg`;
    return url;
  }, [currentUser]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    navigate('/accountSettings/profile');
    handleClose();
  };

  const handleCreate = () => {
    navigate('/createContent/drafts');
    handleClose();
  };

  const handleSignout = () => {
    setAnchorEl(null);
    let activeAuthority = globalCache.getItem(GlobalCacheKeys.AUTHENTICATION_ACTIVE_AUTHORITY);
    instance.logoutRedirect({
      authority: (activeAuthority ? activeAuthority : b2cPolicies.authorities.signUpSignIn.authority)
    });
  };

  const handleSignin = () => {
    //instance.loginRedirect();
    let currentPage = getCurrentPage();
    globalCache.setItem(GlobalCacheKeys.LAST_VISITED_PAGE,  currentPage);
    globalCache.setItem(GlobalCacheKeys.AUTHENTICATION_ACTIVE_AUTHORITY, b2cPolicies.authorities.signUpSignIn.authority);
    instance.loginRedirect({
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      scopes: [],

    });
  };

  const handleSignup = () => {
    let currentPage = getCurrentPage();
    globalCache.setItem(GlobalCacheKeys.LAST_VISITED_PAGE,  currentPage);
    globalCache.setItem(GlobalCacheKeys.AUTHENTICATION_ACTIVE_AUTHORITY, b2cPolicies.authorities.signUp.authority);
    instance.loginRedirect({
      authority: b2cPolicies.authorities.signUp.authority,
      scopes: [],
    });
  }
  const goHome = () => {
    navigate('/');
  }

  const StyledMenu = styled(Menu)`
    paper: {
      border: '1px solid #d3d4d5',
    }
  `;

  const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    "&.Mui-selected, &.Mui-selected:focus" : {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
    },
    "&:hover" : {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
      }
}));

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{background:'white', display:'flex', alignItems:'center'}}>
        <div className="contentWidth">
          <Toolbar sx={{ }}>
            <Box sx={{ flexGrow: 0, display:'flex', alignItems: 'center' }} onClick={goHome} style={{cursor:'pointer'}}>
              <img id="headerLogoMark" src={headerLogoMark} style={{height:45, width:45}}></img>
              <img id="headerLogo" src={headerLogo} style={{height:52, width:213,marginLeft:10}}></img>
            </Box>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            ></Typography>
            <AuthenticatedTemplate>
              <Box sx={{ flexGrow: 0 }}>
                <IconButton onClick={handleMenu} sx={{ p: 0 }}>
                  {currentUser?.avatarVersion && (
                    <Avatar alt="User Avatar" src={avatarURL} style={{width:45, height:45}} />
                  )}
                  {!currentUser?.avatarVersion && <Avatar alt="User Avatar" />}
                </IconButton>
                <StyledMenu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <StyledMenuItem onClick={handleProfile}><SettingsIcon style={{marginRight:10}}/> Account Details</StyledMenuItem>
                  <StyledMenuItem onClick={handleCreate}><CreateIcon style={{marginRight:10}}/> Creator Tools</StyledMenuItem>
                  <StyledMenuItem  onClick={handleSignout}><LogoutIcon style={{marginRight:10}}/> Sign Out</StyledMenuItem>
                </StyledMenu>
              </Box>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Button
                onClick={() => {
                  handleSignin();
                }}
                style={{ fontWeight:'bold' }}
              >
                Sign In
              </Button>
              <span color={theme.palette.primary.main}>test</span>
              <Button
                color="primary"
                onClick={() => {
                  handleSignup();
                }}
                style={{ fontWeight:'bold' }}
              >
                Join
              </Button>
              
            </UnauthenticatedTemplate>
          </Toolbar>
        </div>
        <div style={{height:3, width:'100%'}} className="appBarHeaderBorder">
              &nbsp;
        </div>  
      </AppBar>
      
    </Box>
  );
}
