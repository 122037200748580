import { ApolloError } from "@apollo/client";
import { useMsal } from "@azure/msal-react";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import NavigateBack from "@mui/icons-material/NavigateBefore";
import NavigateNext from "@mui/icons-material/NavigateNext";
import InfoIcon from "@mui/icons-material/Info";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grow,
  Input,
  InputLabel,
  Select,
  SelectChangeEvent,
  styled,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Multiselect from "multiselect-react-dropdown";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ChangeAvatarVersionMutation,
  ChangeCurrentUserCountryMutation,
  ChangeCurrentUserDisplayNameMutation,
  ChangeCurrentUserEmailMutation,
  ChangeCurrentUserShowActiveStatusMutation,
  ChangeCurrentUserShowActiveStatusMutationFn,
  ChangeCurrentUserWebLinkMutation,
  CompleteRegistrationMutation,
  CountryDto,
  GenreDto,
  IsEmailFreeQueryResult,
  ListCountriesQuery,
  ListGenresQuery,
  ListUserGenresQuery,
  SendEmailCodeMutation,
  UpdateUserGenresMutation,
  useChangeAvatarVersionMutation,
  useChangeCurrentUserBioMutation,
  useChangeCurrentUserCountryMutation,
  useChangeCurrentUserDisplayNameMutation,
  useChangeCurrentUserEmailMutation,
  useChangeCurrentUserShowActiveStatusMutation,
  useChangeCurrentUserWebLinkMutation,
  useCompleteRegistrationMutation,
  useIsEmailFreeLazyQuery,
  useListCountriesLazyQuery,
  useListGenresLazyQuery,
  useListUserGenresLazyQuery,
  useProcessRegistrationPlanMutation,
  useRegisterUserMutation,
  useSendEmailCodeMutation,
  useUpdateUserGenresMutation,
  useVerifyEmailCodeLazyQuery,
  useVerifyTwoFaCodeMutation,
  VerifyEmailCodeQueryResult,
} from "../../generated";
import { useScript } from "../../hooks/useScript";
import { TwoFAVerificationResult, UserContext } from "../../ServiceWrapper";
import UploadWidget from "../cloudinary/UploadWidget";
import EditableMultiSelectInputComponent from "../shared/editableMultiSelectInput";
import EditableTextInputComponent from "../shared/editableTextInput";
import { MultiSelectOption } from "../shared/formUtility";
import ToolTipComponent from "../shared/toolTip";
import styles from "../../styles/profile.module.css";
import AutocompleteInputComponent from "../shared/autoCompleteInput";
//import styled from "styled-components";

/*
${(props) => props.theme.breakpoints.down("mobile")} {
    & .MuiInputBase-root {
      width: 100%
    }
  }

  ${(props) => props.theme.breakpoints.up("desktop")} {
    & .MuiInputBase-root {
      min-width: 500px;
    }
  }

  <div style={{   }}>
*/

export const WrapperDiv = styled("div")`
  ${(props) => props.theme.breakpoints.down("mobile")} {
    & {
      width: 100%;
    }
  }

  ${(props) => props.theme.breakpoints.up("mobile")} {
    & {
      width: 50%;
      min-width: 500px;
    }
  }
`;

export const CssTextField = styled(TextField)`
  & .MuiInputBase-root {
    width: 100%;
  }

  & .MuiInputLabel-root {
    color: ${(props) => props.theme.palette.primary.main};
  }

  ,
  & .MuiInput-underline:before {
    border-bottom: none;
  }
  ,
  & .MuiInput-underline:after {
    border-bottom: none;
  }
  ,
  & .MuiInput-underline:hover:before {
    border-bottom: none !important;
  }
`;

export const CssSelectField = styled(Select)`
  & .MuiInputBase-root {
    width: 100%;
  }

  & .MuiInputLabel-root {
    color: ${(props) => props.theme.palette.primary.main};
  }

  ,
  & .MuiInput-underline:before {
    border-bottom: none;
  }
  ,
  & .MuiInput-underline:after {
    border-bottom: none;
  }
  ,
  & .MuiInput-underline:hover:before {
    border-bottom: none !important;
  }
`;

export const HelperTextLabel = styled("p")`
  & {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
  }
`;

export const CSSLabel = styled("label")`
  & {
    color: ${(props) => props.theme.palette.primary.main};
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    position: relative;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 133%;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transform: translate(0, -1.5px) scale(0.75);
    -moz-transform: translate(0, -1.5px) scale(0.75);
    -ms-transform: translate(0, -1.5px) scale(0.75);
    transform: translate(0, -1.5px) scale(0.75);
    -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
`;
/*
export const ZMulti = styled(Multiselect)`
  .searchWrapper {
    border-bottom: none !important;
  }
`;*/
/*
.highlightOption {
    background-color:white;
    color: ${(props) => props.theme.palette.primary.main};
  }*/
const ProfileComponent = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  let [searchParams, setSearchParams] = useSearchParams();
  const { instance, accounts } = useMsal();

  const [userCreated, setUserCreated] = useState<boolean>(false);
  const [genres, setGenres] = useState<MultiSelectOption[]>([]);
  const [selectedGenres, setSelectedGenres] = useState<string[]>([]);
  const [subscriptionPlanURL, setSubscriptionPlanURL] = useState<string>("");

  //const [subscriptionPlan, setSubscriptionPlan] = useState<Plan | null>(null);
  const [addOnQuantity, setAddOnQuantity] = useState<number | null>(null);
  const [selectedAddOnQuantity, setSelectedAddOnQuantity] = useState<number>(1);

  const [newEmail, setNewEmail] = useState<string>("");
  const [emailDialogProcessing, setEmailDialogProcessing] = useState<boolean>(false);
  const [showChangeEmailDialog, setShowChangeEmailDialog] = useState<boolean>(false);
  const [changeEmailDialogStep, setChangeEmailDialogStep] = useState<number>(1);
  const [newEmailConfirmationCode, setNewEmailConfirmationCode] = useState<string>("");
  const [newEmailErrorText, setNewEmailErrorText] = useState<string>("");
  const [processingEmailVerification, setProcessingEmailVerification] = useState<boolean>(false);

  const [showChangeDisplayNameDialog, setShowChangeDisplayNameDialog] = useState<boolean>(false);
  const [displayNameDialogProcessing, setDisplayNameDialogProcessing] = useState<boolean>(false);
  const [newDisplayNameErrorText, setNewDisplayNameErrorText] = useState<string>("");
  const [newDisplayName, setNewDisplayName] = useState<string>("");

  const [showActiveStatusProcessing, setShowActiveStatusProcessing] = useState<boolean>(false);
  
  const widgetStatus = useScript("https://widget.cloudinary.com/v2.0/global/all.js");

  const isMobile = useMediaQuery(theme.breakpoints.down("mobile"));

  const {
    hasToken,
    isAuthenticated,
    globalCache,
    currentUser,
    setCurrentUser,
    showProcessing,
    hideProcessing,
    verifyTwoFA,
    verifyEmailCode,
    twoFAVerificationResult,
    setTwoFAVerificationResult,
  } = useContext(UserContext);

  const avatarURL = useMemo(() => {
    let url = `${process.env.REACT_APP_CLOUDINARY_URL}c_scale,h_400,w_400/v${currentUser?.avatarVersion}/users/${currentUser?.userIDHash}/avatar.jpg`;
    return url;
  }, [currentUser]);

  const hostedPageId = searchParams.get("hostedpage_id");

  const [processRegistrationPlan] = useProcessRegistrationPlanMutation();

  const [registerUser, { data: registerUserData, loading: registerUserLoading, error: registerUserError }] = useRegisterUserMutation();

  const [changeAvatarVersion] = useChangeAvatarVersionMutation();

  const [updateUserGenres] = useUpdateUserGenresMutation();

  const [completeRegistration] = useCompleteRegistrationMutation();

  const [sendEmailCode] = useSendEmailCodeMutation();
  const [verify2FACode] = useVerifyTwoFaCodeMutation();
  const [changeEmailMutation] = useChangeCurrentUserEmailMutation();
  const [changeDisplayNameMutation] = useChangeCurrentUserDisplayNameMutation();
  const [changeWebLinkMutation] = useChangeCurrentUserWebLinkMutation();
  const [changeBioMutation] = useChangeCurrentUserBioMutation();
  const [changeCurrentUserCountryMutation] = useChangeCurrentUserCountryMutation();
  const [changeCurrentUserShowActiveStatusMutation] = useChangeCurrentUserShowActiveStatusMutation();
  
  const handleWebLinkSave = async (val: unknown): Promise<Boolean> => {
    let webLink = "";
    if (val) webLink = val as string;

    const response = await changeWebLinkMutation({
      variables: {
        webLink: webLink,
      },
      onError(error: ApolloError) {
        toast.error("An error occurred while changing the Personal Website.");
        console.log(error);
      },
    }).then((val: any) => {
      if (val?.data?.changeCurrentUserWebLink?.error) {
        toast.error(val?.data?.changeCurrentUserWebLink?.error);
        return false;
      }

      if (val?.data?.changeCurrentUserWebLink?.success) {
        let usr = { ...currentUser };
        usr.weblink = webLink;
        setCurrentUser(usr);
        toast.success("Successfully changed Personal Website.");
        return true;
      }

      toast.error("An error occurred while updating the Personal Website.");
      return false;
    });
    return response;
  };

  const handleBioSave = async (val: unknown): Promise<Boolean> => {
    let bio = "";
    if (val) bio = val as string;

    const response = await changeBioMutation({
      variables: {
        bio: bio,
      },
      onError(error: ApolloError) {
        toast.error("An error occurred while changing the Bio.");
        console.log(error);
      },
    }).then((val: any) => {
      if (val?.data?.changeCurrentUserBio?.error) {
        toast.error(val?.data?.changeCurrentUserBio?.error);
        return false;
      }

      if (val?.data?.changeCurrentUserBio?.success) {
        let usr = { ...currentUser };
        usr.bio = bio;
        setCurrentUser(usr);
        toast.success("Successfully changed Bio.");
        return true;
      }

      toast.error("An error occurred while updating the Bio.");
      return false;
    });
    return response;
  };

  const [verifyEmailCodeQuery] = useVerifyEmailCodeLazyQuery({
    fetchPolicy: "cache-and-network",
    onError(error: ApolloError) {
      toast.error("An error occurred while verifying your email code.");
      console.log(error);
    },
  });

  const [isEmailFreeQuery] = useIsEmailFreeLazyQuery({
    fetchPolicy: "cache-and-network",
    onError(error: ApolloError) {
      toast.error("An error occurred while checking to see if email is in use.");
      console.log(error);
    },
  });

  const [listGenres] = useListGenresLazyQuery({
    onCompleted(data: ListGenresQuery) {
      console.log("list genres success");
      /*console.log(data);
      let options: MultiSelectOption[] = [];
      data.listGenres.map((val: GenreDto, idx: number) => {
        options.push({ id: val.genreIDHash, name: val.name });
      });

      setGenres(options);*/
    },
    onError(error: ApolloError) {
      console.log("an error occurred");
      console.log(error);
    },
  });

  const [listUserGenres] = useListUserGenresLazyQuery({
    onCompleted(data: ListUserGenresQuery) {
      console.log("list user genres success");
      /*console.log(data);
      let options: MultiSelectOption[] = [];
      data.listUserGenres.map((val: string, idx: number) => {
        options.push({ id: val, name: val.name });
      });

      setGenres(options);*/
    },
    onError(error: ApolloError) {
      console.log("an error occurred");
      console.log(error);
    },
  });

  const saveGenreSelection = async (values: string[]): Promise<Boolean> => {
    const result = await updateUserGenres({
      variables: {
        genreIDHashes: values,
      },
      onCompleted(data: UpdateUserGenresMutation) {
        if (data?.updateUserGenres?.error) {
          toast.error(data?.updateUserGenres?.error);
          return;
        }
        toast.success("Successfully changed Genre Interests.")
      },
      onError(error: ApolloError) {
        toast.error("An error occurred while changing Genre Interests.");
        console.log(error);
      },
    });

    if (result.data?.updateUserGenres?.success) return true;
    else return false;
  };

  const getGenres = async (): Promise<MultiSelectOption[]> => {
    const result = await listGenres();
    if (result.data?.listGenres) {
      let options: MultiSelectOption[] = [];
      result.data.listGenres.map((val: GenreDto, idx: number) => {
        options.push({ id: val.genreIDHash, name: val.name });
      });
      return options;
    } else return [];
  };

  const getUserGenres = async (): Promise<string[]> => {
    const result = await listUserGenres();
    if (result.data?.listUserGenres) {
      return result.data.listUserGenres;
    } else return [];
  };

  const [listCountries] = useListCountriesLazyQuery({
    onCompleted(data: ListCountriesQuery) {
      console.log("list countries success");
      /*console.log(data);
      let options: MultiSelectOption[] = [];
      data.listGenres.map((val: GenreDto, idx: number) => {
        options.push({ id: val.genreIDHash, name: val.name });
      });

      setGenres(options);*/
    },
    onError(error: ApolloError) {
      console.log("an error occurred");
      console.log(error);
    },
  });

  const getUserCountry = async (): Promise<MultiSelectOption | null> => {
    if (currentUser?.country) {
      let c: MultiSelectOption = {
        id: currentUser?.country.countryCode,
        name: currentUser?.country.name
      }

      return c;
    }
    else {
      return null;
    }
  };

  const saveCountrySelection = async (countryCode: string | null): Promise<Boolean> => {
    
    const result = await changeCurrentUserCountryMutation({
      variables: {
        countryCode: (countryCode ? countryCode : null),
      },
      onCompleted(data: ChangeCurrentUserCountryMutation) {
        if (data?.changeCurrentUserCountry?.error) {
          toast.error(data?.changeCurrentUserCountry?.error);
          return;
        }
        setCurrentUser(data?.changeCurrentUserCountry);
        toast.success("Successfully changed Country.")
      },
      onError(error: ApolloError) {
        toast.error("An error occurred while changing Country.");
        console.log(error);
      },
    });

    if (result.data?.changeCurrentUserCountry) return true;
    else return false;
  };

  const getCountries = async (): Promise<MultiSelectOption[]> => {
    const result = await listCountries();
    if (result.data?.listCountries) {
      let options: MultiSelectOption[] = [];
      result.data.listCountries.map((val: CountryDto, idx: number) => {
        options.push({ id: val.countryCode, name: val.name });
      });
      return options;
    } else return [];
  };

  const handleNewEmailChange = (event: any) => {
    setNewEmail(event.target.value);
  };

  const handleDisplayNameChange = (event: any) => {
    setNewDisplayName(event.target.value);
  };

  const handleNewEmailConfirmationCodeChange = (event: any) => {
    setNewEmailConfirmationCode(event.target.value);
  };

  const handleNewEmailVerification = async () => {
    setProcessingEmailVerification(true);

    let verifyResponse = await verifyEmailCodeQuery({
      variables: {
        code: newEmailConfirmationCode,
        isCurrentEmail: false,
      },
    }).then((val: VerifyEmailCodeQueryResult) => {
      setProcessingEmailVerification(false);

      if (val?.data?.verifyEmailCode) {
        setChangeEmailDialogStep(3);
        return true;
      } else {
        toast.error("Invalid email code.");
        return false;
      }
    });

    if (!verifyResponse) return;

    changeEmailMutation({
      variables: {
        newEmail: newEmail,
        code: twoFAVerificationResult.code,
        codeEntryTime: twoFAVerificationResult?.time,
      },
      onCompleted(data: ChangeCurrentUserEmailMutation) {
        if (data?.changeCurrentUserEmail?.error) {
          toast.error(data?.changeCurrentUserEmail?.error);
          setChangeEmailDialogStep(1);
          return;
        }

        let usr = { ...currentUser };
        usr.email = newEmail;
        setCurrentUser(usr);
        toast.success("Successfully changed email.");
        setShowChangeEmailDialog(false);
      },
      onError(error: ApolloError) {
        setChangeEmailDialogStep(1);
        toast.error("An error occurred while changing your email.");
        console.log(error);
      },
    });
  };

  const handleNewEmailSendCode = async () => {
    if (newEmail.toLowerCase() === currentUser?.email?.toLowerCase()) {
      toast.error("Your email is already " + newEmail);
      return;
    }

    if (newEmail === "") {
      toast.error("You must enter a new email first.");
      return;
    }

    setEmailDialogProcessing(true);

    let emailFreeResponse = await isEmailFreeQuery({
      variables: {
        email: newEmail,
      },
    }).then((ret: IsEmailFreeQueryResult) => {
      return ret.data?.isEmailFree;
    });

    if (!emailFreeResponse) {
      toast.error(`${newEmail} is already in use.`);
      setEmailDialogProcessing(false);
      return;
    }

    sendEmailCode({
      variables: {
        email: newEmail,
      },
      onCompleted(data: SendEmailCodeMutation) {
        setEmailDialogProcessing(false);

        if (data?.sendEmailCode?.error) {
          toast.error(data?.sendEmailCode?.error);
          setChangeEmailDialogStep(1);
          return;
        }

        setChangeEmailDialogStep(2);
      },
      onError(error: ApolloError) {
        setEmailDialogProcessing(false);
        toast.error("An error occurred while sending the email verification code.");
        console.log(error);
        setChangeEmailDialogStep(1);
      },
    });
  };

  const executeDisplayNameChange = async () => {
    if (newDisplayName.toLowerCase() === currentUser?.displayName?.toLowerCase()) {
      toast.error("Your Display Name is already " + newDisplayName);
      return;
    }

    if (newDisplayName === "") {
      toast.error("You must enter a new Display Name first.");
      return;
    }

    setDisplayNameDialogProcessing(true);

    changeDisplayNameMutation({
      variables: {
        newDisplayName: newDisplayName,
        code: twoFAVerificationResult.code,
        codeEntryTime: twoFAVerificationResult?.time,
      },
      onCompleted(data: ChangeCurrentUserDisplayNameMutation) {
        if (data?.changeCurrentUserDisplayName?.error) {
          toast.error(data?.changeCurrentUserDisplayName?.error);
          return;
        }

        let usr = { ...currentUser };
        usr.displayName = newDisplayName;
        setCurrentUser(usr);
        toast.success("Successfully changed Display Name.");
        setShowChangeDisplayNameDialog(false);
      },
      onError(error: ApolloError) {
        toast.error("An error occurred while changing your Display Name.");
        console.log(error);
      },
    });
  };

  const onCompleteRegistration = () => {
    if (currentUser?.avatarVersion == null) {
      toast.error("You must upload an avatar first.");
      return;
    }

    if (selectedGenres.length == 0) {
      toast.error("You must select at least one genre you are interested in first.");
      return;
    }

    updateUserGenres({
      variables: {
        genreIDHashes: selectedGenres,
      },
      onCompleted(data: UpdateUserGenresMutation) {
        if (data?.updateUserGenres?.error) {
          toast.error(data?.updateUserGenres?.error);
          return;
        }

        completeRegistration({
          onCompleted(cRegData: CompleteRegistrationMutation) {
            if (cRegData?.completeRegistration == null) {
              toast.error("An error occurred while completing your registration, please try again.");
              return;
            }

            setCurrentUser(cRegData.completeRegistration);
            toast.success("Registration Complete!");
          },
          onError(error: ApolloError) {
            toast.error("An error occurred while completing your registration, please try again.");
            console.log(error);
          },
        });
      },
      onError(error: ApolloError) {
        toast.error("An error occurred while uploading your avatar, please try again.");
        console.log(error);
      },
    });
  };

  const onSelect = (selectedList: any, selectedItem: any) => {
    console.log("added");
    console.log(selectedItem);
    let selectedIds: string[] = [];

    selectedList.map((obj: any, idx: number) => {
      selectedIds.push(obj.id);
    });
    setSelectedGenres(selectedIds);
  };

  const onRemove = (selectedList: any, removedItem: any) => {
    console.log("removed");
    console.log(removedItem);
  };

  const handleAddOnQuantityChange = (event: Event, value: number | number[], activeThumb: number) => {
    if (typeof value === "number") setSelectedAddOnQuantity(value);
  };

  const successCallBack = (result: any) => {
    console.log(result);
    //process.env.REACT_APP_GRAPHQL_URI
    // process.env.REACT_APP_CLOUDINARY_URL
    //let url = `${process.env.REACT_APP_CLOUDINARY_URL}c_scale,h_400,w_400/users/${currentUser?.userIDHash}/avatar.jpg`;
    //setAvatarURL(result.info.eager[1].secure_url);
    changeAvatarVersion({
      variables: {
        avatarVersion: result.info.version,
      },
      onCompleted(data: ChangeAvatarVersionMutation) {
        console.log("changed avatar version successfully");

        if (data?.changeAvatarVersion == null) {
          toast.error("An error occurred while uploading your avatar, please try again.");
        } else {
          toast.success("Successfully uploaded avatar");
          setCurrentUser(data.changeAvatarVersion);
        }
      },
      onError(error: ApolloError) {
        toast.error("An error occurred while uploading your avatar, please try again.");
        console.log(error);
      },
    });
  };

  const validateNewEmail = () => {
    let regexp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (newEmail !== "" && !regexp.test(newEmail)) {
      setNewEmailErrorText("Invalid email");
    } else setNewEmailErrorText("");
  };

  const validateDisplayName = () => {
    let regexp = new RegExp(/^\w{3,20}$/);
    if (newEmail !== "" && !regexp.test(newEmail)) {
      setNewDisplayNameErrorText("Display Name must be between 3 and 20 character long and only include letters, numbers, and underscores.");
    } else setNewDisplayNameErrorText("");
  };

  const changeEmail = () => {
    verifyTwoFA((result: TwoFAVerificationResult) => {
      setNewEmail("");
      setNewEmailConfirmationCode("");
      setNewEmailErrorText("");
      setChangeEmailDialogStep(1);
      setShowChangeEmailDialog(true);
    });
  };

  const changeDisplayName = () => {
    verifyTwoFA((result: TwoFAVerificationResult) => {
      setNewDisplayName("");
      setNewDisplayNameErrorText("");
      setShowChangeDisplayNameDialog(true);
    });
  };

  const changeCountry = async(event: SelectChangeEvent) => {

    const response = await changeCurrentUserCountryMutation({
      variables: {
        countryCode: event.target.value as string,
      },
      onError(error: ApolloError) {
        toast.error("An error occurred while changing the Country.");
        console.log(error);
      },
    }).then((val: any) => {
      if (val?.data?.changeCurrentUserCountry?.error) {
        toast.error(val?.data?.changeCurrentUserCountry?.error);
        return false;
      }

      if (val?.data?.changeCurrentUserCountry) {
        //let usr = { ...currentUser };
        //usr.country = event.target.value as string;
        console.log(val?.data?.changeCurrentUserCountry);
        setCurrentUser(val?.data?.changeCurrentUserCountry);
        toast.success("Successfully changed Country.");
        return true;
      }

      toast.error("An error occurred while updating the Country.");
      return false;
    });
  };

  const handleShowActiveStatusChange = async (event: React.ChangeEvent<HTMLInputElement>) => {

    let newStatus = event.target.checked;
    setShowActiveStatusProcessing(true);

    await changeCurrentUserShowActiveStatusMutation({
      variables: {
        status: newStatus
      },
      onCompleted(data: ChangeCurrentUserShowActiveStatusMutation) {
        setShowActiveStatusProcessing(false);
        if (data?.changeCurrentUserShowActiveStatus?.error) {
          toast.error(data?.changeCurrentUserShowActiveStatus?.error);
          return;
        }
        if (data?.changeCurrentUserShowActiveStatus?.success) {
          let usr = { ...currentUser };
          usr.showActiveStatus = newStatus;
          
          setCurrentUser(usr);
          toast.success("Successfully changed Show Active Status.")
        }
      },
      onError(error: ApolloError) {
        setShowActiveStatusProcessing(false);
        toast.error("An error occurred while changing Show Active Status.");
        console.log(error);
      },
    });
  };

  const handleEnableCreatorFunctionalityChange = async (event: React.ChangeEvent<HTMLInputElement>) => {

    let newStatus = event.target.checked;

    if (currentUser?.subscriptionPlanID === 1) {
      navigate("/accountSettings/subscription");
      toast.info("Creator Tools require a paid subscription.");
      return;
    }
  };

  const failureCallBack = (error: any, result: any) => {};

  useEffect(() => {
    listGenres();
  }, []);

  const mobileSize = useMediaQuery("(max-width:1200px)");
  const desktopSize = useMediaQuery("(min-width:1201px)");
  //165

  useEffect(() => {
    console.log('widgetStatus: ', widgetStatus);
  }, [widgetStatus])

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <h1 style={{ textAlign: "center" }}>Profile Settings</h1>
        <WrapperDiv>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {!currentUser?.avatarVersion && <Avatar alt="Avatar Image" style={{ width: 150, height: 150 }} />}
            {currentUser?.avatarVersion && <Avatar alt="Avatar Image" src={avatarURL} style={{ width: 150, height: 150 }} />}
            <div>
              {currentUser && widgetStatus === "ready" && (
                <>
                <UploadWidget
                  sources={["local", "url", "unsplash", "camera"]} // set the sources available for uploading -> by default
                  // all sources are available. More information on their use can be found at
                  // https://cloudinary.com/documentation/upload_widget#the_sources_parameter
                  sourceKeys={{}} // add source keys
                  // and ID's as an object. More information on their use can be found at
                  // https://cloudinary.com/documentation/upload_widget#the_sources_parameter
                  resourceType={"image"} // optionally set with 'auto', 'image', 'video' or 'raw' -> default = 'auto'
                  cloudName={"miadara"} // your cloudinary account cloud name.
                  // Located on https://cloudinary.com/console/
                  uploadPreset={"avatar_upload"} // check that an upload preset exists and check mode is signed or unisgned
                  buttonText={"Change Avatar"} // default 'Upload Files'
                  style={{
                    color: "white",
                    border: "none",
                    width: "120px",
                    backgroundColor: "green",
                    borderRadius: "4px",
                    height: "25px",
                  }} // inline styling only or style id='cloudinary_upload_button'
                  folder={"users/" + currentUser.userIDHash} // set cloudinary folder name to send file
                  cropping={true} // set ability to crop images -> default = true
                  // https://support.cloudinary.com/hc/en-us/articles/203062071-How-to-crop-images-via-the-Upload-Widget-#:~:text=Click%20on%20the%20%22Edit%22%20link,OK%22%20and%20Save%20the%20changes.
                  // more information here on cropping. Coordinates are returned or upload preset needs changing
                  multiple={false} // set to false as default. Allows multiple file uploading
                  // will only allow 1 file to be uploaded if cropping set to true
                  autoClose={true} // will close the widget after success. Default true
                  onSuccess={successCallBack} // add success callback -> returns result
                  onFailure={failureCallBack} // add failure callback -> returns 'response.error' + 'response.result'
                  logging={true} // logs will be provided for success and failure messages,
                  // set to false for production -> default = true
                  customPublicId={"avatar"} // set a specific custom public_id.
                  // To use the file name as the public_id use 'use_filename={true}' parameter
                  //eager={'w_400,h_300,c_pad|w_260,h_200,c_crop'} // add eager transformations -> deafult = null
                  use_filename={false} // tell Cloudinary to use the original name of the uploaded
                  // file as its public ID -> default = true,

                  /*widgetStyles={{
      palette: {
        window: '#737373',
        windowBorder: '#FFFFFF',
        tabIcon: '#FF9600',
        menuIcons: '#D7D7D8',
        textDark: '#DEDEDE',
        textLight: '#FFFFFF',
        link: '#0078FF',
        action: '#FF620C',
        inactiveTabIcon: '#B3B3B3',
        error: '#F44235',
        inProgress: '#0078FF',
        complete: '#20B832',
        sourceBg: '#909090'
      },
      fonts: {
        default: null,
        "'Fira Sans', sans-serif": {
          url: 'https://fonts.googleapis.com/css?family=Fira+Sans',
          active: true
        }
      }
    }} // ability to customise the style of the widget uploader*/
                  destroy={false} // will destroy the widget on completion
                  // 👇 FOR SIGNED UPLOADS ONLY 👇

                  generateSignatureUrl={`${process.env.REACT_APP_REST_URI}/GenerateCloudinaryAvatarSignature`} // pass the api
                  // endpoint for generating a signature -> check cloudinary docs and SDK's for signing uploads
                  apiKey={814698183619494} // cloudinary API key -> number format
                  accepts={"application/json"} // for signed uploads only -> default = 'application/json'
                  contentType={"application/json"} // for signed uploads only -> default = 'application/json'
                  withCredentials={false} // default = true -> check axios documentation for more information
                  unique_filename={true} // setting it to false, you can tell Cloudinary not to attempt to make
                  showButton={true}
                  // the Public ID unique, and just use the normalized file name -> default = true
                />
                </>
              )}
            </div>
            <div style={{ display: "flex", alignItems: "center", width: "100%", marginTop: 30 }}>
              <div style={{ flexGrow: 1 }}>
                <ToolTipComponent
                  htmlFor="selGenres"
                  label={"Genre Interests"}
                  style={{marginBottom:5}}
                  tooltip={
                    isMobile
                      ? "Your genre interests help us prioritize the content we show you as well as indicating what you are interested in to other users."
                      : ""
                  }
                />
                <EditableMultiSelectInputComponent
                  id="selGenres"
                  options={[]}
                  title="Genre Interests"
                  loadValuesHandler={getGenres}
                  loadSelectedValuesHandler={getUserGenres}
                  saveValuesHandler={saveGenreSelection}
                  placeholder="Select a Genre"
                  helperText={"Your genre interests help us prioritize the content we show you as well as indicating what you are interested in to other users."}
                />
              </div>
            </div>
          </Box>

          <div style={{ display: "flex", alignItems: "center", width: "100%", marginTop: 30 }}>
            <div style={{ flexGrow: 1 }}>
              <ToolTipComponent
                htmlFor="txtEmail"
                label={"Email"}
                tooltip={isMobile ? "The email you login to Miadara with and where communications are sent." : ""}
              />
              <CssTextField
                id="txtEmail"
                type="text"
                value={currentUser?.email}
                style={{ flexGrow: 1 }}
                helperText={isMobile ? "" : "The email you login to Miadara with and where communications are sent."}
                inputProps={{ readOnly: true }}
                variant="standard"
              />
            </div>
            <Button color="primary" variant="contained" style={{ fontWeight: "bold", marginLeft: 20 }} onClick={changeEmail}>
              <EditIcon />
            </Button>
          </div>
          <div style={{ display: "flex", alignItems: "center", position: "relative", marginTop: 30, width: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <ToolTipComponent
                htmlFor="txtDisplayName"
                label={"Display Name"}
                tooltip={isMobile ? "This is the name other users will know you as in Miadara." : ""}
              />
              <CssTextField
                id="txtDisplayName"
                type="text"
                style={{ flexGrow: 1 }}
                value={currentUser?.displayName}
                helperText={isMobile ? "" : "This is the name other users will know you as in Miadara."}
                inputProps={{ readOnly: true }}
                variant="standard"
              />
            </div>
            <Button color="primary" variant="contained" style={{ fontWeight: "bold", marginLeft: 20 }} onClick={changeDisplayName}>
              <EditIcon />
            </Button>
          </div>
          <div style={{ display: "flex", alignItems: "center", position: "relative", marginTop: 30, width: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <ToolTipComponent
                htmlFor="swEnableCreatorFunctionality"
                label={"Enable Creator Tools"}
                tooltip={isMobile ? "Enable Creator Tools so you can create and sell content as well as earn tips!" : ""}
              />
              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  {!showActiveStatusProcessing &&
                  <FormControlLabel
                    control={<Switch id="swEnableCreatorFunctionality" disabled={!!currentUser?.isCreator} checked={!!currentUser?.isCreator} onChange={handleEnableCreatorFunctionalityChange} />}
                    label="Enable Creator Tools"
                  />}
                  {showActiveStatusProcessing &&
                  <FormControlLabel style={{display:'flex', alignItems:'center', height:38}}
                    control={<CircularProgress size={20} style={{marginLeft:20, marginRight:18}} />}
                    label="Enable Creator Tools"
                  />}
                </FormGroup>
                {!isMobile &&
                <FormHelperText>
                  Enable Creator Tools so you can create and sell content as well as earn tips!
                </FormHelperText>}
              </FormControl>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", marginTop: 30, width: "100%" }}>
            <EditableTextInputComponent
              id="personalWebsite"
              label={"Personal Website"}
              helperText={"This link provides a way for users to access your personal website from within your public profile."}
              style={{ display:'inline', maxWidth: "calc(576px - 165px)", overflow: "hidden" }}
              mobileStyle={{flexGrow: 1, width: '100%'}}
              value={currentUser?.weblink}
              saveHandler={handleWebLinkSave}
              showAsLink={true}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center", width: "100%", marginTop: 30 }}>
              <AutocompleteInputComponent
                id="selCountry"
                loadValuesHandler={getCountries}
                loadSelectedValueHandler={getUserCountry}
                saveHandler={saveCountrySelection}
                label="Country"
                placeholder="Select a Country"
                helperText="This is the country other users will see in Miadara."
              />
          </div>
          <div style={{ display: "flex", alignItems: "center", marginTop: 30, width: "100%" }}>
            <EditableTextInputComponent
              id="txtBio"
              label={"Bio"}
              multiline={true}
              maxLength={500}
              helperText={"This bio can tell other Miadara users a little about you."}
              style={{ display:'inline', overflow: isMobile ? "hidden" : "" }}
              mobileStyle={{width: '100%'}}
              value={currentUser?.bio}
              saveHandler={handleBioSave}
              showAsLink={false}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center", position: "relative", marginTop: 30, width: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <ToolTipComponent
                htmlFor="swShowActiveStatus"
                label={"Show Active Status"}
                tooltip={isMobile ? "Allow other Miadara users to see when you last logged in." : ""}
              />
              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  {!showActiveStatusProcessing &&
                  <FormControlLabel
                    control={<Switch id="swShowActiveStatus" checked={!!currentUser?.showActiveStatus} onChange={handleShowActiveStatusChange} />}
                    label="Show Active Status"
                  />}
                  {showActiveStatusProcessing &&
                  <FormControlLabel style={{display:'flex', alignItems:'center', height:38}}
                    control={<CircularProgress size={20} style={{marginLeft:20, marginRight:18}} />}
                    label="Show Active Status"
                  />}
                </FormGroup>
                {!isMobile &&
                <FormHelperText>
                  Allow other Miadara users to see when you last logged in.
                </FormHelperText>}
              </FormControl>
            </div>
          </div>
        </WrapperDiv>
      </div>
      <Dialog
        open={showChangeEmailDialog}
        TransitionComponent={Grow as React.ComponentType}
        keepMounted
        fullWidth={true}
        maxWidth={"xs"}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Change Email"}</DialogTitle>
        <DialogContent>
          {changeEmailDialogStep === 1 && (
            <TextField
              id="newEmail"
              type="email"
              error={newEmailErrorText !== ""}
              autoFocus={true}
              onBlur={validateNewEmail}
              onChange={handleNewEmailChange}
              value={newEmail}
              style={{ marginTop: 10, width: "100%" }}
              label="New Email"
              helperText={newEmailErrorText}
              variant="standard"
            />
          )}
          {changeEmailDialogStep === 2 && (
            <>
              <Typography>Enter the code sent to {newEmail}.</Typography>
              <TextField
                id="newEmailConfirmationCode"
                type="text"
                autoFocus={true}
                onChange={handleNewEmailConfirmationCodeChange}
                inputProps={{ maxLength: 6 }}
                value={newEmailConfirmationCode}
                style={{ marginTop: 10, width: "100%" }}
                label="Code"
                variant="standard"
              />
            </>
          )}
          {changeEmailDialogStep === 3 && (
            <>
              <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress />
                <h3 style={{ marginLeft: 20 }}>Updating email</h3>
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {changeEmailDialogStep === 1 && (
            <>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  setShowChangeEmailDialog(false);
                }}
              >
                <>
                  <CancelIcon color="secondary" style={{ marginRight: 5 }} />
                  Cancel
                </>
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={newEmailErrorText !== "" || emailDialogProcessing}
                onClick={() => {
                  handleNewEmailSendCode();
                }}
              >
                {emailDialogProcessing && <CircularProgress size={20} />}
                {!emailDialogProcessing && (
                  <>
                    Next
                    <NavigateNext style={{ marginLeft: 5 }} />
                  </>
                )}
              </Button>
            </>
          )}
          {changeEmailDialogStep === 2 && (
            <>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  setChangeEmailDialogStep(1);
                }}
              >
                <>
                  <NavigateBack style={{ marginRight: 5 }} />
                  Back
                </>
              </Button>
              <Button
                disabled={processingEmailVerification}
                color="primary"
                variant="contained"
                onClick={() => {
                  handleNewEmailVerification();
                }}
              >
                Verify
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={showChangeDisplayNameDialog}
        TransitionComponent={Grow as React.ComponentType}
        keepMounted
        fullWidth={true}
        maxWidth={"xs"}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Change Display Name"}</DialogTitle>
        <DialogContent>
          <TextField
            id="displayName"
            type="text"
            error={newDisplayNameErrorText !== ""}
            autoFocus={true}
            onBlur={validateDisplayName}
            onChange={handleDisplayNameChange}
            value={newDisplayName}
            style={{ marginTop: 10, width: "100%" }}
            label="New Display Name"
            helperText={newDisplayNameErrorText}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              setShowChangeDisplayNameDialog(false);
            }}
          >
            <>
              <CancelIcon color="secondary" style={{ marginRight: 5 }} />
              Cancel
            </>
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={newDisplayNameErrorText !== "" || displayNameDialogProcessing}
            onClick={() => {
              executeDisplayNameChange();
            }}
          >
            {displayNameDialogProcessing && <CircularProgress size={20} />}
            {!displayNameDialogProcessing && (
              <>
                Next
                <NavigateNext style={{ marginLeft: 5 }} />
              </>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfileComponent;
