import { ApolloError } from "@apollo/client";
import { CircularProgress, Grid, Link, useTheme } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PlanData from "../data/pricingPlans.json";
import { SubscribeFreePlanMutation, UserSubscriptionDto, useSubscribeFreePlanMutation } from "../generated";
import PlanBadge from "../images/popularBadge.png";
import PlanImage_Mentor from "../images/pricing_plans/badger.png";
import PlanImage_Initiate from "../images/pricing_plans/basic_hippo.png";
import PlanImage_Scholar from "../images/pricing_plans/fox.png";
import PlanImage_Novice from "../images/pricing_plans/free_pig.png";
import { UserContext } from "../ServiceWrapper";
import Helmet from "react-helmet";
import "../styles/pricingTable.css";

export enum PLAN_TYPE {
  MONTHLY,
  YEARLY,
}

interface PlanPricingTableComponentProps {
  planType: PLAN_TYPE;
  setSubscriptionPlanURL: (state: string) => void;
  setSubscriptionPlan: (state: Plan) => void;
  subscriptionPlan: Plan | null;
  userSubscription?: UserSubscriptionDto | null;
  processingPlanChange?: boolean;
}

export interface Plan {
  id: number;
  name: string;
  type : {
      monthly : {
        price: string;
        subscriptionUrl: string;
        planCode: string;
      },
      yearly : {
        price: string;
        subscriptionUrl: string;
        planCode: string;
      }
  },
  registration_offer? : {
    title: string;
    description: string;
    addon_code: string;
    price: number;
    dara: number;
  },
  features: string[]
};

const PlanPricingTableComponent: React.FC<PlanPricingTableComponentProps> = ({planType, setSubscriptionPlanURL, setSubscriptionPlan, subscriptionPlan, userSubscription, processingPlanChange}: PlanPricingTableComponentProps) => {
  
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const [
    subscribeFreePlan,
    {
      data: subscribeFreePlanData,
      loading: subscribeFreePlanLoading,
      error: subscribeFreePlanError,
    },
  ] = useSubscribeFreePlanMutation();

  const choosePlan = (plan: Plan, planType: PLAN_TYPE) => {
    
    // If it is a free plan we will skip the purchasing step
    if (plan.id === 1) {
      subscribeFreePlan({
        onCompleted(data: SubscribeFreePlanMutation) {
          console.log("subscribeFreePlan successfull");
          if (data.subscribeFreePlan?.error || data.subscribeFreePlan?.subscriptionPlanID !== 1) {
            toast.error('Unable to subscribe to the free plan at this time');
          }
          else {
            toast.success('Successfully registered for the free plan');
            setCurrentUser(data.subscribeFreePlan);  
          }
        },
        onError(error: ApolloError) {
          console.log("an error occurred");
          console.log(error);
        },
      });
      
    }
    else {
      let email = currentUser?.email ?? "";
      if (planType === PLAN_TYPE.MONTHLY) {
        setSubscriptionPlanURL(plan.type.monthly.subscriptionUrl + '?email=' + encodeURIComponent(email));
      }
      else {
        setSubscriptionPlanURL(plan.type.yearly.subscriptionUrl + '?email=' + encodeURIComponent(email));
      }
      setSubscriptionPlan(plan);

    }
  };

  const buildSubscribeButton = (plan: Plan, planType: PLAN_TYPE, userSubscription: UserSubscriptionDto | null | undefined) : React.ReactFragment => {
    let showActive = false;
    
    if (userSubscription) {

      if (plan.name === userSubscription.subscriptionPlan && plan.name === "Novice") {
        showActive = true;
      }

      if (planType === PLAN_TYPE.MONTHLY) {
        if (plan.type.monthly.planCode === userSubscription.subscriptionPlanCode) {
          showActive = true;
        }
      }
      else {
        if (plan.type.yearly.planCode === userSubscription.subscriptionPlanCode) {
          showActive = true;
        }
      }

      if (showActive) {
        return (
        <span className="goal">
          <Link className="rounded" style={{backgroundColor: theme.palette.secondary.main}}>Active</Link>
        </span>
        );
      }
      else {
        if (processingPlanChange) {
          if (subscriptionPlan === plan) {
            return (
              <span className="goal">
                <Link className="rounded cursor-pointer" style={{display:"flex",justifyContent:"center"}}>
                  <CircularProgress size={15} style={{color:"white"}} />
                </Link>
              </span>
            );
          }
          else {
            return (
              <span className="goal">
                <Link className="rounded cursor-pointer">Change Plan</Link>
              </span>
            );
          }
        }
        else {
          return (
            <span className="goal">
              <Link className="rounded cursor-pointer" onClick={() => {setSubscriptionPlan(plan);}}>Change Plan</Link>
            </span>
          );
        }
      }
    }
    else {
      return (
        <span className="goal">
          <Link className="rounded cursor-pointer" onClick={() => {choosePlan(plan, planType)}}>Subscribe</Link>
        </span>
      )
    }
  };

  return (
    <>
      <div style={{display:'flex', justifyContent: 'center'}}>
        <Grid id="planGrid" container spacing={2} columns={{ xs: 1, sm: 2, md: 3, lg: 4}} style={{display:'flex', justifyContent: 'center', maxWidth: 1400}}>
        {PlanData.plans.map((plan, idx) => {
                  let planImage = "";

                  switch (plan.name) {
                    case "Novice":
                      planImage = PlanImage_Novice;
                      break;
                    case "Initiate":
                      planImage = PlanImage_Initiate;
                      break;
                    case "Scholar":
                      planImage = PlanImage_Scholar;
                      break;
                    case "Mentor":
                      planImage = PlanImage_Mentor;
                      break;
                    default:
                      console.error("invalid plan name found");
                      break;
                  }

                  let planAmount =
                    planType === PLAN_TYPE.MONTHLY
                      ? plan.type.monthly.price
                      : plan.type.yearly.price;
                  let planSubscriptionUrl =
                    planType === PLAN_TYPE.MONTHLY
                      ? plan.type.monthly.subscriptionUrl
                      : plan.type.yearly.subscriptionUrl;

                  return (
                    <Grid item key={`gridItem_${idx}`} xs={1} sm={1} md={1} lg={1} style={{display:'flex', justifyContent: 'center'}}>
                      <div
                        className="plan-block "
                        style={{ position: "relative", maxWidth: 300 }}
                      >
                        {plan.id === 3 && (
                          <img
                            style={{
                              position: "absolute",
                              top: 10,
                              right: 10,
                              width: 50,
                              height: 50,
                            }}
                            src={PlanBadge}
                            alt="popular"
                          />
                        )}
                        <h2 id="plan-name">{plan.name}</h2>
                        <div className="pricing-img">
                          <img
                            className="icon-basic"
                            src={planImage}
                            alt="plan"
                          />
                        </div>
                        <div className="main-price">
                          <span className="price-figure">
                            {plan.id !== 1 && <small>$</small>}
                            <span className="basic-plan price-value">
                              <span className="otherCurrency" id="plan-amount">
                                {planAmount}
                              </span>
                            </span>
                          </span>
                          <span className="price-term">
                            {plan.id !== 1 && (
                              <span>
                                Billed{" "}
                                {planType === PLAN_TYPE.MONTHLY
                                  ? "Monthly"
                                  : "Annually"}
                              </span>
                            )}
                          </span>
                          {buildSubscribeButton(plan, planType, userSubscription)}
                        </div>
                        <ul
                          id="price-features"
                          className="price-features"
                          style={{
                            borderTop: "1px solid rgb(237, 237, 237)",
                            paddingBottom: "7px",
                          }}
                        >
                          {plan.features.map((feature, fIdx) => {
                            return (
                              <li key={`fIdx_${fIdx}`}>
                                <p
                                  style={{ position: "relative" }}
                                  dangerouslySetInnerHTML={{ __html: feature }}
                                ></p>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </Grid>
                  );
                })}
        </Grid>
      </div>
    </>
  );
};

export default PlanPricingTableComponent;
