import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  Link,
  matchPath,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProfileComponent from "../components/accountSettings/profile";
import SecurityComponent from "../components/accountSettings/security";
import SecurityIcon from "@mui/icons-material/Key";
import CreateIcon from "@mui/icons-material/Create";
import SubscriptionIcon from "@mui/icons-material/History";
import { Icon, Paper, SvgIcon } from "@mui/material";

function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

function MyTabs() {
  // You need to provide the routes in descendant order.
  // This means that if you have nested routes like:
  // users, users/new, users/edit.
  // Then the order should be ['users/add', 'users/edit', 'users'].
  const routeMatch = useRouteMatch([
    "/createContent/drafts/edit/*",
    "/createContent/drafts/new",
    "/createContent/drafts"
  ]);
  
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:1200px)");

  let routePath = routeMatch?.pattern?.path;
  //console.log('routePath:', routePath);

  const [currentTab, setCurrentTab] = useState<string | undefined | null>("drafts");

  useEffect(() => {
    if (routePath) {
      if (routePath.startsWith('/createContent/drafts'))
        setCurrentTab("drafts");
    }
    else
      setCurrentTab("drafts");

  }, [routePath]);

  useEffect(() => {
    if (routePath == null) navigate("/createContent/drafts");
  }, [routePath]);

  

  return (
    <div style={{marginTop:20, borderRight: '1px solid lightgray'}}>
      <Tabs
        value={currentTab}
        orientation="vertical"
        indicatorColor="primary"
        className="accountSettingsTabs"
      >
        <Tab
          label={isMobile ? "" : "Drafts"}
          icon={<CreateIcon />}
          value="drafts"
          to="/createContent/drafts"
          component={Link}
        />
      </Tabs>
    </div>
  );
}

export const CreateContent = () => {
  const loc = useLocation();
  const [newEditDraft, setNewEditDraft] = useState<boolean>(false);

  useEffect(() => {
    
    if (loc.pathname.startsWith('/createContent/drafts/new')) {
      setNewEditDraft(true);
    }
    else if (loc.pathname.startsWith('/createContent/drafts/edit')) {
      setNewEditDraft(true);
    }
    else {
      setNewEditDraft(false);
    }

  },[loc]);

  return (
    <Box sx={{ width: "100%", display: "flex" }}>
      {!newEditDraft &&
      <MyTabs />}
      <div style={{margin:10, flexGrow: 1}}>
        <Outlet />
      </div>
    </Box>
  );
};
