import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  Link,
  TextFieldProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { createRef, useEffect, useRef, useState } from "react";
import { CSSLabel, CssTextField, HelperTextLabel } from "../accountSettings/profile";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import { title } from "process";
import ToolTipComponent from "./toolTip";
import ReactTooltip from "react-tooltip";
import { photoPlaceholder } from "aws-amplify";

interface ParentProps {
  saveHandler: (value: unknown) => Promise<Boolean>;
  showAsLink?: boolean;
  showAsTextArea?: boolean;
  maxWidth?: number;
  maxLength?: number;
  mobileStyle?: React.CSSProperties;
}

export type EditableTextInputComponentProps = ParentProps & TextFieldProps;

const EditableTextInputComponent: React.FC<EditableTextInputComponentProps> = ({
  id,
  label,
  value,
  style,
  mobileStyle,
  helperText,
  showAsLink,
  showAsTextArea,
  maxWidth,
  maxLength,
  multiline,
  saveHandler,
  placeholder,
}: EditableTextInputComponentProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isOverflown, setIsOverflown] = useState<boolean>(false);
  const [txtValue, setTxtValue] = useState<unknown>(value);
  const [txtMobileValue, setTxtMobileValue] = useState<unknown>(value);
  const [currentLength, setCurrentLength] = useState<number>(0);
  const [txtOrigValue, setTxtOrigValue] = useState<unknown>(value);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const txtRef = useRef<HTMLInputElement>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("mobile"));

  const handleClose = () => {
    setOpen(false);
    setIsEditing(false);
  };

  useEffect(() => {
    if (isMobile) {
      if (txtMobileValue) 
        setCurrentLength((txtMobileValue as string).length);
      else 
        setCurrentLength(0);
    } else {
      if (txtValue) 
        setCurrentLength((txtValue as string).length);
      else 
        setCurrentLength(0);
    }
  }, [isMobile, txtValue, txtMobileValue]);

  const handleModalClose = (event: any, reason: any) => {
    if (reason && reason === "backdropClick") return;

    handleClose();
  };

  const beginEditing = () => {
    console.log("begin editing");

    setIsEditing(true);
    console.log("isMobile", isMobile);
    if (isMobile) {
      setTxtMobileValue(txtOrigValue);
      setOpen(true);
    } else {
    }
    setTimeout(() => {
      txtRef.current?.focus();
    }, 100);
  };

  const cancel = () => {
    setIsEditing(false);
    setTxtValue(txtOrigValue);

    if (isMobile) handleClose();
  };

  const save = async () => {
    setIsProcessing(true);
    const response = await saveHandler(isMobile ? txtMobileValue : txtValue);
    setIsProcessing(false);

    if (response) {
      if (isMobile) {
        setTxtValue(txtMobileValue);
        setTxtOrigValue(txtMobileValue);
      } else {
        setTxtOrigValue(txtValue);
      }
      setIsEditing(false);
    }
  };

  const handleTextChange = (event: any) => {
    if (isMobile) setTxtMobileValue(event.target.value);
    else setTxtValue(event.target.value);
  };

  const linkText: string = txtValue ? (txtValue as string) : "";

  const BuildLink = () => {
    return (
      <>
        <div style={{ position: "relative", flexGrow: 1 }}>
          <ToolTipComponent htmlFor={`lnkItem_${id}`} label={label} tooltip={isMobile && helperText ? helperText : ""} />
          <div id={`lnkItem_${id}`} className="permalink" style={{ marginTop: 5 }}>
            {linkText !== "" && (
              <Link target="_blank" href={linkText} style={{ ...style }} color={theme.palette.secondary.main}>
                {linkText}
              </Link>
            )}
            {linkText === "" && <span>None Provided</span>}
          </div>
          {helperText && !isMobile && (
            <div>
              <HelperTextLabel>{helperText}</HelperTextLabel>
            </div>
          )}
        </div>
      </>
    );
  };

  const EditButton = () => {
    return (
      <Button color="primary" variant="contained" style={{ fontWeight: "bold", marginLeft: 20 }} title="Edit" onClick={beginEditing}>
        <EditIcon />
      </Button>
    );
  };

  const displayLink = showAsLink;

  if (isEditing) {
    if (isMobile) {
      return (
        <>
          <Dialog
            open={open}
            TransitionComponent={Grow as React.ComponentType}
            keepMounted
            fullWidth={true}
            hideBackdrop={true}
            onClose={handleModalClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <div style={{ marginTop: 10, width: '100%' }}>
                <ToolTipComponent htmlFor={`dialog_${id}`} label={label} style={{marginBottom:5}} tooltip={helperText ? helperText : ""} />
                <CssTextField
                  type="text"
                  id={`dialog_${id}`}
                  inputRef={txtRef}
                  multiline={multiline}
                  style={mobileStyle}
                  rows={5}
                  value={txtMobileValue}
                  inputProps={{ readOnly: !isEditing, maxlength: maxLength }}
                  onChange={handleTextChange}
                  variant={isEditing ? "outlined" : "standard"}
                />
                <div style={{ marginTop: 5 }}>
                  {maxLength && maxLength > 0 &&
                  <span>{currentLength} / {maxLength} characters remaining</span>}
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" variant="outlined" onClick={cancel}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CancelIcon color="secondary" style={{ marginRight: 5 }} />
                  <span>Cancel</span>
                </div>
              </Button>
              <Button color="primary" variant="contained" style={{ fontWeight: "bold", marginLeft: 20 }} title="Save" onClick={save}>
                {isProcessing && <CircularProgress size={20} style={{ color: "white", marginRight: 5 }} />}
                {!isProcessing && <SaveIcon style={{ marginRight: 5 }} />}
                Save
              </Button>
            </DialogActions>
          </Dialog>
          {!displayLink && (
            <>
              <div style={{ flexGrow: 1 }}>
                <ToolTipComponent htmlFor={`mainForm_${id}`} label={label} tooltip={helperText ? helperText : ""} />
                <CssTextField
                  id={`mainForm_${id}`}
                  type="text"
                  inputRef={txtRef}
                  style={style}
                  value={txtValue}
                  multiline={multiline}
                  placeholder={placeholder}
                  inputProps={{ readOnly: true }}
                  variant={"standard"}
                />
              </div>
              <EditButton />
            </>
          )}
          {displayLink && (
            <>
              <BuildLink />
              <EditButton />
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <CssTextField
              type="text"
              label={label}
              inputRef={txtRef}
              style={style}
              multiline={multiline}
              value={txtValue}
              inputProps={{ readOnly: !isEditing, maxLength: maxLength }}
              onChange={handleTextChange}
              variant={isEditing ? "outlined" : "standard"}
            />
            <div style={{ marginTop: 5 }}>
              {maxLength && maxLength > 0 &&
              <span>{currentLength} / {maxLength} characters remaining</span>}
              <HelperTextLabel>{helperText}</HelperTextLabel>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex" }}>
              <Button color="secondary" variant="outlined" style={{ fontWeight: "bold", marginLeft: 20 }} title="Cancel" onClick={cancel}>
                <CancelIcon />
              </Button>
              <Button color="primary" variant="contained" style={{ fontWeight: "bold", marginLeft: 20 }} title="Save" onClick={save}>
                {isProcessing && <CircularProgress size={20} style={{ color: "white" }} />}
                {!isProcessing && <SaveIcon />}
              </Button>
            </div>
          </div>
        </>
      );
    }
  } else {
    if (showAsLink) {
      return (
        <>
          <BuildLink />
          <EditButton />
        </>
      );
    } else {
      return (
        <>
          <div style={{ flexGrow: 1 }}>
            <ToolTipComponent htmlFor={`mainForm_${id}`} label={label} tooltip={isMobile && helperText ? helperText : ""} />
            <CssTextField
              id={`mainForm_${id}`}
              type="text"
              helperText={isMobile ? "" : helperText}
              inputRef={txtRef}
              style={style}
              value={txtValue ? txtValue : "None Provided"}
              multiline={multiline}
              placeholder={placeholder}
              inputProps={{ readOnly: !isEditing, maxLength: 5 }}
              onChange={handleTextChange}
              variant={isEditing ? "outlined" : "standard"}
            />
          </div>
          <EditButton />
        </>
      );
    }
  }
};

export default EditableTextInputComponent;
function useMemo(arg0: () => number, arg1: (number | undefined)[]) {
  throw new Error("Function not implemented.");
}

