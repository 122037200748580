import { CircularProgress, Dialog, DialogContent, DialogTitle, Grow } from "@mui/material";
import { Hub } from "aws-amplify";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";

export interface IProcessingControllerReference {
  showProcessing: (processingText: string) => void,
  hideProcessing: () => void
};

const ProcessingController = forwardRef<IProcessingControllerReference>((props, ref) => {
  
  const [open, setOpen] = useState(false);
  const [processingText, setProcessingText] = useState<string>("");
  
  const showProcessing = (processingText: string) => {
    if (processingText)
      setProcessingText(processingText);
    else
      setProcessingText('');

    setOpen(true);
  }
  
  const hideProcessing = () => {
    setOpen(false);
    setProcessingText('');
  }

  useImperativeHandle(ref, () => ({
    showProcessing,
    hideProcessing
  }));

  useEffect(() => {
    Hub.listen('globalError', (data) => {
      
      hideProcessing();
    });
    Hub.listen('graphqlError', (data) => {
      
      hideProcessing();
    });
  }, []);

  return (
    <Dialog
      open={open}
      TransitionComponent={Grow as React.ComponentType}
      keepMounted
      style={{zIndex:100000}}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{processingText}</DialogTitle>
      <DialogContent>
        <div style={{width:'100%',display:'flex', justifyContent:'center'}}>
        <CircularProgress />
        </div>
      </DialogContent>
    </Dialog>
  );
});

export default ProcessingController;