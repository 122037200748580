import {
  Box, Button, CircularProgress, useMediaQuery, useTheme
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/CheckCircle";
import NavigateBack from "@mui/icons-material/NavigateBefore";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CreateDraftMutation,
  DraftContentDto,
  DraftDetailDto,
  DraftDto, useCreateDraftMutation, UserDraftQuery, useUserDraftLazyQuery
} from "../../generated";
import { UserContext } from "../../ServiceWrapper";

interface DraftUpperRightMenuProps {
  action: string | undefined;
  isDirty: boolean;
  isSaving: boolean;
  saveDraft: () => void;
}

const DraftUpperRightMenuComponent: React.FC<DraftUpperRightMenuProps> = ({ saveDraft, action, isDirty, isSaving }: DraftUpperRightMenuProps) => {
  
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  
 if (action === "edit") {
    console.log('rendering header');
    return (
      <Box sx={{ flexGrow: 0 }}>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              navigate("/createContent/drafts");
            }}
          >
            <>
              <NavigateBack color="secondary" style={{ marginRight: 5 }} />
              Back
            </>
          </Button>
          <Button color="primary" variant="contained" disabled={!isDirty} style={{ fontWeight: "bold", marginLeft: 20 }} title="Save" onClick={saveDraft}>
            {isSaving && <CircularProgress size={20} style={{ color: "white" }} />}
            {!isSaving && <><SaveIcon /> <span style={{marginLeft:5}}>Save</span></>}
          </Button>
        </div>
      </Box>
    );
  }
  else
    return null;
};

export default DraftUpperRightMenuComponent;