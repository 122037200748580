import { Button, InputLabel, ThemeProvider, Tooltip, useTheme } from "@mui/material";
import React, { useState } from "react";
import { CSSLabel } from "../accountSettings/profile";
import InfoIcon from "@mui/icons-material/Info";
import { ConsoleLogger } from "@aws-amplify/core";
import ReactTooltip from 'react-tooltip';

interface ParentProps {
  label: React.ReactNode;
  tooltip?: React.ReactNode;
  htmlFor: string;
  style?: React.CSSProperties
}

export type ToolTipComponentProps = ParentProps;

const ToolTipComponent: React.FC<ToolTipComponentProps> = ({ label, tooltip, style, htmlFor }: ToolTipComponentProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [timeoutHandle, setTimeoutHandle] = useState<NodeJS.Timeout>();
  const theme = useTheme();
  
  const handleTooltipClose = () => {
    setOpen(false);
    console.log('clearing handle: ' + htmlFor, timeoutHandle);
    if (timeoutHandle)
      clearTimeout(timeoutHandle);
    console.timeEnd('tooltip');
  };

  const handleTooltipOpen = () => {
    console.log('clicked - opening');
    setOpen(true);
    console.time('tooltip - ' + htmlFor);
    if (timeoutHandle)
      clearTimeout(timeoutHandle);

    let handle = setTimeout(() => {
      console.log('timeout executing - ' + htmlFor);
      handleTooltipClose();
    }, 5000);
    setTimeoutHandle(handle);
  };

  /*
  <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            
            open={open}
            disableFocusListener
            disableHoverListener
            enterTouchDelay={0}
            leaveTouchDelay={5000}
            enterDelay={0}
            leaveDelay={5000}
            placement="top"
            title={tooltip ? tooltip : ""}
          >*/
  return (
    <>
        <div style={style}>
          <InputLabel htmlFor={htmlFor} style={{color:theme.palette.primary.main, fontSize: '90%'}}>
            <div style={{display:'flex', alignItems:'center', lineHeight:'initial'}} data-class="toolTipContainer" data-tip={tooltip} data-for={`tipContainer_${htmlFor}`}>{label}{tooltip && <InfoIcon style={{marginLeft:5}}/>}</div>
          </InputLabel>
        </div>
        <ReactTooltip effect="solid" place="top" multiline={true}  id={`tipContainer_${htmlFor}`} />
    </>
  );
};

export default ToolTipComponent;