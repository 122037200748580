import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  styled,
  TextField,
  TextFieldProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { createRef, useCallback, useEffect, useRef, useState } from "react";
import { CssTextField, HelperTextLabel } from "../accountSettings/profile";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import Multiselect from "multiselect-react-dropdown";
import { IMultiselectProps } from "multiselect-react-dropdown/dist/multiselect/interface";
import { MultiSelectOption } from "./formUtility";
import NavigateNext from "@mui/icons-material/NavigateNext";
import ToolTipComponent from "./toolTip";
import { tooltip } from "aws-amplify";

interface ParentProps {
  saveValuesHandler?: (values: string[]) => Promise<Boolean>;
  loadValuesHandler: () => Promise<MultiSelectOption[]>;
  loadSelectedValuesHandler?: () => Promise<string[]>;
  title?: string;
  helperText?: string;
}

export type EditableMultiSelectInputComponentProps = ParentProps & IMultiselectProps;

export const ZMulti = styled(Multiselect)`
  .searchWrapper {
    border-bottom: none !important;
  }
  ,
  &.multiSelectContainer ul {
    max-height: 150px;
  }
`;

export const ZMultiWrapper = styled("div")`
  & {
    flex-grow: 1;
  }
  div:first-of-type {
    width: 100%;
  }
`;

const EditableMultiSelectInputComponent: React.FC<EditableMultiSelectInputComponentProps> = ({
  selectedValues,
  id,
  style,
  placeholder,
  helperText,
  title,
  singleSelect,
  saveValuesHandler,
  loadValuesHandler,
  loadSelectedValuesHandler,
}: EditableMultiSelectInputComponentProps) => {
  
  const [open, setOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selValues, setSelValues] = useState<MultiSelectOption[]>([]);
  const [options, setOptions] = useState<MultiSelectOption[]>([]);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const multiselectRef = createRef<Multiselect>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("mobile"));

  const handleClose = () => {
    setOpen(false);
    setIsEditing(false);
  };

  const handleModalClose = (event: any, reason: any) => {
    if (reason && reason === "backdropClick") return;

    handleClose();
  };

  const beginEditing = () => {
    console.log("begin editing");

    setIsEditing(true);
    console.log("isMobile", isMobile);
    if (isMobile) {
      setOpen(true);
    } else {
    }
  };

  const cancel = () => {
    setIsEditing(false);
  };

  const save = async () => {
    if (multiselectRef && multiselectRef.current) {
      setIsProcessing(true);
      let selectedItems = multiselectRef.current.getSelectedItems();
      let selectedIds: string[] = [];

      selectedItems.map((obj: any, idx: number) => {
        selectedIds.push(obj.id);
      });

      if (saveValuesHandler) {
        const response = await saveValuesHandler(selectedIds);
        setIsProcessing(false);
        if (response) {
          setSelValues(selectedItems);
          setSelectedIds(selectedIds);
          setIsEditing(false);

          if (isMobile) handleClose();
        }
      }
      
    }
  };

  const onSelect = (selectedList: any, selectedItem: any) => {
    console.log("added");
  };

  const onRemove = (selectedList: any, removedItem: any) => {
    console.log("removed");
  };

  const updateSelectedValues = (selectedList: any) => {
    let selectedIds: string[] = [];

    selectedList.map((obj: any, idx: number) => {
      selectedIds.push(obj.id);
    });
    setSelectedIds(selectedIds);
  };

  const getData = (): Promise<any> => {
    var results = [];
    if (loadSelectedValuesHandler)
      results.push(loadSelectedValuesHandler());
    results.push(loadValuesHandler());
    return Promise.all(results);
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    let results = await getData();
    setSelectedIds(results[0]);
    setOptions(results[1]);
    let selectedOptionsList: MultiSelectOption[] = [];

    results[0].map((id: string, idx: number) => {
      const foundOption = results[1].find((o: MultiSelectOption) => o.id === id);
      if (foundOption) selectedOptionsList.push(foundOption);
    });
    console.log("selectedOptionsList", selectedOptionsList);
    setSelValues(selectedOptionsList);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        {(!isEditing || isMobile) && (
          <>
            <div style={{flexGrow: 1}}>
              <div style={{ padding: 10, border: "1px solid gray", borderRadius: 10, width: "100%" }}>
                {isLoading && (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    <CircularProgress size={20} />
                  </div>
                )}
                {!isLoading &&
                  selectedIds.map((id: string, idx: number) => {
                    const foundOption = options.find((o) => o.id === id);

                    if (foundOption)
                      return (
                        <span key={`chip_${idx}`} className="chip" style={{ background: "rgb(0, 150, 255)" }}>
                          {foundOption.name}
                        </span>
                      );
                    else return <></>;
                })}
                {!isLoading &&
                selectedIds.length == 0 &&

                  <span key={`chip_none`} >
                    None Selected    
                  </span>
                }
              </div>
              {!isMobile && helperText &&
              <div>
                <HelperTextLabel>{helperText}</HelperTextLabel>
              </div>
              }
            </div>
            <Button color="primary" variant="contained" style={{ fontWeight: "bold", marginLeft: 20 }} title="Edit" onClick={beginEditing}>
              <EditIcon />
            </Button>
          </>
        )}
        {isEditing && !isMobile && (
          <>
            <ZMultiWrapper>
              <ZMulti
                ref={multiselectRef}
                style={{
                  chips: {
                    background: theme.palette.primary.main,
                    color:'white'
                  },
                  multiselectContainer: {
                    width: "100%",
                    border: "1px solid gray",
                    borderRadius: 10,
                    color: theme.palette.primary.main,
                    flexGrow: 1,
                  },
                  searchBox: {
                    border: "none",
                    borderBottom: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: 0,
                  },
                }}
                options={options} // Options to display in the dropdown
                selectedValues={selValues}
                singleSelect={singleSelect}
                //selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                onSelect={onSelect} // Function will trigger on select event
                onRemove={onRemove} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
                placeholder={placeholder}
                avoidHighlightFirstOption={true}
              />
              <div>
                <HelperTextLabel>{helperText}</HelperTextLabel>
              </div>
            </ZMultiWrapper>
            <Button color="secondary" variant="outlined" style={{ fontWeight: "bold", marginLeft: 20 }} title="Cancel" onClick={cancel}>
              <CancelIcon />
            </Button>
            <Button color="primary" variant="contained" style={{ fontWeight: "bold", marginLeft: 20 }} title="Save" onClick={save}>
              {isProcessing && <CircularProgress size={20} style={{ color: "white" }} />}
              {!isProcessing && <SaveIcon />}
            </Button>
          </>
        )}
        {isEditing && isMobile && (
          <>
            <Dialog
              open={open}
              TransitionComponent={Grow as React.ComponentType}
              keepMounted
              fullWidth={true}
              hideBackdrop={true}
              onClose={handleModalClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent>
                <ZMultiWrapper>
                <ToolTipComponent
                  htmlFor={`${id}_mobile`}
                  label={title}
                  style={{marginBottom:10}}
                  tooltip={helperText}
                />
                  <ZMulti
                    id={`${id}_mobile`}
                    ref={multiselectRef}
                    style={{
                      chips: {
                        background: theme.palette.primary.main,
                        color:"white"
                      },
                      multiselectContainer: {
                        width: "100%",
                        height: "350px",
                        border: "1px solid gray",
                        borderRadius: 10,
                        color: theme.palette.primary.main,
                        flexGrow: 1,
                      },
                      searchBox: {
                        border: "none",
                        borderBottom: `1px solid ${theme.palette.primary.main}`,
                        borderRadius: 0,
                      },
                    }}
                    singleSelect={singleSelect}
                    options={options} // Options to display in the dropdown
                    selectedValues={selValues}
                    //selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                    onSelect={onSelect} // Function will trigger on select event
                    onRemove={onRemove} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                    placeholder={placeholder}
                    avoidHighlightFirstOption={true}
                  />
                </ZMultiWrapper>
              </DialogContent>
              <DialogActions>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => {
                    handleModalClose(null, null);
                  }}
                >
                  <CancelIcon color="secondary" style={{ marginRight: 5 }} />
                  Cancel
                </Button>
                <Button color="primary" variant="contained" style={{ fontWeight: "bold", marginLeft: 20 }} title="Save" onClick={save}>
                  {isProcessing && <CircularProgress size={20} style={{ color: "white", marginRight: 5 }} />}
                  {!isProcessing && <SaveIcon style={{ marginRight: 5 }} />}
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </div>
    </>
  );
};

export default EditableMultiSelectInputComponent;
