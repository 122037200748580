import * as React from 'react';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../ServiceWrapper';

export const AuthRoute: React.FC = ({children}) => {
  const { currentUser } = useContext(UserContext);
  const shouldRedirect = (currentUser === null);

  return shouldRedirect ? <Navigate to="/" /> : <>{children}</>;
}