import { Typography } from "@mui/material";
import React from "react";

const TermsOfServicePage = () => {
    
  return (
      <>
        
        <Typography component="h2">
            Terms of Service
        </Typography>
    </>
  );
};

export default TermsOfServicePage;