import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import {
  Autocomplete, Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  Link,
  TextField, useMediaQuery,
  useTheme,
  styled
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CssTextField, HelperTextLabel } from "../accountSettings/profile";
import { MultiSelectOption } from "./formUtility";
import ToolTipComponent from "./toolTip";

interface ParentProps {
  saveHandler: (value: string | null) => Promise<Boolean>;
  loadValuesHandler: () => Promise<MultiSelectOption[]>;
  loadSelectedValueHandler: () => Promise<MultiSelectOption | null>;
  showAsLink?: boolean;
  id: string;
  maxWidth?: number;
  mobileStyle?: React.CSSProperties;
  label?: string;
  helperText?: string;
  placeholder?: string;
}

export type AutocompleteInputComponentProps = ParentProps;

export const ZAutocomplete = styled(Autocomplete)`
  .MuiAutocomplete-listbox {
    background-color:red;
  }
  ,
  &.li {
    background-color:orange;
  }
`;

const AutocompleteInputComponent: React.FC<AutocompleteInputComponentProps> = ({
  mobileStyle,
  showAsLink,
  maxWidth,
  label,
  id,
  helperText,
  placeholder,
  saveHandler,
  loadSelectedValueHandler,
  loadValuesHandler,
}: AutocompleteInputComponentProps) => {
  
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isOverflown, setIsOverflown] = useState<boolean>(false);
  const [val, setVal] = React.useState<MultiSelectOption | null>(null);
  const [mobileVal, setMobileVal] = React.useState<MultiSelectOption | null>(null);
  const [originalVal, setOriginalVal] = React.useState<MultiSelectOption | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openAutoMobile, setOpenAutoMobile] = useState<boolean>(false);
  const [openAuto, setOpenAuto] = useState<boolean>(false);
  const [options, setOptions] = React.useState<readonly MultiSelectOption[]>([]);
  const loading = (openAuto || openAutoMobile) && options.length === 0;

  const txtRef = useRef<HTMLInputElement>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  
  const handleClose = () => {
    setOpen(false);
    setIsEditing(false);
  };

  const handleModalClose = (event: any, reason: any) => {
    if (reason && reason === "backdropClick") return;

    handleClose();
  };

  const beginEditing = () => {
    console.log("begin editing");

    setIsEditing(true);
    console.log("isMobile", isMobile);
    
    if (isMobile) {
      setMobileVal(originalVal);
      setOpen(true);
    }

  };

  
  const cancel = () => {
    setIsEditing(false);
    setVal(originalVal);

    if (isMobile)
      handleClose();
  };

  const save = async () => {
    setIsProcessing(true);
    
    let submissionId = val?.id as string;
    if (isMobile)
      submissionId = mobileVal?.id as string

    const response = await saveHandler(submissionId);
    setIsProcessing(false);
    
    if (response) {
      if (isMobile) {
        setVal(mobileVal);
        setOriginalVal(mobileVal);
      }
      else {
        setOriginalVal(val);
      }
      setIsEditing(false);
    }
  };


  const EditButton = () => {
    return (
      <Button color="primary" variant="contained" style={{ fontWeight: "bold", marginLeft: 20 }} title="Edit" onClick={beginEditing}>
        <EditIcon />
      </Button>
    );
  };


  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        setOptions(await loadValuesHandler());
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  const loadSelectedVal = useCallback(async() => {
    let selectVal = await loadSelectedValueHandler();
    setVal(selectVal);
    setOriginalVal(selectVal);
  },[]);

  useEffect(() => {
    loadSelectedVal();
  },[]);

  const displayLink = (showAsLink);

  if (isEditing) {
    if (isMobile) {
      return (
        <>
        <Dialog
          open={open}
          TransitionComponent={Grow as React.ComponentType}
          keepMounted
          fullWidth={true}
          hideBackdrop={true}
          onClose={handleModalClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
          <div style={{ flexGrow: 1}}>
            <ToolTipComponent
              htmlFor={id}
              label={label}
              style={{marginBottom:10}}
              tooltip={
                isMobile
                  ? helperText
                  : ""
              }
            />
            <Autocomplete
              id={id}
              sx={{ width: '100%' }}
              open={openAutoMobile}
              onOpen={() => {
                setOpenAutoMobile(true);
              }}
              onClose={() => {
                setOpenAutoMobile(false);
              }}
              onChange={(event: any, newValue: MultiSelectOption | null) => {
                setMobileVal(newValue);
              }}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name as string}
              options={options}
              loading={loading}
              value={mobileVal}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={placeholder}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            {helperText && !isMobile &&
            <div>
              <HelperTextLabel>{helperText}</HelperTextLabel>
            </div>
            }
          </div>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={cancel}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <CancelIcon color="secondary" style={{ marginRight: 5 }} />
                <span>Cancel</span>
              </div>
            </Button>
            <Button color="primary" variant="contained" style={{ fontWeight: "bold", marginLeft: 20 }} title="Save" onClick={save}>
              {isProcessing && <CircularProgress size={20} style={{ color: "white", marginRight: 5 }} />}
              {!isProcessing && <SaveIcon style={{ marginRight: 5 }} />}
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <div style={{ flexGrow: 1 }}>
          <ToolTipComponent
            htmlFor={id}
            label={label}
            style={{marginBottom:5}}
            tooltip={isMobile ? helperText : ""}
          />
          <CssTextField
            id={id}
            type="text"
            value={(val?.name ? val?.name : "None Provided")}
            style={{flexGrow:1}}
            helperText={isMobile ? "" : helperText}
            inputProps={{ readOnly: true }}
            variant={"standard"}
          />
        </div>
        <EditButton />
        </>
      );
    } else {
      return (
        <>
          <div style={{ flexGrow: 1 }}>
            <ToolTipComponent
              htmlFor={id}
              label={label}
              style={{marginBottom:5}}
              tooltip={
                isMobile
                  ? helperText
                  : ""
              }
            />
            <Autocomplete
              id={id}
              sx={{ width: '100%' }}
              open={openAuto}
              onOpen={() => {
                setOpenAuto(true);
              }}
              onClose={() => {
                setOpenAuto(false);
              }}
              onChange={(event: any, newValue: MultiSelectOption | null) => {
                setVal(newValue);
              }}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name as string}
              options={options}
              loading={loading}
              value={val}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={placeholder}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            {helperText && !isMobile &&
            <div>
              <HelperTextLabel>{helperText}</HelperTextLabel>
            </div>
            }
          </div>

          <Button color="secondary" variant="outlined" style={{ fontWeight: "bold", marginLeft: 20 }} title="Cancel" onClick={cancel}>
            <CancelIcon />
          </Button>
          <Button color="primary" variant="contained" style={{ fontWeight: "bold", marginLeft: 20 }} title="Save" onClick={save}>
            {isProcessing && <CircularProgress size={20} style={{ color: "white" }} />}
            {!isProcessing && <SaveIcon />}
          </Button>
        </>
      );
    }
  } else {
    
    return (
      <>
        <div style={{ flexGrow: 1 }}>
          <ToolTipComponent
            htmlFor={id}
            label={label}
            style={{marginBottom:5}}
            tooltip={isMobile ? helperText : ""}
          />
          <CssTextField
            id={id}
            type="text"
            value={(val?.name ? val?.name : "None Provided")}
            style={{flexGrow:1}}
            helperText={isMobile ? "" : helperText}
            inputProps={{ readOnly: true }}
            variant={"standard"}
          />
        </div>
        <EditButton />
      </>
    );
    
  }
};

export default AutocompleteInputComponent;
