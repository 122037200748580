import { HubPayload } from "@aws-amplify/core";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grow } from "@mui/material";
import { Hub } from "aws-amplify";
import React, { useEffect, useState } from "react";


const GlobalErrorController = () => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onGlobalError = (payload: HubPayload) => {
    setOpen(true);
  }
  
  useEffect(() => {
    Hub.listen('globalError', (data) => {
      const { payload } = data;
      console.log('global error received');
      console.log(payload);
      onGlobalError(payload);
  })
  }, []);

  

  return (
    <Dialog
      open={open}
      style={{zIndex:100001}}
      TransitionComponent={Grow as React.ComponentType}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Uh Oh! An error has occurred"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          The last request failed to proccess successfully.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GlobalErrorController;
