import { ApolloError } from "@apollo/client";
import { useMsal } from "@azure/msal-react";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NavigateBack from "@mui/icons-material/NavigateBefore";
import NavigateNext from "@mui/icons-material/NavigateNext";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Button, CircularProgress, Dialog, DialogActions,
  DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, Grow,
  Link, Switch, TextField, Typography
} from "@mui/material";
import moment from "moment";
import { useContext, useMemo, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Helmet } from "react-helmet";
import QRCode from "react-qr-code";
import { toast } from "react-toastify";
import { b2cPolicies } from "../../authConfig";
import { GlobalCacheKeys } from "../../cache/globalCacheKeys";
import {
  ChangeCurrentUserRecoveryEmailMutation,
  InitializeTwoFaMutation, SendEmailCodeMutation,
  TwoFaSetupQuery,
  useChangeCurrentUserRecoveryEmailMutation,
  useInitializeTwoFaMutation, useRemoveTwoFaMutation, useSendEmailCodeMutation,
  useTwoFaSetupLazyQuery,
  useVerifyEmailCodeLazyQuery, VerifyEmailCodeQueryResult
} from "../../generated";
import { TwoFAVerificationResult, UserContext } from "../../ServiceWrapper";
import styles from "../../styles/security.module.css";
import { CssTextField } from "./profile";

const SecurityComponent = () => {
  const {
    globalCache,
    currentUser,
    setCurrentUser,
    verifyTwoFA,
    verifyEmailCode,
    showProcessing,
    hideProcessing,
    twoFAVerificationResult,
    setTwoFAVerificationResult,
  } = useContext(UserContext);
  const { instance, accounts } = useMsal();
  const [twoFASecret, setTwoFASecret] = useState<string>("");
  const [twoFACode, setTwoFACode] = useState<string>("");
  const [codeTime, setCodeTime] = useState<number>(0);
  const [twoFARecoveries, setTwoFARecoveries] = useState<string[]>([]);
  const [twoFASetupSuccess, setTwoFASetupSuccess] = useState<boolean>(false);
  const [showTwoFADialog, setShowTwoFADialog] = useState<boolean>(false);
  const [showManualTwoFASetup, setShowManualTwoFASetup] = useState<boolean>(false);

  const [newRecoveryEmail, setNewRecoveryEmail] = useState<string>("");
  const [emailDialogProcessing, setEmailDialogProcessing] = useState<boolean>(false);
  const [showChangeEmailDialog, setShowChangeEmailDialog] = useState<boolean>(false);
  const [changeEmailDialogStep, setChangeEmailDialogStep] = useState<number>(1);
  const [newRecoveryEmailConfirmationCode, setNewRecoveryEmailConfirmationCode] = useState<string>("");
  const [newRecoveryEmailErrorText, setNewRecoveryEmailErrorText] = useState<string>("");
  const [processingEmailVerification, setProcessingEmailVerification] = useState<boolean>(false);

  const [initializeTwoFA] = useInitializeTwoFaMutation();
  const [removeTwoFA] = useRemoveTwoFaMutation();
  const [changeRecoveryEmailMutation] = useChangeCurrentUserRecoveryEmailMutation();
  const [sendEmailCode] = useSendEmailCodeMutation();

  const [verifyEmailCodeQuery] = useVerifyEmailCodeLazyQuery({
    fetchPolicy: "cache-and-network",
    onError(error: ApolloError) {
      toast.error("An error occurred while verifying your email code.");
      console.log(error);
    },
  });

  const initTwoFA = () => {
    initializeTwoFA({
      variables: {
        code: twoFACode,
        codeEntryTime: codeTime,
      },
      onCompleted(data: InitializeTwoFaMutation) {
        if (data?.initializeTwoFA?.error) {
          toast.error(data?.initializeTwoFA?.error);
          return;
        }

        if (data?.initializeTwoFA?.recoveryCodes) {
          let usr = { ...currentUser };
          usr.is2FASetup = true;
          setCurrentUser(usr);

          setTwoFARecoveries(data?.initializeTwoFA?.recoveryCodes);
          toast.success("Setup 2FA Successfully!");
          setTwoFASetupSuccess(true);
        } else {
          toast.error("An error occurred while setting up your 2FA.");
        }
      },
      onError(error: ApolloError) {
        toast.error("An error occurred while initializing 2FA, please try again.");
        console.log(error);
      },
    });
  };

  const [getTwoFASetup] = useTwoFaSetupLazyQuery({
    fetchPolicy: "cache-and-network",
    onCompleted(data: TwoFaSetupQuery) {
      if (data.twoFASetup?.twoFASecret) setTwoFASecret(data.twoFASetup?.twoFASecret);

      setShowTwoFADialog(true);
    },
    onError(error: ApolloError) {
      console.log("an error occurred");
      console.log(error);
    },
  });

  const toggleManualSetup = () => {
    setShowManualTwoFASetup(!showManualTwoFASetup);
  };

  const qrCodeURL = useMemo(() => {
    if (!currentUser?.email) return "";

    let encodedEmail = encodeURIComponent(currentUser?.email);
    let encodedTwoFASecret = encodeURIComponent(twoFASecret);
    let url = `otpauth://totp/Miadara:${encodedEmail}?secret=${encodedTwoFASecret}&issuer=Miadara`;

    return url;
  }, [twoFASecret]);

  const handle2FAChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      verifyTwoFA(async (result: TwoFAVerificationResult) => {
        setTwoFACode("");
        getTwoFASetup();
        return true;
      });
    } else {
      verifyTwoFA(async (result: TwoFAVerificationResult) => {
        return await removeTwoFA({
          variables: {
            code: result.code,
            codeEntryTime: result.time,
          },
          onError(error: ApolloError) {
            toast.error("An error occurred while disabling 2FA, please try again.");
            console.log(error);
          },
        }).then((val: any) => {
          if (val?.data?.removeTwoFA?.error) {
            toast.error(val?.data?.removeTwoFA?.error);
            return false;
          }

          if (val?.data?.removeTwoFA?.success) {
            let usr = { ...currentUser };
            usr.is2FASetup = false;
            setCurrentUser(usr);
            toast.success("Two-Factor Authentication has been disabled");
            return true;
          }

          toast.error("An error occurred while disabling Two-Factor Authentication.");
          return false;
        });
      });
    }
  };

  const handleTwoFADialogClose = (event: any, reason: any) => {
    if (reason && reason === "backdropClick") return;

    setShowTwoFADialog(false);
    setTimeout(() => {
      setTwoFASetupSuccess(false);
    }, 500);
  };

  const handleTwoFACodeChange = (event: any) => {
    setTwoFACode(event.target.value);
    setCodeTime(moment().unix());
  };

  const copySecretToClipboard = () => {
    copyToClipboard(twoFASecret);
  };

  const copyRecoveriesToClipboard = () => {
    copyToClipboard(twoFARecoveries.join(", "));
  };

  const changePassword = () => {
    //challengeEmailCode();

    globalCache.setItem(GlobalCacheKeys.LAST_VISITED_PAGE, "/accountSettings/security");
    instance.loginRedirect({
      authority: b2cPolicies.authorities.changePassword.authority,
      scopes: [],
    });
  };

  const challengeTwoFA = () => {
    /*verifyTwoFA((result: TwoFAVerificationResult) => {
      
      console.log(result);
    }, () => {
      console.log('cancelled');
    });*/
  };

  const challengeEmailCode = () => {};

  function copyToClipboard(message: string) {
    var textArea = document.createElement("textarea");
    textArea.value = message;
    textArea.style.opacity = "0";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      if (successful) toast.info("Copy to clipboard successful");
      else toast.error("Failed to copy the text");
    } catch (err: any) {
      toast.error("Unable to copy the text, error: " + err.message);
    }

    document.body.removeChild(textArea);
  }

  const validateNewRecoveryEmail = () => {
    let regexp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (newRecoveryEmail !== "" && !regexp.test(newRecoveryEmail)) {
      setNewRecoveryEmailErrorText("Invalid email");
    } else setNewRecoveryEmailErrorText("");
  };

  const handleNewRecoveryEmailChange = (event: any) => {
    setNewRecoveryEmail(event.target.value);
  };

  const handleNewRecoveryEmailConfirmationCodeChange = (event: any) => {
    setNewRecoveryEmailConfirmationCode(event.target.value);
  };

  const handleNewRecoveryEmailVerification = async () => {
    setProcessingEmailVerification(true);

    let verifyResponse = await verifyEmailCodeQuery({
      variables: {
        code: newRecoveryEmailConfirmationCode,
        isCurrentEmail: false,
      },
    }).then((val: VerifyEmailCodeQueryResult) => {
      setProcessingEmailVerification(false);

      if (val?.data?.verifyEmailCode) {
        setChangeEmailDialogStep(3);
        return true;
      } else {
        toast.error("Invalid email code.");
        return false;
      }
    });

    if (!verifyResponse) return;

    changeRecoveryEmailMutation({
      variables: {
        newRecoveryEmail: newRecoveryEmail,
        code: twoFAVerificationResult.code,
        codeEntryTime: twoFAVerificationResult?.time,
      },
      onCompleted(data: ChangeCurrentUserRecoveryEmailMutation) {
        if (data?.changeCurrentUserRecoveryEmail?.error) {
          toast.error(data?.changeCurrentUserRecoveryEmail?.error);
          setChangeEmailDialogStep(1);
          return;
        }

        let usr = { ...currentUser };
        usr.recoveryEmail = newRecoveryEmail;
        setCurrentUser(usr);
        toast.success("Successfully changed recovery email.");
        setShowChangeEmailDialog(false);
      },
      onError(error: ApolloError) {
        setChangeEmailDialogStep(1);
        toast.error("An error occurred while changing your recovery email.");
        console.log(error);
      },
    });
  };

  const handleNewRecoveryEmailSendCode = async () => {
    if (newRecoveryEmail.toLowerCase() === currentUser?.email?.toLowerCase()) {
      toast.error("Your email is already " + newRecoveryEmail);
      return;
    }

    if (newRecoveryEmail === "") {
      toast.error("You must enter a new email first.");
      return;
    }

    setEmailDialogProcessing(true);

    sendEmailCode({
      variables: {
        email: newRecoveryEmail,
      },
      onCompleted(data: SendEmailCodeMutation) {
        setEmailDialogProcessing(false);

        if (data?.sendEmailCode?.error) {
          toast.error(data?.sendEmailCode?.error);
          setChangeEmailDialogStep(1);
          return;
        }

        setChangeEmailDialogStep(2);
      },
      onError(error: ApolloError) {
        setEmailDialogProcessing(false);
        toast.error("An error occurred while sending the email verification code.");
        console.log(error);
        setChangeEmailDialogStep(1);
      },
    });
  };

  const changeRecoveryEmail = () => {
    verifyTwoFA((result: TwoFAVerificationResult) => {
      setNewRecoveryEmail("");
      setNewRecoveryEmailConfirmationCode("");
      setNewRecoveryEmailErrorText("");
      setChangeEmailDialogStep(1);
      setShowChangeEmailDialog(true);
    });
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <Helmet>
          <link rel="stylesheet" href="" />
        </Helmet>
        <h1 style={{ textAlign: "center" }}>Security Settings</h1>
        <div style={{ marginLeft: 20 }}>
        <FormControl component="fieldset" variant="standard" style={{ display: "block" }}>
            <FormLabel component="legend"></FormLabel>
            <FormGroup style={{ marginTop: 20 }}>
              <div>
                <Button color="primary" variant="contained" style={{ fontWeight: "bold" }} onClick={changePassword}>
                  Change Password
                </Button>
              </div>
            </FormGroup>
          </FormControl>
          <FormControl component="fieldset" variant="standard" style={{marginTop:20}}>
            <FormLabel component="legend"></FormLabel>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={!!currentUser?.is2FASetup} onChange={handle2FAChange} />}
                label="Enable 2-Factor Authentication"
              />
            </FormGroup>
            <FormHelperText>
              Secure your account by enabling two-factor authentication. After setup, Miadara generates recovery codes to use if you get locked out of
              your account.
            </FormHelperText>
          </FormControl>
          
          <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
            <CssTextField
              id="txtRecoveryEmail"
              type="text"
              value={currentUser?.recoveryEmail ? currentUser.recoveryEmail : "None Provided"}
              label="Recovery Email"
              inputProps={{ readOnly: true }}
              variant="standard"
            />
            <Button color="primary" variant="contained" style={{ fontWeight: "bold", marginLeft: 20 }} onClick={changeRecoveryEmail}>
              <EditIcon />
            </Button>
          </div>
          <Dialog
            open={showTwoFADialog}
            TransitionComponent={Grow as React.ComponentType}
            keepMounted
            fullWidth={true}
            maxWidth={"sm"}
            onClose={handleTwoFADialogClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Setup 2-FA"}</DialogTitle>
            <DialogContent>
              {!twoFASetupSuccess && (
                <ol id="twoFASetupInstructions">
                  <li>Install an authenticator app on your mobile device like Google Authenticator or Microsoft Authenticator</li>
                  <li>Scan the following QR code in your authenticator app</li>
                  <div style={{ background: "white", padding: "16px", textAlign: "center" }}>
                    <QRCode value={qrCodeURL} size={100} />
                  </div>
                  <Accordion disableGutters={true} className={styles.accordianText}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="manualCodeExpander">
                      <Typography>Can’t scan the QR code? Follow alternative steps</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>Configure your authenticator app manually using code:</Typography>
                      <span style={{ backgroundColor: "#f6f6f6", fontSize: 10 }}>{twoFASecret}</span>
                      <CopyToClipboard
                        text={twoFASecret}
                        onCopy={() => {
                          toast.info("Copy succeeded");
                        }}
                      >
                        <Link style={{ display: "block", marginTop: 5 }}>Click to Copy</Link>
                      </CopyToClipboard>
                    </AccordionDetails>
                  </Accordion>
                  <li>Enter the code from your authenticator app below</li>
                  <TextField
                    id="twoFACode"
                    type="text"
                    autoFocus={true}
                    onChange={handleTwoFACodeChange}
                    value={twoFACode}
                    inputProps={{ maxLength: 6 }}
                    style={{ marginTop: 10, width:'100%' }}
                    label="2FA Code"
                    variant="standard"
                  />
                </ol>
              )}

              {twoFASetupSuccess && (
                <div>
                  <h2>Fallback recovery codes</h2>
                  <Typography>
                    Copy your recovery codes and keep them safe. You can use each of these codes one time each if you lose access to your device.
                  </Typography>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: 20 }}>
                    <Grid
                      id="gridRecoveryCodes"
                      container
                      spacing={2}
                      columns={{ xs: 2, sm: 2, md: 3, lg: 3 }}
                      style={{ display: "flex", justifyContent: "center", maxWidth: 1400 }}
                    >
                      {twoFARecoveries.map((val, idx) => {
                        return (
                          <Grid item key={`gridItem_${idx}`} xs={1} sm={1} md={1} lg={1} style={{ display: "flex", justifyContent: "center" }}>
                            <span>{val}</span>
                          </Grid>
                        );
                      })}
                    </Grid>
                    <CopyToClipboard
                      text={twoFARecoveries.join(", ")}
                      onCopy={() => {
                        toast.info("Copy succeeded");
                      }}
                    >
                      <Link style={{ display: "block", marginTop: 10 }}>Click to copy</Link>
                    </CopyToClipboard>
                  </div>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              {!twoFASetupSuccess && (
                <>
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                      handleTwoFADialogClose(null, null);
                    }}
                  >
                    <>
                      <CancelIcon color="secondary" style={{ marginRight: 5 }} />
                      Cancel
                    </>
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      initTwoFA();
                    }}
                  >
                    <>
                        Next
                        <NavigateNext style={{ marginLeft: 5 }} />
                      </>
                  </Button>
                </>
              )}
              {twoFASetupSuccess && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    handleTwoFADialogClose(null, null);
                  }}
                >
                  OK
                </Button>
              )}
            </DialogActions>
          </Dialog>
          <Dialog
            open={showChangeEmailDialog}
            TransitionComponent={Grow as React.ComponentType}
            keepMounted
            fullWidth={true}
            maxWidth={"xs"}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Change Recovery Email"}</DialogTitle>
            <DialogContent>
              {changeEmailDialogStep === 1 && (
                <TextField
                  id="newRecoveryEmail"
                  type="email"
                  error={newRecoveryEmailErrorText !== ""}
                  autoFocus={true}
                  onBlur={validateNewRecoveryEmail}
                  onChange={handleNewRecoveryEmailChange}
                  value={newRecoveryEmail}
                  style={{ marginTop: 10, width:'100%' }}
                  label="New Recovery Email"
                  helperText={newRecoveryEmailErrorText}
                  variant="standard"
                />
              )}
              {changeEmailDialogStep === 2 && (
                <>
                  <Typography>Enter the code sent to {newRecoveryEmail}.</Typography>
                  <TextField
                    id="newRecoveryEmailConfirmationCode"
                    type="text"
                    autoFocus={true}
                    onChange={handleNewRecoveryEmailConfirmationCodeChange}
                    inputProps={{ maxLength: 6 }}
                    value={newRecoveryEmailConfirmationCode}
                    style={{ marginTop: 10, width:'100%' }}
                    label="Code"
                    variant="standard"
                  />
                </>
              )}
              {changeEmailDialogStep === 3 && (
                <>
                  <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress />
                    <h3 style={{ marginLeft: 20 }}>Updating recovery email</h3>
                  </div>
                </>
              )}
            </DialogContent>
            <DialogActions>
              {changeEmailDialogStep === 1 && (
                <>
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                      setShowChangeEmailDialog(false);
                    }}
                  >
                    <>
                      <CancelIcon color="secondary" style={{ marginRight: 5 }} />
                      Cancel
                    </>
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={newRecoveryEmailErrorText !== "" || emailDialogProcessing}
                    onClick={() => {
                      handleNewRecoveryEmailSendCode();
                    }}
                  >
                    {emailDialogProcessing && <CircularProgress size={20} />}
                    {!emailDialogProcessing && (
                      <>
                        Next
                        <NavigateNext style={{ marginLeft: 5 }} />
                      </>
                    )}
                  </Button>
                </>
              )}
              {changeEmailDialogStep === 2 && (
                <>
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                      setChangeEmailDialogStep(1);
                    }}
                  >
                    <>
                      <NavigateBack style={{ marginRight: 5 }} />
                      Back
                    </>
                  </Button>
                  <Button
                    disabled={processingEmailVerification}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      handleNewRecoveryEmailVerification();
                    }}
                  >
                    Verify
                  </Button>
                </>
              )}
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default SecurityComponent;
