import { ApolloError } from "@apollo/client";
import { useMsal } from "@azure/msal-react";
import CancelIcon from "@mui/icons-material/Cancel";
import NavigateBack from "@mui/icons-material/NavigateBefore";
import NavigateNext from "@mui/icons-material/NavigateNext";
import {
  Box, Button, CircularProgress, Dialog, DialogActions,
  DialogContent, DialogTitle, Grow, ToggleButton,
  ToggleButtonGroup, Typography, useMediaQuery,
  useTheme
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CancelCurrentUserPlanMutation, CurrentUserSubscriptionQuery, CurrentUserUpdateSubscriptionCardUrlQuery, CurrentUserUpdateSubscriptionUrlQuery, ProcessRegistrationPlanMutation, useCancelCurrentUserPlanMutation, useCurrentUserSubscriptionLazyQuery, useCurrentUserUpdateSubscriptionCardUrlLazyQuery, useCurrentUserUpdateSubscriptionUrlLazyQuery, useProcessRegistrationPlanMutation, UserSubscriptionDto
} from "../../generated";
import { TwoFAVerificationResult, UserContext } from "../../ServiceWrapper";
import styles from "../../styles/subscription.module.css";
import PlanPricingTableComponent, { Plan, PLAN_TYPE } from "../planPricingTable";
import PlanData from "../../data/pricingPlans.json";

const SubscriptionComponent = () => {
  const {
    globalCache,
    currentUser,
    setCurrentUser,
    verifyTwoFA,
    verifyEmailCode,
    showProcessing,
    hideProcessing,
    twoFAVerificationResult,
    setTwoFAVerificationResult,
    setGlobalTimeout,
    clearGlobalTimeout
  } = useContext(UserContext);

  const { instance, accounts } = useMsal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  let [searchParams, setSearchParams] = useSearchParams();
  
  const [planType, setPlanType] = useState<PLAN_TYPE>(PLAN_TYPE.YEARLY);
  const [subscriptionPlanURL, setSubscriptionPlanURL] = useState<string>("");
  const [subscriptionPlan, setSubscriptionPlan] = useState<Plan | null>(null);
  const [loadingSubscription, setLoadingSubscription] = useState<boolean>(false);
  const [processingPlanChange, setProcessingPlanChange] = useState<boolean>(false);
  const [loadingNewSubscription, setLoadingNewSubscription] = useState<boolean>(false);
  const [showPlanChangeConfirmationDialog, setShowPlanChangeConfirmationDialog] = useState<boolean>(false);
  const [userSubscription, setUserSubscription] = useState<UserSubscriptionDto | null | undefined>(null);
  const [reloadTimeout, setReloadTimeout] = useState<any>(null);
  const navigate = useNavigate();
  
  const hostedPageId = searchParams.get("hostedpage_id");
  const updateCard = searchParams.get("updateCard");

  const [processRegistrationPlan] = useProcessRegistrationPlanMutation();

  const handlePlanTypeChange = (event: React.MouseEvent<HTMLElement>, planType: PLAN_TYPE) => {
    setPlanType(planType);
  };

  const [cancelCurrentUserPlan] = useCancelCurrentUserPlanMutation();
  
  const [getCurrentUserSubscription] = useCurrentUserSubscriptionLazyQuery({
    fetchPolicy: "cache-and-network",
    onCompleted(data: CurrentUserSubscriptionQuery) {
      if (data?.currentUserSubscription) {
        setUserSubscription(data.currentUserSubscription);
        setPlanTypeForUserSubscription(data?.currentUserSubscription);
      } else {
        setUserSubscription(null);
      }
      setLoadingSubscription(false);
    },
    onError(error: ApolloError) {
      console.log("an error occurred");
      console.log(error);
    },
  });

  const [getCurrentUserUpdateSubscriptionCard] = useCurrentUserUpdateSubscriptionCardUrlLazyQuery({
    fetchPolicy: "cache-and-network",
    onCompleted(data: CurrentUserUpdateSubscriptionCardUrlQuery) {
      if (data?.currentUserUpdateSubscriptionCardURL) {
        setSubscriptionPlanURL(data?.currentUserUpdateSubscriptionCardURL);
      } else {
        setSubscriptionPlanURL("");
      }
    },
    onError(error: ApolloError) {
      console.log("an error occurred");
      console.log(error);
    },
  });

  const [getCurrentUserUpdateSubscriptionUrl] = useCurrentUserUpdateSubscriptionUrlLazyQuery({
    fetchPolicy: "cache-and-network",
    onCompleted(data: CurrentUserUpdateSubscriptionUrlQuery) {
      setProcessingPlanChange(false);

      if (data?.currentUserUpdateSubscriptionURL)
        setSubscriptionPlanURL(data?.currentUserUpdateSubscriptionURL);
      else {
        setSubscriptionPlanURL("");
        setSubscriptionPlan(null);
        toast.error('An error occurred while trying to update your subscription.');
      } 
      setShowPlanChangeConfirmationDialog(false);
    }
  });
/*
  useEffect(() => {
    if (hostedPageId === null && updateCard === null) {
      setLoadingSubscription(true);
      getCurrentUserSubscription();
    }
  }, []);
*/
  useEffect(() => {
    if (subscriptionPlanURL !== "") {

      // Set a timeout to reload the page after 30 minutes to ensure the session remains active while the user is attempting to subscribe
      setGlobalTimeout(() => {
        let timeout = setTimeout(() => {
          window.location.reload();
        }, 30 * 60 * 1000);
        return timeout;
      });

      let iframe = document.getElementById("payForPlanFrame");
      if (iframe != null) {
        //@ts-ignore
        iframe.src = subscriptionPlanURL;
      }
    }
    else {
      clearGlobalTimeout();
    }
  }, [subscriptionPlanURL]);

  useEffect(() => {
    if (subscriptionPlan) {
      
      verifyTwoFA((result: TwoFAVerificationResult) => {
        setTimeout(() => {
          if (userSubscription?.subscriptionId) {
          
            // did the user switch to a free plan?
            setShowPlanChangeConfirmationDialog(true);
          }
          else {
              changePlan();
          }
          
        }, 700);
        
      }, () => {
        setSubscriptionPlan(null);
      });
    }
    
  }, [subscriptionPlan, userSubscription]);

  const setPlanTypeForUserSubscription = (userSubscription: UserSubscriptionDto | undefined | null) => {
    
    if (userSubscription) {
      if (userSubscription.subscriptionId && userSubscription.subscriptionTerms === "yearly") {
        setPlanType(PLAN_TYPE.YEARLY);
      }
      else
        setPlanType(PLAN_TYPE.MONTHLY);
    }
  };


  const changePlan = () => {
    
    setProcessingPlanChange(true);
    
    if (subscriptionPlan?.name === "Novice") {
      cancelCurrentUserPlan({
        onCompleted(data: CancelCurrentUserPlanMutation) {
          setSubscriptionPlan(null);
          setProcessingPlanChange(false);
    
          if (!data.cancelCurrentUserPlan) {
            toast.error("An error occurred while cancelling your existing subscription.");
          } else {
            setCurrentUser(data?.cancelCurrentUserPlan.user);
            setUserSubscription(data?.cancelCurrentUserPlan.userSubscription);
            setPlanTypeForUserSubscription(data?.cancelCurrentUserPlan.userSubscription);
          }
          setShowPlanChangeConfirmationDialog(false);
        }
      });
    }
    else if (subscriptionPlan) {
      
      let pc = subscriptionPlan.type.yearly.planCode;
      if (planType === PLAN_TYPE.MONTHLY)
        pc = subscriptionPlan.type.monthly.planCode;

      getCurrentUserUpdateSubscriptionUrl({variables: {
        planCode: pc
      }});
    }
    
  };

  useEffect(() => {
    if (hostedPageId !== null && updateCard !== "success") {
      
      processRegistrationPlan({
        variables: {
          hostedPageId: hostedPageId,
        },
        onCompleted(data: ProcessRegistrationPlanMutation) {
          console.log("processing finished");

          if (data.processRegistrationPlan?.error) {
            toast.error(data.processRegistrationPlan?.error);
          } else {
            if (data?.processRegistrationPlan?.user == null) {
              toast.error("An error occurred while processing your subscription");
            }
            else {
              setCurrentUser(data?.processRegistrationPlan.user);
              setUserSubscription(data?.processRegistrationPlan.userSubscription);
              setPlanTypeForUserSubscription(data?.processRegistrationPlan.userSubscription);
            }
          }
          navigate("/accountSettings/subscription");
        }
      });
    }
  }, [hostedPageId]);

  useEffect(() => {
    if (hostedPageId === null && updateCard === null) {
      setLoadingSubscription(true);
      getCurrentUserSubscription();
    }
    else if (updateCard === "success") {
      navigate("/accountSettings/subscription");
      toast.success("Successfully updated card info");
    }
  }, [updateCard, hostedPageId]);

  if (hostedPageId !== null && updateCard !== "success") {// process subscription
    return (
      <>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <h1>Initializing New Subscription</h1>
          <CircularProgress />
        </div>
      </>
    );
  }
  else if (subscriptionPlanURL !== "") {// pay for plan
    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-evenly", width: "100%" }}>
          <Button
            color="secondary"
            variant="text"
            onClick={() => {
              setSubscriptionPlan(null);
              setSubscriptionPlanURL("");
            }}
          >
            <>
              <NavigateBack style={{ marginRight: 0 }} />
            </>
          </Button>
          <div style={{ textAlign: "center" }}>
            {subscriptionPlan && <h1>Pay for Plan</h1>}
            {!subscriptionPlan && <h1>Update Card Info</h1>}
          </div>
          <div style={{ minWidth: 64 }}></div>
        </div>
        <Box sx={{ width: "100%" }}>
          <iframe id="payForPlanFrame" title="Plan Payment" style={{ minWidth: "100%", height: isMobile ? 1700 : 1300, border: "none" }} />
        </Box>
      </>
    );
  }
  else {
    return (
      <>
        <div>
          <h1 style={{ textAlign: "center" }}>Plan</h1>
        </div>
        <Box sx={{ width: "100%" }}>
          
          {loadingSubscription &&
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <CircularProgress />
            </div>
          }
          {!loadingSubscription && !userSubscription &&
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <h3 style={{color: "red"}}>Failed to load subscription</h3>
            </div>
          }
          {!loadingSubscription && userSubscription &&
            <>
              <div style={{marginLeft:10,marginRight:10}}>
                <h3 style={{fontSize:"20",fontWeight:"bold",marginTop:0}}>Active Plan:<span style={{display:"inline-block",paddingLeft:10,color:'#7952b3'}}>{userSubscription.subscriptionPlan}</span></h3>
                {userSubscription.subscriptionPlanCode && <h3 style={{fontSize:"20",fontWeight:"bold",marginTop:0}}>Cost:<span style={{paddingLeft:10,color:'gray'}}>{userSubscription.planCost}</span></h3>}
                {userSubscription.subscriptionPlanCode && <h3 style={{fontSize:"20",fontWeight:"bold",marginTop:0}}>Next Renewal Date:<span style={{paddingLeft:10,color:'gray'}}>{userSubscription.renewalDateString}</span></h3>}
                {userSubscription.subscriptionPlanCode && <div className={styles.subRenAndCancel}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                      let novicePlan = PlanData.plans.find(o => o.name === "Novice");
                      if (novicePlan)
                        setSubscriptionPlan(novicePlan);
                    }}
                  >
                    <>
                      Cancel Subscription
                    </>
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={processingPlanChange}
                    onClick={() => {
                      verifyTwoFA(() => {
                        setTimeout(async() => {
                          await getCurrentUserUpdateSubscriptionCard();
                        }, 700);
                      });

                    }}
                  >
                    <>
                      Update Payment Info
                    </>
                  </Button>
                  </div>
                }
              </div>
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  justifyContent: "center",
                }}
              >
                <div style={{ position: "relative" }}>
                  <ToggleButtonGroup color="primary" value={planType} exclusive onChange={handlePlanTypeChange}>
                    <ToggleButton value={PLAN_TYPE.MONTHLY}>Monthly</ToggleButton>
                    <ToggleButton value={PLAN_TYPE.YEARLY}>Yearly</ToggleButton>
                  </ToggleButtonGroup>
                  <div id="planSaveMore" />
                </div>
              </div>
              <PlanPricingTableComponent 
                planType={planType} 
                userSubscription={userSubscription} 
                setSubscriptionPlanURL={setSubscriptionPlanURL} 
                subscriptionPlan={subscriptionPlan}
                setSubscriptionPlan={setSubscriptionPlan}
                processingPlanChange={processingPlanChange}
                 />
            </>
          }
          <Dialog
            open={showPlanChangeConfirmationDialog}
            TransitionComponent={Grow as React.ComponentType}
            keepMounted
            fullWidth={true}
            maxWidth={"xs"}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Confirm Change of Plan"}</DialogTitle>
            <DialogContent>
              <Typography>
                Any time left on the current subscription will be lost. {subscriptionPlan?.name === "Novice" ? "Access to Creator Tools will also be lost." : ""} Do you wish to continue?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  setSubscriptionPlan(null);
                  setShowPlanChangeConfirmationDialog(false);
                }}
              >
                <>
                  <CancelIcon color="secondary" style={{ marginRight: 5 }} />
                  No
                </>
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={processingPlanChange}
                onClick={() => {
                  changePlan();
                }}
              >
                {processingPlanChange && <CircularProgress size={20} />}
                {!processingPlanChange && (
                  <>
                    Yes
                    <NavigateNext style={{ marginLeft: 5 }} />
                  </>
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </>
    );
  }
};

export default SubscriptionComponent;
