import { Button, CircularProgress, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from 'tinymce';

import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { DraftDetailDto, SaveDraftContentMutation, UserDraftQuery, useSaveDraftContentMutation, useUserDraftLazyQuery } from "../../generated";

import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";


import ImagePickerComponent, { IImagePickerComponentReference, IUploadedImageProps } from "./imagePicker";

interface EditDraftProps {
  editDraftTitleSubtitle: (title: string, subTitle: string | null) => void;
  setHeaderDraftIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  setHeaderSavingDraft: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IEditDraftReference {
  updateDraftTitleSubtitle: (title: string, subtitle: string | null) => void;
  saveDraftContent: () => void;
}

const EditDraftComponent = forwardRef<IEditDraftReference, EditDraftProps>((props, ref) => {

  const {id} = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  
  const { editDraftTitleSubtitle, setHeaderDraftIsDirty, setHeaderSavingDraft } = props;

  const [draft, setDraft] = useState<DraftDetailDto | null | undefined>(null);
  const [draftIsDirty, setDraftIsDirty] = useState<boolean>(false);
  const [loadingDraft, setLoadingDraft] = useState<boolean>(false);
  const [editorLoaded, setEditorLoaded] = useState<boolean>(false);
  

  const [title, setTitle] = useState<string>("");
  const [subTitle, setSubTitle] = useState<string | null>("");
  
  const editorRef = useRef<TinyMCEEditor | null>(null);
  
  const [saveDraftContentMutation] = useSaveDraftContentMutation();
  
  const imagePickerRef = useRef<IImagePickerComponentReference>(null);
  

  const updateDraftTitleSubtitle = (title: string, subtitle: string | null) => {
    setTitle(title);
    setSubTitle(subtitle);
  };

  const saveDraftContent = () => {
    saveDraft();
  };

  useImperativeHandle(ref, () => ({
    updateDraftTitleSubtitle,
    saveDraftContent
  }));

  const [getUserDraft] = useUserDraftLazyQuery({
    fetchPolicy: "cache-and-network",
    onCompleted(data: UserDraftQuery) {
      if (data?.userDraft) {
        setDraft(data.userDraft);
        setTitle(data.userDraft.title);
        if (data.userDraft.subtitle)
          setSubTitle(data.userDraft.subtitle);
        else
          setSubTitle(null);

        setLoadingDraft(false);
        
      } else {
        setDraft(null);
      }
      
    }
  });
  
  const saveDraft = () => {
    if (!editorRef.current || !draft?.virtualSections[0])
      return;

    setHeaderSavingDraft(true);

    saveDraftContentMutation({
      variables: {
        draftContentIDHash: draft?.virtualSections[0].draftContentIDHash,
        content: editorRef.current.save()
      },
      onCompleted(data: SaveDraftContentMutation) {
        setHeaderSavingDraft(false);

        if (data?.saveDraftContent.error) {
          toast.error(data?.saveDraftContent.error);
          setDraftIsDirty(true);
        }
      }
    });
  };
  
  const resetDraftVariables = () => {
    setDraft(null);
    setDraftIsDirty(false);
    setEditorLoaded(false);
    setTitle("");
    setSubTitle("");
    setHeaderSavingDraft(false);
  };

  
  useEffect(() => {
      // decode the hash and see if it retrieves something other than 0
    if (id == null) {
      toast.error('Invalid draft Id');
      navigate('/createContent/drafts');
    }
    else {

      setLoadingDraft(true);
      resetDraftVariables();
      // load up the draft
      getUserDraft({
        variables: {
          draftIDHash: id
        }
      });
    }
  }, [])

  useEffect(() => {
    if (draft != null && editorLoaded)
    {
      if (draft.virtualSections 
        && draft.virtualSections[0]
        && draft.virtualSections[0].content) {
          console.log('loading content');
          editorRef.current?.setContent(draft.virtualSections[0].content, {initial: true});
        }
    }
  }, [draft, editorLoaded])

  useEffect(() => {
    if (draft != null && editorLoaded)
    {
      const saveInterval = setInterval(() => {
        if (draftIsDirty)
          saveDraft();
      }, 10000);
      return () => {clearInterval(saveInterval);};
    }
  }, [draft, editorLoaded, draftIsDirty])

  useEffect(() => {
    setHeaderDraftIsDirty(draftIsDirty);
  }, [draftIsDirty])

  const imagePickerComplete = (args: IUploadedImageProps) => {
    console.log('completed picker:', args);
    if (editorRef.current)
      editorRef.current.dispatch('cloudinary-plugin-imageselected', args);
  };
  
  // decode the hash and see if it retrieves something other than 0
  if (id == null) {
    console.log('I should not be here');
    return null;
  }

  if (loadingDraft) {
    return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <CircularProgress />
    </div>
    );
  }

  return (
    <>
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", marginTop: 30 }}>
      <div style={{ width:"100%", display:"flex", justifyContent:"space-between", alignItems:"center" }}>
        <div>
          <Typography style={{fontSize:20,color:"#444444"}}>{title}{subTitle ? ":" : null}</Typography>
          <Typography style={{fontSize:15,color:"#909090"}}>{subTitle}</Typography>
        </div>
        <Button color="primary" variant="contained" style={{ fontWeight: "bold", marginLeft: 20 }} title="Edit Title / Subtitle" onClick={() => {editDraftTitleSubtitle(title, subTitle)}}>
          <EditIcon />
        </Button>
      </div>
      <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: 30 }}>
        <>
          <Editor
            apiKey="0wvuj6qlnycbgzwf7ptp2ecsl2ohmzr8xm51g5236sg9pkpe"
            onInit={(evt, editor) => {
              console.log('editor ready');
              setEditorLoaded(true);
              editorRef.current = editor
              editor.on('cloudinary-plugin-clicked', (event: any) => {
                
                if (imagePickerRef.current) {
                  imagePickerRef.current.showPicker();
                }
              });
            }}
            
            onDirty={() => { console.log('draft dirty');setDraftIsDirty(true)}}
            onSaveContent={() => { console.log('saved content'); setDraftIsDirty(false);}}
            initialValue={""}
            init={{
              width: '100%',
              toolbar: 'cloudinary-plugin',
              external_plugins: {
                'cloudinary-plugin': '/cloudinary-plugin/plugin.js'
              },
              plugins: [
                'image'
              ],
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
          <ImagePickerComponent ref={imagePickerRef} imageSelectionComplete={imagePickerComplete}/>
        </>
      </div>
    </div>
  </>
  );
});
  
export default EditDraftComponent;