import CancelIcon from "@mui/icons-material/Cancel";
import { default as ImportIcon, default as OKIcon } from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import CloudIcon from "@mui/icons-material/Cloud";
import EditIcon from "@mui/icons-material/Edit";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import MediaLibraryIcon from "@mui/icons-material/FolderSpecial";
import SearchIcon from "@mui/icons-material/Search";
import SkipIcon from "@mui/icons-material/SkipNext";
import { Button, Card, CardActionArea, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Grow, IconButton, LinearProgress, Menu, MenuItem, styled, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import prettyBytes from 'pretty-bytes';

import { createClient, ErrorResponse } from "pexels";
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
// Import the editor styles
import '@pqina/pintura/pintura.css';

// Import the editor default configuration
import { createDefaultImageReader, createDefaultImageWriter, getEditorDefaults, ImageSource, PinturaDefaultImageReaderResult, PinturaDefaultImageWriterResult, processImage } from '@pqina/pintura';

// Import the editor component from `react-pintura`
import { PinturaEditor } from '@pqina/react-pintura';

import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import Dropzone from "react-dropzone";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFilePicker } from "use-file-picker";
import { CreateMediaMutation, CreateMediaValue, MediaSasQuery, PhotoDto, PhotoPickerType, useCreateMediaMutation, useMediaSasLazyQuery, useMediaUploadCompleteMutation, usePickerPhotosLazyQuery } from "../../generated";
import openverseLogo from "../../images/openverse-logo.svg";
import pexelsLogo from "../../images/pexelsLogo.png";
import pixabayLogo from "../../images/pixabay-logo.svg";
import { UserContext } from "../../ServiceWrapper";
import styles from "../../styles/imagePicker.module.css";
//Require Wrapper Library
//const PexelsAPI = require('pexels-api-wrapper');
 
//Create Client instance by passing in API key
//var pexelsClient = new PexelsAPI();

interface ImagePickerComponentProps {
  imageSelectionComplete: (args: IUploadedImageProps) => void;
}

export interface IImagePickerComponentReference {
  showPicker: () => void
}

export interface ImageProps {
  width: number,
  height: number,
  size: number,
  format: string,
  file?: File,
  thumbFile?: File
}

export interface IUploadedImageProps {
  width: number,
  height: number,
  alt: string,
  url: string
}

const ImagePickerComponent = forwardRef<IImagePickerComponentReference, ImagePickerComponentProps>((props, ref) => {
  const {
    globalCache,
    currentUser,
    setCurrentUser,
    verifyTwoFA,
    verifyEmailCode,
    showProcessing,
    hideProcessing,
    twoFAVerificationResult,
    setTwoFAVerificationResult,
  } = useContext(UserContext);

  const { imageSelectionComplete } = props;

  // get default properties
  const editorConfig = getEditorDefaults();
  const [openFileSelector, { filesContent, plainFiles, loading: loadingFiles, errors: fileErrors }] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
    limitFilesConfig: { max: 1, },
    //editor.insertContent('<emstart>EM Start</emstart><p>Example text!</p><emend>EM End</emend>');
    // minFileSize: 1,
    maxFileSize: 50 // in megabytes
  });

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  
  const [pickerType, setPickerType] = useState<PhotoPickerType>(PhotoPickerType.None);
  const [openPicker, setOpenPicker] = useState<boolean>(false);
  const [openFileUploader, setOpenFileUploader] = useState<boolean>(false);
  const [openPexelPicker, setOpenPexelPicker] = useState<boolean>(false);
  const [openImporterDialog, setOpenImporterDialog] = useState<boolean>(false);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [openStorageQuotaExceeded, setOpenStorageQuotaExceeded] = useState<boolean>(false);
  const [openEditConfirmation, setOpenEditConfirmation] = useState<boolean>(false);
  const [downloadingBlob, setDownloadingBlob] = useState<boolean>(false);
  
  const [imgURL, setImgURL] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [photoQuery, setPhotoQuery] = useState<string>("");
  const [imageTitle, setImageTitle] = useState<string | null>("");
  const [imageTags, setImageTags] = useState<string | null>("");
  const [imageProps, setImageProps] = useState<ImageProps | null>(null);
  const [uploadedImageProps, setUploadedImageProps] = useState<IUploadedImageProps | null>(null);
  const [createMediaResult, setCreateMediaResult] = useState<CreateMediaValue | null>(null);

  const imageEditorRef = useRef(null);
  const imageResultsDiv = useRef<HTMLDivElement>(null)
  const searchInputRef = useRef<HTMLInputElement>(null)

  const [mediaSASURL, setMediaSASURL] = useState<string | null | undefined>("");
  const [mediaSASError, setMediaSASError] = useState<string | null>("");
  const [contentType, setContentType] = useState<string | null>("");
  const [fileBlobl, setFileBlob] = useState<Blob>();
  
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  
  const pageSize = 75;
  const [didSearch, setDidSearch] = useState<boolean>(false);
  const [noResults, setNoResults] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [photos, setPhotos] = useState<PhotoDto[]>([]);
  const [nextPage, setNextPage] = useState<number>(1);
  const [searchDate, setSearchDate] = useState<number>(1);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [hasMorePages, setHasMorePages] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [selectedPhoto, setSelectedPhoto] = useState<PhotoDto | null>(null);
  const [editorImageSource, setEditorImageSource] = useState<ImageSource | undefined>(undefined);

  const [createMediaMutation] = useCreateMediaMutation();
  const [completeMediaUploadMutation] = useMediaUploadCompleteMutation();

  const handleFileUploaderClose = (event: any, reason: any) => {

    if (reason && reason === "backdropClick") 
        return;

    setOpenFileUploader(false);
  };

  const handlePickerClose = (event: any, reason: any) => {

    if (reason && reason === "backdropClick") 
        return;

    setOpenPicker(false);
  };
  

  const showPicker = () => {
    setOpenPicker(true);
  };

  const handleURLChange = (event: any) => {
    setImgURL(event.target.value);
  };

  const getFileFromURL = (url: string) => {
    return new Promise((resolve, reject) => {
        const fileName = url.split('/').pop();
        const img = document.createElement('img');
        img.crossOrigin = 'Anonymous';
        //  img.setAttribute('crossorigin', 'anonymous');
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            if (ctx) {
              ctx.drawImage(img, 0, 0);
              canvas.toBlob(blob => {
                if (blob !== null)
                  resolve (blob);
                  //resolve(new File([blob], "apple_filename"));
              });
            }
        };
        img.onerror = () => {
            reject('something went wrong');
        };
        img.src = url;
    })
  }

  const downloadURL = async() => {
    setDownloadingBlob(true);
    let b = await getFileFromURL(imgURL);
    console.log(b);

    /*Image foo = new Image();

    downloadedImg = new Image();
    downloadedImg.crossOrigin = "Anonymous";
    downloadedImg.addEventListener("load", imageReceived, false);
    downloadedImg.alt = 'test';
    downloadedImg.src = imgURL;
    downloadedImg.

    fetch(imgURL,{
      method: 'GET', 
      mode: 'cors',
      })
    .then(async response => {
      
      console.log(response);
      if (!response.ok) {
        toast.error("Could not retrieve the image.");
        return;
      }

      const cType = response.headers.get('content-type');
      console.log(cType);

      if (cType == null || cType === "") {
        toast.error("Could not extract the content type for the image.");
        return;
      }

      var foundItem = ImageTypes.find(o => o === cType);
      if (foundItem == null)
      {
        toast.error("Unsupported content type.");
        return;
      }

      setContentType(cType);

      const blob = await response.blob();
      console.log(blob);
      setFileBlob(blob);
      toast.success('Successfully retrieved file');
      
      //const file = new File([blob], fileName, { contentType })
      // access file here
    }).catch(error => {
      // Handle the error.
      toast.error("A network error occurred - Could not retrieve the image.");
    }).finally(() => {
      setDownloadingBlob(false);
    })*/
  };

  const showPexelPicker = () => {
    closeMenu();
    setPickerType(PhotoPickerType.Pexels);
    setOpenPicker(false);
    setOpenPexelPicker(true);
    
    setTimeout(() => {
      if (searchInputRef.current)
        searchInputRef.current.focus();
    }, 1);
  };

  const showPixabayPicker = () => {
    closeMenu();
    setPickerType(PhotoPickerType.Pixabay);
    setOpenPicker(false);
    setOpenPexelPicker(true);
    
    setTimeout(() => {
      if (searchInputRef.current)
        searchInputRef.current.focus();
    }, 1);
  };

  const showOpenversePicker = () => {
    closeMenu();
    setPickerType(PhotoPickerType.Openverse);
    setOpenPicker(false);
    setOpenPexelPicker(true);
    
    setTimeout(() => {
      if (searchInputRef.current)
        searchInputRef.current.focus();
    }, 1);
  };

  const showUploadImageEditor = () => {
    closeMenu();
    setPickerType(PhotoPickerType.None);
    setOpenPicker(false);
    setStep(2);
    setOpenPexelPicker(true);
  };

  const showMediaLibraryPicker = () => {
    closeMenu();
    setPickerType(PhotoPickerType.MediaLibrary);
    setOpenPicker(false);
    setOpenPexelPicker(true);
    
    setTimeout(() => {
      if (searchInputRef.current)
        searchInputRef.current.focus();
    }, 1);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  }
  
  const closeMenu = () => {
    setAnchorEl(null);
  };

  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  // We pass generic type, this case string
  const debouncedSearchTerm: string | null = useDebounce<string | null>(searchText, 500);
  const pexelClient = createClient("tmYDgtlMDLTm4W8PgxcDpmo6pG7rLCUuTndMMuRaCkz1QOR3ruBpVicL");

  const instanceOfErrorResponse = (object: any): object is ErrorResponse => {
    return 'error' in object;
  }

  const [getPickerPhotos] = usePickerPhotosLazyQuery({
    fetchPolicy: "cache-and-network"
  });

  const searchPhotos = async() => {
    setDidSearch(true);
    setHasMorePages(false);
    setNextPage(1);
    setIsSearching(true);

    const pickerResult = await getPickerPhotos({variables: {
      pageNumber: 1,
      initialSearch: true,
      pageSize: pageSize,
      pickerType: pickerType,
      query: photoQuery
    }});

    if (pickerResult && pickerResult.data && pickerResult.data.pickerPhotos) {
      const photoResult = pickerResult.data.pickerPhotos;
      
      if (photoResult.error) {
        toast.error(photoResult.error);
        return;
      }

      if (photoResult.nextPage) {
        setNextPage(photoResult.nextPage);
        setHasMorePages(true);
      } 
      else
        setHasMorePages(false);
        
      setPhotos(photoResult.photos);
      if (photoResult.totalRecords)
        setTotalResults(photoResult.totalRecords);

      setNoResults(photoResult.photos.length === 0);

      if (imageResultsDiv.current) {
        imageResultsDiv.current.scroll({
          top: 0,
          behavior: 'smooth'
        });
      }

      setIsSearching(false);
      setSearchDate(new Date().valueOf());
    }
    
    /*
    pexelClient.photos.search({page: 1, per_page: pageSize, query: searchText}).then((value: PhotosWithTotalResults | ErrorResponse) => {

      
      if (instanceOfErrorResponse(value)) {
          console.log('received an error: ', value.error);
      }
      else {
        
        console.log(value);
        if (value.next_page)
        {
          console.log ("nextPage: ", nextPage);
          setNextPage(nextPage + 1);
          setHasMorePages(true);
          console.log('has more');
        }
        else
          setHasMorePages(false);

        setTotalResults(value.total_results);
        console.log('total results: ', value.total_results);
        
        
        setPhotos(value.photos);
        setNoResults(value.photos.length === 0);

        if (imageResultsDiv.current) {
          imageResultsDiv.current.scroll({
            top: 0,
            behavior: 'smooth'
          });
        }
      }
    }).finally(() => {
      setIsSearching(false);
      setSearchDate(new Date().valueOf());
    });
    */
  };

  // Effect for API call
  /*useEffect(
    () => {
      if (debouncedSearchTerm) {
        setIsSearching(true);
        pexelClient.photos.search({page: 1, per_page: 10, query: debouncedSearchTerm}).then((value: PhotosWithTotalResults | ErrorResponse) => {

          
          if (instanceOfErrorResponse(value)) {
              console.log('received an error: ', value.error);
          }
          else {
            
            console.log(value);
            if (value.next_page)
            {
              setNextPage(nextPage + 1);
              setHasMorePages(true);
              console.log('has more');
            }
            else
              setHasMorePages(false);

            setTotalResults(value.total_results);
            console.log('total results: ', value.total_results);
            
            
            setPhotos(value.photos);

            if (imageResultsDiv.current) {
              imageResultsDiv.current.scroll({
                top: 0,
                behavior: 'smooth'
              });
            }
          }
        }).finally(() => {
          setIsSearching(false);
          setSearchDate(new Date().valueOf());
        });
      } else {
        //setResults([]);
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );*/

  const fetchNextPage = async() => {
    console.log('fetching next page');
    
    const pickerResult = await getPickerPhotos({variables: {
      initialSearch: false,
      totalRecords: totalResults,
      pageNumber: nextPage,
      pageSize: pageSize,
      pickerType: pickerType,
      query: photoQuery
    }});

    if (pickerResult && pickerResult.data && pickerResult.data.pickerPhotos) {
      const photoResult = pickerResult.data.pickerPhotos;
      
      if (photoResult.error) {
        toast.error(photoResult.error);
        return;
      }

      if (photoResult.nextPage) {
        setNextPage(photoResult.nextPage);
        setHasMorePages(true);
      } 
      else
        setHasMorePages(false);

      setPhotos(photos.concat(photoResult.photos))
    }
  };

  const [getMediaSASURL] = useMediaSasLazyQuery({
    fetchPolicy: "cache-and-network",
    async onCompleted(data: MediaSasQuery) {
      if (data?.mediaSAS) {
        console.log(data.mediaSAS);
        
        if (data.mediaSAS.error) {
          setMediaSASError(data.mediaSAS.error);
          setOpenStorageQuotaExceeded(true);
          return;
        }

        
        setMediaSASURL(data.mediaSAS.value);
        setOpenImporterDialog(true);
        
      } else {
        setMediaSASURL("");
      }
    }
  });

  
  
  //
  const handleClosePexelPicker = (event: any, reason: any) => {

    if (reason && reason === "backdropClick") 
        return;

    setOpenPexelPicker(false);
  };
  const handleCloseImporterDialog = (event: any, reason: any) => {

    if (reason && reason === "backdropClick") 
        return;

    setOpenImporterDialog(false)
  };
  
  const handleEditConfirmationClose = (event: any, reason: any) => {

    if (reason && reason === "backdropClick") 
        return;

    setOpenEditConfirmation(false);
  };

  const handleQuotaExceededDialogClose = (event: any, reason: any) => {

    if (reason && reason === "backdropClick") 
        return;

    setOpenStorageQuotaExceeded(false);
  };

  useImperativeHandle(ref, () => ({
    showPicker
  }));
  
  const handleSearchChange = (event: any) => {
    setSearchText(event.target.value);
  };

  const handleSearch = (event: any) => {
    if (event.key === 'Enter') {
      setPhotoQuery(searchText);
    }
  };

  useEffect(() => {
    if (pickerType === PhotoPickerType.MediaLibrary || photoQuery != "") {
      searchPhotos();
    }
  }, [photoQuery]);

  useEffect(() => {
    if (pickerType === PhotoPickerType.MediaLibrary) {
      searchPhotos();
    }
  }, [pickerType]);

  const handleImageTitleChange = (event: any) => {
    setImageTitle(event.target.value);
  };

  const handleImageTagsChange = (event: any) => {
    setImageTags(event.target.value);
  };

  const StyledMenu = styled(Menu)`
    paper: {
      border: '1px solid #d3d4d5',
    }
  `;

  const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    "&.Mui-selected, &.Mui-selected:focus" : {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    },
    "&:hover" : {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
      }
  }));
  

  // Hook
  // T is a generic type for value parameter, our case this will be string
  function useDebounce<T>(value: T, delay: number): T {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }

  const photoLoaded = async(result: PinturaDefaultImageReaderResult) => {
    
     // get reference to editor instance
     if (imageEditorRef.current) {
      
      let editorRef = imageEditorRef.current as PinturaEditor;
      
      setImageProps({
        width: editorRef.editor.imageSize.width,
        height: editorRef.editor.imageSize.height,
        size: editorRef.editor.imageFile.size,
        format: editorRef.editor.imageFile.type
      });
      setOpenEditConfirmation(true);
     }
  }

  const skipEditing = () => {

    if (pickerType === PhotoPickerType.MediaLibrary) {
      skipEditingMediaLibrarySelection();
    }
    else if (pickerType === PhotoPickerType.Pexels || pickerType === PhotoPickerType.Pixabay) {
      skipEditingPexelSelection();
    }
    else if (pickerType === PhotoPickerType.None) {
      skipEditingUpload();
    }
  }

  const skipEditingPexelSelection = async() => {

    if (!selectedPhoto)
      return;

      generateThumbnail();
  };

  const skipEditingUpload = async() => {

      generateThumbnail();
  };

  const skipEditingMediaLibrarySelection = async() => {

    if (!selectedPhoto)
      return;

    let uploadImg = {
      alt: (selectedPhoto.title ? selectedPhoto.title : ""),
      width: selectedPhoto.width,
      height: selectedPhoto.height,
      url: selectedPhoto.imageUrl,

    };

    setUploadedImageProps(uploadImg);

    resetPhotoPicker();
    imageSelectionComplete(uploadImg);
  };

  const generateThumbnail = async() => {

    // get reference to editor instance
    if (!imageEditorRef.current)
      return;
      
    let editorRef = imageEditorRef.current as PinturaEditor;
     
    showProcessing("Generating Thumbnail");
    
    // Show resulting image preview
    const preview = new Image();
    preview.onerror = (err) => {
      hideProcessing();
      toast.error("An error occurred while generating your image.");
    };

    preview.onload = () => {
      console.log('loaded preview');
      
      // use the output image as the source
      processImage(editorRef.editor.imageFile, {
        
        // set the default reader
        imageReader: createDefaultImageReader(),

        // set the default writer and supply the intended size of the thumbnail
        imageWriter: createDefaultImageWriter({
            // Limit size of output to this size
            targetSize: {
              width: 350,
              height: 350,
              fit: 'contain',
              upscale: false,
          },
        }),
      }).then(async({ dest }) => {
          
        let fileName = editorRef.editor.imageFile.name;
        let idx = fileName.lastIndexOf(".");

        if (idx > 0) {
          setImageTitle(fileName.substring(0, idx));
        }
        else
          setImageTitle(fileName);

        if (imageProps) {
          setImageProps({...imageProps, ...{
            width: preview.naturalWidth,
            height: preview.naturalHeight,
            size: editorRef.editor.imageFile.size,
            format: editorRef.editor.imageFile.type,
            file: editorRef.editor.imageFile,
            thumbFile: dest
          }});
        }

        await getMediaSASURL({
          variables: {
            importSizeBytes: (editorRef.editor.imageFile.size + dest.size)
          }
        })
      
      })
      .finally(() => {
        hideProcessing();
      });
    };
    
    preview.src = URL.createObjectURL(editorRef.editor.imageFile);
  }

  const photoProcessed = async(detail: PinturaDefaultImageWriterResult) => {
    
    
    console.log(detail);
    
    showProcessing("Generating Thumbnail");
    
    // Show resulting image preview
    const preview = new Image();
    preview.onerror = (err) => {
      hideProcessing();
      toast.error("An error occurred while generating your image.");
    };

    preview.onload = () => {
      console.log('loaded preview');
      
      // use the output image as the source
      processImage(detail.dest, {
        
        // set the default reader
        imageReader: createDefaultImageReader(),

        // set the default writer and supply the intended size of the thumbnail
        imageWriter: createDefaultImageWriter({
            // Limit size of output to this size
            targetSize: {
              width: 350,
              height: 350,
              fit: 'contain',
              upscale: false,
          },
        }),
        }).then(async({ dest }) => {
            
            let fileName = detail.dest.name;
            let idx = fileName.lastIndexOf(".");
            if (pickerType === PhotoPickerType.MediaLibrary) {
              if (selectedPhoto?.title)
                setImageTitle(selectedPhoto?.title);
              else
                setImageTitle("");

              if (selectedPhoto?.tags)
                setImageTags(selectedPhoto?.tags);
              else
                setImageTags("");

            } else {
              if (idx > 0) {
                setImageTitle(fileName.substring(0, idx));
              }
              else
                setImageTitle(fileName);
            }

            if (imageEditorRef.current) {
        
              let editorRef = imageEditorRef.current as PinturaEditor;
              if (imageProps) {
                setImageProps({...imageProps, ...{
                  width: preview.naturalWidth,
                  height: preview.naturalHeight,
                  size: detail.dest.size,
                  format: detail.dest.type,
                  file: detail.dest,
                  thumbFile: dest
                }});
              }
            }

            await getMediaSASURL({
              variables: {
                importSizeBytes: (detail.dest.size + dest.size)
              }
            })
            
        })
        .finally(() => {
          hideProcessing();
        });
        

    };
    
    preview.src = URL.createObjectURL(detail.dest);

  };

  const importImage = () => {
    if (!imageTitle || imageTitle?.length === 0) {
      toast.error("Image Title is required");
      return;
    }
    
    console.log('creating media record');
    setIsImporting(true);

    createMediaMutation({
      variables: {
        fileSize: imageProps?.size,
        fileType: (imageProps?.format ? imageProps?.format : ""),
        height: imageProps?.height,
        width: imageProps?.width,
        title: imageTitle,
        tags: imageTags
      },
      async onCompleted(data: CreateMediaMutation) {
        
        if (data?.createMedia?.error) {
          console.log('creating media record - error occurred');
          setIsImporting(false);
          toast.error(data?.createMedia?.error);
          return;
        }

        if (!data?.createMedia?.value) {
          setIsImporting(false);
          toast.error('An unknown error has occurred');
          return;
        }
  
        console.log('creating media record - success');
        setCreateMediaResult(data?.createMedia?.value);

        if (!imageProps || !imageProps.file || !imageProps.thumbFile) {
          console.log('creating media record - props missing');
          setIsImporting(false);
          return;
        }

        try
        {
          console.log('creating media record - props exist');
          
          const uploadUrl = `https://privatemiadara.blob.core.windows.net/${mediaSASURL}`;
          
          //const uploadUrl = `${data.mediaSAS}`;
          // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
          const blobService = new BlobServiceClient(uploadUrl);

          // get Container - full public read access
          const containerClient: ContainerClient = blobService.getContainerClient('users');
          
          // create blobClient for file
          const blobClientFile = containerClient.getBlockBlobClient(data?.createMedia.value.filePath);
        
          // set mimetype as determined from browser with file upload control
          const options = { blobHTTPHeaders: { blobContentType: imageProps?.format } };
        
          console.log('creating media record - getting buffers');
          let fileBuffer = await imageProps.file.arrayBuffer();
          let thumbFileBuffer = await imageProps.thumbFile.arrayBuffer();

          console.log('creating media record - retrieved buffers');
          
          // upload file
          await blobClientFile.uploadData(fileBuffer, options);

          console.log('creating media record - uploaded file to azure');

          // create blobClient for thumbnail
          const blobClientThumbFile = containerClient.getBlockBlobClient(data?.createMedia.value.thumbFilePath);

          await blobClientThumbFile.uploadData(thumbFileBuffer, options);

          console.log('creating media record - uploaded thumbnail to azure');
        }
        catch(error) {
          toast.error('An error occurred while uploading the image, please try again.');
          console.log('caught an error: ', error);
          setOpenImporterDialog(false);
          setIsImporting(false);
          return;
        }
        
        let confirmation = await completeMediaUploadMutation({
          variables: {
            mediaLibraryIDHash: data.createMedia.value.mediaLibraryIDHash
          }
        }).then( o => {
          
          if (o.data?.mediaUploadComplete?.error) {
            console.log('complete media record - error');
            toast.error(o.data?.mediaUploadComplete.error);
            return false;
          }

          if (!o.data?.mediaUploadComplete.success) {
            console.log('complete media record - returned false');
            toast.error("An unknown error occurred while confirming the upload");
            return false;
          }

          console.log('complete media record - succeeded');

          return true;
        });
    
        setIsImporting(false);

        if (!confirmation)
          return;

        
        let uploadImg = {
          alt: imageTitle,
          width: imageProps.width,
          height: imageProps.height,
          url: "https://images.miadara.com/users/" + data?.createMedia.value.filePath
        };

        setUploadedImageProps(uploadImg);

        resetPhotoPicker();
        imageSelectionComplete(uploadImg);
      }
    });
  };

  const resetFileUploader = () => {
    setOpenFileUploader(false);
    setOpenPicker(false);
  }

  const resetPhotoPicker = () => {
    setSearchText("");
    setPickerType(PhotoPickerType.None);
    setPhotoQuery("");
    setImageTags("");
    setImageTitle("");
    setPhotos([]);
    setStep(1);
    setHasMorePages(false);
    setIsImporting(false);
    setNextPage(1);
    setIsSearching(false);
    setNoResults(false);
    setDidSearch(false);
    setSelectedPhoto(null);
    setEditorImageSource(undefined);
    setOpenFileUploader(false);
    setOpenPexelPicker(false);
    setOpenImporterDialog(false);
    setOpenPicker(false);
  };

  const selectPhoto = (item: PhotoDto) => {
    setSelectedPhoto(item);
    setEditorImageSource(item.imageUrl);
    setStep(2);
  };

  const doSubmit = (event: any) => {
    return false;
  };

  useEffect(() => { 
    if (plainFiles.length > 0) {
      toast.success('selected a file: ' + plainFiles[0].name);
      getMediaSASURL();
    }
  },[plainFiles]);


  return (
    <>
      <Dialog
        open={openPicker}
        TransitionComponent={Grow as React.ComponentType}
        fullWidth={true}
        maxWidth={'md'}
        onClose={handlePickerClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <>
            Image
            <IconButton
              aria-label="close"
              onClick={() => {setOpenPicker(false)}}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </>
        </DialogTitle>
        <DialogContent>
          <>
            <Typography>Upload an image file, pick one from your media library, or use an online service.</Typography>
            <div style={{display:'flex', gap:20, flexWrap:'wrap', marginTop:20}}>
              <Button color="primary" variant="contained" onClick={() => {setOpenPicker(false);setOpenFileUploader(true)}}><FileUploadIcon style={{marginRight:5, color:'white'}}/>Upload</Button>
              <Button color="primary" variant="contained" style={{}} onClick={handleMenu}><CloudIcon style={{marginRight:10, color:'white'}}/>Select Image</Button>
              <StyledMenu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <StyledMenuItem  onClick={showMediaLibraryPicker} ><MediaLibraryIcon style={{marginRight:10}}/> Your Media Library</StyledMenuItem>
                  <StyledMenuItem  onClick={showPexelPicker}><MediaLibraryIcon style={{marginRight:10}}/> Pexels</StyledMenuItem>
                  <StyledMenuItem  onClick={showPixabayPicker} ><MediaLibraryIcon style={{marginRight:10}}/> Pixabay</StyledMenuItem>
                  <StyledMenuItem  onClick={showOpenversePicker} ><MediaLibraryIcon style={{marginRight:10}}/> Openverse</StyledMenuItem>
                </StyledMenu>
            </div>
          </>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openFileUploader}
        TransitionComponent={Grow as React.ComponentType}
        fullWidth={true}
        maxWidth={'md'}
        onClose={handleFileUploaderClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <>
            Upload Image
            <IconButton
              aria-label="close"
              onClick={() => {resetFileUploader()}}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </>
        </DialogTitle>
        <DialogContent>
          <>
            <Dropzone accept={{'image/*': []}} maxFiles={1} onDrop={acceptedFiles => 
              {
                console.log(acceptedFiles)
                if (acceptedFiles.length === 1) {
                  setEditorImageSource(acceptedFiles[0]);
                  showUploadImageEditor();
                }
              }}>
              {({getRootProps, getInputProps}) => (
                <section>
                  <div {...getRootProps()} style={{backgroundColor:'whitesmoke', height:200, display:"flex", justifyContent:"center", alignItems:"center", textAlign:"center"}}>
                    <input {...getInputProps()} />
                    <Typography style={{margin:10}}>Drag 'n' drop a file here, or click <b>here</b> to select a file</Typography>
                  </div>
                </section>
              )}
            </Dropzone>
            <Typography>The following image types are supported: png, jpg, gif, svg.</Typography>
          </>
        </DialogContent>
      </Dialog>
      <Dialog
        fullScreen={(step !== 3 ? true : false)}
        open={openPexelPicker}
        TransitionComponent={Grow as React.ComponentType}
        fullWidth={(step !== 3 ? true : false)}
        style={{height:"100%", minHeight:"100%", display:"flex", flexDirection:"column"}}
        PaperProps={{
          style: {
            backgroundColor: "#f9f9f9"
          }
        }}
        maxWidth={'md'}
        onClose={handleClosePexelPicker}
        aria-describedby="alert-dialog-slide-description"
      >
        {step === 1 &&
        <>
          <DialogTitle className={styles.dialogTitle}>
            <div style={{maxWidth:1280,margin:"auto"}}>
              <div className={styles.dialogHeader}>
                {pickerType === PhotoPickerType.Pexels &&
                <a href="https://pexels.com" rel="noreferrer" target="_blank" style={{lineHeight:0}}><img src={pexelsLogo} style={{height:50}}/></a>
                }
                {pickerType === PhotoPickerType.Pixabay &&
                <a href="https://pixabay.com" rel="noreferrer" target="_blank" style={{lineHeight:0}}><img src={pixabayLogo} style={{height:50}}/></a>
                }
                {pickerType === PhotoPickerType.Openverse &&
                <a href="https://openverse.org" rel="noreferrer" target="_blank" style={{lineHeight:0}}><img src={openverseLogo} style={{width: 200}}/></a>
                }
                {pickerType === PhotoPickerType.MediaLibrary &&
                <><MediaLibraryIcon/><span>Your Media Library</span></>
                }
                <IconButton
                  aria-label="close"
                  onClick={resetPhotoPicker}
                  sx={{
                    
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </DialogTitle>
          <div style={{maxWidth:1280,margin:"auto", width:"100%"}}>
            <div style={{marginLeft:10, marginRight:10,display:"flex", alignItems:"center"}}>
              <SearchIcon style={{marginRight:10}}/>
              <TextField id="txtSearch" inputRef={searchInputRef} type="text" onChange={handleSearchChange} onKeyDown={handleSearch} value={searchText} style={{marginTop:10,marginBottom:10, width:'100%'}} 
              placeholder="Search for an image" variant="standard" 
              inputProps={{
                enterKeyHint: "search"
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ visibility: searchText ? "visible" : "hidden" }}
                    onClick={() => {setSearchText("")}}
                  >
                    <CancelIcon/>
                  </IconButton>
                ),
              }}
              />
            </div>
          </div>
          <div id="divScroller" ref={imageResultsDiv} style={{ flex: 1, overflowY: "auto", maxWidth:1280, width:"100%", margin:"10px", alignSelf:"center"}}>
            <>
              { isSearching &&
                <div style={{ width: "100%", height: "inherit", maxWidth: "inherit", display: "flex", justifyContent: "center", alignItems: "center", position: "fixed", zIndex:1, backgroundColor:"white", opacity:.7 }}>
                  <CircularProgress />
                </div>
              }
                <InfiniteScroll
                  key={`newSearch${searchDate}`}
                  dataLength={photos.length} //This is important field to render the next data
                  next={fetchNextPage}
                  hasMore={hasMorePages}
                  scrollThreshold={1}
                  loader={<LinearProgress />}
                  // Let's get rid of second scroll bar
                  scrollableTarget="divScroller"
                  endMessage={
                    didSearch && !isSearching &&
                    <p style={{ display:"flex", alignItems:"center", justifyContent:"center", height:100 }}>
                      <b>No More Results</b>
                    </p>
                  }
                >
                    <Grid container spacing={4}>
                    {photos.map((item, idx) => {
                      return (
                      <Grid key={idx} item xs={12} sm={6} md={4} lg={3}>
                        <Card elevation={5} style={{position:"relative"}}>
                          <CardActionArea style={{display:"flex", justifyContent: "center"}} onClick={() => { selectPhoto(item);}}>
                            <img
                              src={`${item.thumbImageUrl}`}
                              alt={item.title ? item.title : ""}
                              style={{height:312, width:312, objectFit:"contain"}}
                              loading="lazy"
                            />
                          </CardActionArea>
                          {item.author && item.authorUrl &&
                          <div style={{position:"absolute", bottom:0, textAlign:"center", backgroundColor:"white", opacity:".6", padding:10, width:"100%"}}>
                            <a href={item.authorUrl} rel="noreferrer" target="_blank">{item.author}</a>
                          </div>}
                          {item.title && !item.author && !item.authorUrl &&
                          <div style={{position:"absolute", bottom:0, textAlign:"center", backgroundColor:"white", opacity:".6", padding:10, width:"100%"}}>
                            <span>{item.title}</span>
                          </div>}
                        </Card>
                      </Grid>)
                    })}
                    </Grid>
                </InfiniteScroll>
                </>
          </div>
          <div style={{height:10}}></div>
        </>}
        {step === 2 &&
        <>
          <DialogTitle className={styles.dialogTitle}>
            <div style={{maxWidth:1280,margin:"auto"}}>
              <div className={styles.dialogHeader}>
                <IconButton
                  aria-label="close"
                  onClick={() => {setStep(1)}}
                  className={pickerType === PhotoPickerType.None ? styles.hide : null}
                  sx={{
                    
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <NavigateBefore />
                </IconButton>
                <span>Edit Image</span>
                <IconButton
                  aria-label="close"
                  onClick={resetPhotoPicker}
                  sx={{
                    
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </DialogTitle>
          <PinturaEditor
            className={styles.imageEditor}
              {...editorConfig}
              src={editorImageSource}
              ref={imageEditorRef}
              onProcess={photoProcessed}
              onLoad={photoLoaded}
          ></PinturaEditor>
        </>}
      </Dialog>
      <Dialog
        open={openImporterDialog}
        TransitionComponent={Grow as React.ComponentType}
        style={{height:"100%", minHeight:"100%", display:"flex", flexDirection:"column"}}
        PaperProps={{
          style: {
            backgroundColor: "#f9f9f9"
          }
        }}
        maxWidth={'sm'}
        onClose={handleCloseImporterDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className={styles.dialogTitle}>
          <div style={{maxWidth:1280,margin:"auto"}}>
            <div className={styles.dialogHeader}>
              <span>Import Image</span>
              <IconButton
                aria-label="close"
                disabled={isImporting}
                onClick={() => {setOpenImporterDialog(false)}}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
        <div style={{maxWidth:1280, width: "100%", margin:"auto", display:"flex", flexDirection:"column", alignItems:"center"}}>
          <TextField id="txtTitle" label="Image Title" type="text" autoFocus onChange={handleImageTitleChange} value={imageTitle} style={{marginTop:10,marginBottom:10, width:300}} 
            placeholder="Image Title" variant="standard"
            inputProps={{ maxLength: 50 }}
            InputProps={{
              endAdornment: (
                <IconButton
                  sx={{ visibility: searchText ? "visible" : "hidden" }}
                  onClick={() => {setImageTitle("")}}
                >
                  <CancelIcon/>
                </IconButton>
              ),
            }}
          />
          <TextField id="txtTags" label="Image Tags" type="text" autoFocus onChange={handleImageTagsChange} value={imageTags} style={{marginTop:10,marginBottom:10, width:300}} 
            placeholder="Optional Image Tags" variant="standard"
            inputProps={{ maxLength: 50 }}
            InputProps={{
              endAdornment: (
                <IconButton
                  sx={{ visibility: searchText ? "visible" : "hidden" }}
                  onClick={() => {setImageTags("")}}
                >
                  <CancelIcon/>
                </IconButton>
              ),
            }}
          />
          <div style={{marginTop:10, alignSelf:"flex-start"}}>Image Dimensions: {imageProps?.width} x {imageProps?.height} (W x H)</div>
          <div style={{marginTop:10, alignSelf:"flex-start"}}>Image Size: {prettyBytes(imageProps?.size ? imageProps.size : 0)}</div>
          <div style={{marginTop:10, alignSelf:"flex-start"}}>Image Type: {imageProps?.format}<br/><br/></div>
        </div>
        </DialogContent>
        <DialogActions style={{display:"flex", justifyContent:"flex-end"}}>
          <Button color="primary" variant="contained" onClick={importImage} disabled={isImporting}>{isImporting && <CircularProgress size={20} />}{!isImporting && <><ImportIcon style={{marginRight:5}}/>Import</>}</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEditConfirmation}
        TransitionComponent={Grow as React.ComponentType}
        style={{height:"100%", minHeight:"100%", display:"flex", flexDirection:"column"}}
        PaperProps={{
          style: {
            backgroundColor: "#f9f9f9"
          }
        }}
        maxWidth={'sm'}
        onClose={handleEditConfirmationClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className={styles.dialogTitle}>
          <div style={{maxWidth:1280,margin:"auto"}}>
            <div className={styles.dialogHeader}>
              <span>Edit Image?</span>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          Image Dimensions: {imageProps?.width} x {imageProps?.height} (W x H)<br/>
          Image Size: {prettyBytes(imageProps?.size ? imageProps.size : 0)}<br/>
          Image Type: {imageProps?.format}<br/><br/>
          You can: Resize, Crop, Filter, Finetune, Annotate, Decorate, Frame, Redact, Sticker
        </DialogContent>
        <DialogActions style={{display:"flex", justifyContent:"flex-end"}}>
          <Button color="secondary" variant="outlined" onClick={() => {setOpenEditConfirmation(false);skipEditing();}}><><SkipIcon style={{marginRight:5}}/>Skip</></Button>
          <Button color="primary" variant="contained" onClick={() => {setOpenEditConfirmation(false);}}><><EditIcon style={{marginRight:5}}/>Edit</></Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openStorageQuotaExceeded}
        TransitionComponent={Grow as React.ComponentType}
        style={{height:"100%", minHeight:"100%", display:"flex", flexDirection:"column"}}
        PaperProps={{
          style: {
            backgroundColor: "#f9f9f9"
          }
        }}
        maxWidth={'sm'}
        onClose={handleQuotaExceededDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className={styles.dialogTitle}>
          <div style={{maxWidth:1280,margin:"auto"}}>
            <div className={styles.dialogHeader}>
              <span>Storage Quota Exceeded</span>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          {mediaSASError}
        </DialogContent>
        <DialogActions style={{display:"flex", justifyContent:"flex-end"}}>
          <Button color="secondary" variant="outlined" onClick={() => {setOpenStorageQuotaExceeded(false)}}><><CancelIcon style={{marginRight:5}}/>No</></Button>
          <Button color="primary" variant="contained" onClick={() => {window.open("/accountSettings/subscription", "_blank");setOpenStorageQuotaExceeded(false);}}><><OKIcon style={{marginRight:5}}/>Upgrade Account</></Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default ImagePickerComponent;