import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  Link,
  matchPath,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProfileComponent from "../components/accountSettings/profile";
import SecurityComponent from "../components/accountSettings/security";
import SecurityIcon from "@mui/icons-material/Key";
import ProfileIcon from "@mui/icons-material/Person";
import SubscriptionIcon from "@mui/icons-material/History";
import { Icon, Paper, SvgIcon } from "@mui/material";

function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

function MyTabs() {
  // You need to provide the routes in descendant order.
  // This means that if you have nested routes like:
  // users, users/new, users/edit.
  // Then the order should be ['users/add', 'users/edit', 'users'].
  const routeMatch = useRouteMatch([
    "/accountSettings/profile",
    "/accountSettings/security",
    "/accountSettings/subscription",
  ]);
  
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:1200px)");

  let routePath = routeMatch?.pattern?.path;
  const [currentTab, setCurrentTab] = useState<string | undefined>(routePath);

  useEffect(() => {
    setCurrentTab(routePath);
  }, [routePath]);

  useEffect(() => {
    if (currentTab == null) navigate("/accountSettings/profile");
  }, [currentTab]);

  if (routePath == null) routePath = "/accountSettings/profile";

  return (
    <div style={{marginTop:20, borderRight: '1px solid lightgray'}}>
      
      <Tabs
        value={routePath}
        orientation="vertical"
        indicatorColor="primary"
        className="accountSettingsTabs"
      >
        <Tab
          label={isMobile ? "" : "Profile"}
          icon={<ProfileIcon />}
          value="/accountSettings/profile"
          to="/accountSettings/profile"
          component={Link}
        />
        <Tab
          label={isMobile ? "" : "Security"}
          icon={<SecurityIcon />}
          value="/accountSettings/security"
          to="/accountSettings/security"
          component={Link}
        />
        <Tab
          label={isMobile ? "" : "Subscription"}
          icon={<SubscriptionIcon/>}
          value="/accountSettings/subscription"
          to="/accountSettings/subscription"
          component={Link}
        />
      </Tabs>
    </div>
  );
}

function CurrentRoute() {
  const location = useLocation();

  return (
    <Typography variant="body2" sx={{ pb: 2 }} color="text.secondary">
      Current route: {location.pathname}
    </Typography>
  );
}

export const AccountSettings = () => {
  return (
    <Box sx={{ width: "100%", display: "flex" }}>
      <MyTabs />
      <div style={{margin:10, flexGrow: 1}}>
        <Outlet />
      </div>
    </Box>
  );
};
