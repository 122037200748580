import * as React from 'react';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../ServiceWrapper';

export const UserRegisteredRoute: React.FC = ({children}) => {
  const { currentUser } = useContext(UserContext);
  const shouldRedirect = (currentUser !== null && !currentUser?.registrationComplete);

  return shouldRedirect ? <Navigate to="/register" /> : <>{children}</>;
}