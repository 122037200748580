import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grow, TextField } from "@mui/material";

import { forwardRef, useImperativeHandle, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/CheckCircle";

export interface IDialogReference {
  showDialog: (title: string, subTitle: string | null) => void
  hideDialog: () => void
}

export interface CreateDraftDialogComponentProps {
  action: string | undefined;
  savingDraft: boolean;
  saveDraftTitle: (title: string, subTitle: string | null) => void;
}

const CreateDraftDialogComponent = forwardRef<IDialogReference, CreateDraftDialogComponentProps>((props, ref) => {

  const {action, saveDraftTitle, savingDraft } = props;
  const [combinedLength, setCombinedLength] = useState<number>(0);
  const [newTitle, setNewTitle] = useState<string>("");
  const [newSubTitle, setNewSubTitle] = useState<string>("");
  const [openTitleEditor, setOpenTitleEditor] = useState<boolean>(false);
  
  const showDialog = (incomingTitle: string, incomingSubtitle: string | null) => {
    setCombinedLength((incomingSubtitle ? incomingSubtitle.length : 0) + (incomingTitle ? incomingTitle.length : 0));

    setNewTitle(incomingTitle);
    if (incomingSubtitle)
      setNewSubTitle(incomingSubtitle);
    else
      setNewSubTitle("");

    setOpenTitleEditor(true);
  };

  const hideDialog = () => {
    setOpenTitleEditor(false);
  };

  useImperativeHandle(ref, () => ({
    showDialog,
    hideDialog
  }));

  const handleNewTitleChange = (event: any) => {
    setNewTitle(event.target.value);
    setCombinedLength((newSubTitle ? newSubTitle.length : 0) + (event.target.value ? event.target.value.length : 0));
  };

  const handleNewSubTitleChange = (event: any) => {
    setNewSubTitle(event.target.value);
    setCombinedLength((newTitle ? newTitle.length : 0) + (event.target.value ? event.target.value.length : 0));
  };

  const handleTitleEditorClose = (event: any, reason: any) => {

    if (reason && reason === "backdropClick") 
        return;

    setOpenTitleEditor(false);
  };
  
  return (
    <Dialog
      open={openTitleEditor}
      TransitionComponent={Grow as React.ComponentType}
      fullWidth={true}
      maxWidth={'md'}
      onClose={handleTitleEditorClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <>
          {action === "edit" ? "Edit Title / Subtitle" : "Create Draft"}
        </>
      </DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
          <div style={{ width:"100%" }}>
            <TextField
              id="txtNewTitle"
              type="text"
              autoFocus={true}
              value={newTitle}
              onChange={handleNewTitleChange}
              style={{ width: "100%" }}
              label="Title"
              required={true}
              variant="standard"
            />
            <TextField
              id="txtNewSubTitle"
              type="text"
              onChange={handleNewSubTitleChange}
              value={newSubTitle}
              style={{ marginTop: 10, width: "100%" }}
              label="Optional Subtitle"
              required={true}
              variant="standard"
            />
            <label style={{marginTop:10, display:"block", color: (combinedLength > 200 ? "red" : "")}}>Combined length: {combinedLength} / 200</label>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" style={{ fontWeight: "bold", marginLeft: 20 }} title="Cancel" onClick={() => {setOpenTitleEditor(false)}}>
          <CancelIcon /><span style={{marginLeft:5}}>Cancel</span>
        </Button>
        <Button color="primary" variant="contained" disabled={savingDraft} style={{ fontWeight: "bold", marginLeft: 20 }} title={(action === "edit" ? "Save" : "Create")} onClick={() => {saveDraftTitle(newTitle, newSubTitle)}}>
          {savingDraft && <CircularProgress size={20} style={{ color: "white" }} />}
          {!savingDraft && <><SaveIcon /><span style={{marginLeft:5}}>{(action === "edit" ? "Save" : "Create")}</span></>}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default CreateDraftDialogComponent;
