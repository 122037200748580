import React from 'react';
import { useLogErrorMutation } from '../generated';


export const withErrorBoundaryHOC = (Component: any) => {
  return (props: any) => {
    const [logError] = useLogErrorMutation();

    return <Component logError={logError} {...props} />;
  };
};