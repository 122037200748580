import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grow, TextField, Typography } from "@mui/material";
import React, { forwardRef, useContext, useImperativeHandle, useState } from "react";
import { SendCurrentEmailCodeMutation, useSendCurrentEmailCodeMutation } from "../generated";
import { TwoFAVerificationResult, UserContext } from "../ServiceWrapper";
import moment from 'moment';
import { toast } from "react-toastify";
import { ApolloError } from "@apollo/client";
import NavigateNext from "@mui/icons-material/NavigateNext";
import CancelIcon from "@mui/icons-material/Cancel";

export interface IEmailCodeControllerReference {
  showEmailCodeVerification: (successCallback: (result: TwoFAVerificationResult) => Promise<boolean>, cancelCallback?: () => void) => void
}

const EmailCodeController = forwardRef<IEmailCodeControllerReference>((props, ref) => {
  
  const [open, setOpen] = useState<boolean>(false);
  const [dialogStep, setDialogStep] = useState<number>(1);
  const [processing, setProcessing] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const [codeTime, setCodeTime] = useState<number>(0);
  const [externalCallback, setExternalCallback] = useState<((result: TwoFAVerificationResult) => Promise<boolean>) | null>(null);
  const [cancelCallback, setCancelCallback] = useState<(() => void) | null>(null);
  const { currentUser } = useContext(UserContext);
  const [sendEmailCode] = useSendCurrentEmailCodeMutation();

  const handleClose = (event: any, reason: any) => {

    if (reason && reason === "backdropClick") 
        return;

    setOpen(false);

    if (cancelCallback)
      cancelCallback();
  };
  
  const verifyTwoFA = () => {

    if (code === "") {
      toast.error('You must enter a code first.');
      return;
    }

    setProcessing(true);

    if (externalCallback) {
      externalCallback({ code: code, time: codeTime}).then((val: boolean) => {
        setProcessing(false);

        if (val)
          setOpen(false);
      });
    }
    setCode('');
  };

  const showEmailCodeVerification = (successCallback: (result: TwoFAVerificationResult) => Promise<boolean>, cancelCallback?: () => void) => {
    setCode('');
    setExternalCallback(() => successCallback);

    if (cancelCallback)
      setCancelCallback(() => cancelCallback);

    setOpen(true);
    setDialogStep(1);

    sendEmailCode({
      onCompleted(data: SendCurrentEmailCodeMutation) {
        
        if (data?.sendCurrentEmailCode?.error) {
          toast.error(data?.sendCurrentEmailCode?.error);
          
          if (cancelCallback)
            cancelCallback();
          
          return;
        }

        setDialogStep(2);

      },
      onError(error: ApolloError) {
        
        toast.error("An error occurred while sending the email verification code.");
        console.log(error);
        setOpen(true);
      },
    });
  }
  
  useImperativeHandle(ref, () => ({
    showEmailCodeVerification
  }));

  const handleTwoFACodeChange = (event: any) => {
    setCode(event.target.value);
    setCodeTime(moment().unix());
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Grow as React.ComponentType}
      keepMounted
      fullWidth={true}
      maxWidth={'xs'}
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Email Code Verification</DialogTitle>
      <DialogContent>
        {dialogStep === 1 && (
          <>
            <div style={{width:'100%',height:'100%',display:'flex', justifyContent:'center', alignItems: 'center'}}>
              <CircularProgress/><h3 style={{marginLeft:20}}>Sending verification email</h3>
            </div>
          </>
        )}
        {dialogStep === 2 && (
          <>
            <Typography>Enter the code sent to {currentUser?.email}</Typography>
            <TextField id="twoFACode" type="text" onChange={handleTwoFACodeChange} inputProps={{maxLength:6}} value={code} style={{marginTop:10}} label="Code" variant="standard" />
          </>
        )}
      </DialogContent>
      <DialogActions>
      {dialogStep === 2 && (
        <>
          <Button color="secondary" variant="outlined" onClick={() => {handleClose(null, null);}}><CancelIcon color="secondary" style={{marginRight:5}}/>Cancel</Button>
          <Button color="primary" variant="contained" onClick={() => {verifyTwoFA()}}>{processing && <CircularProgress size={20} />}{!processing && <>Next<NavigateNext style={{marginLeft:5}}/></>}</Button>
        </>
      )}
      </DialogActions>
    </Dialog>
  );
});

export default EmailCodeController;