import {
  Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grow, Link, Menu, MenuItem, styled, Typography, useMediaQuery, useTheme
} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarContainer, GridToolbarQuickFilter, GridValueGetterParams } from '@mui/x-data-grid';
import { Editor as TinyMCEEditor } from 'tinymce';

import moment from "moment";
import { useEffect, useRef, useState } from "react";
import {
  DeleteDraftMutation, DraftDto, ListUserDraftsQuery, useCreateDraftMutation, useDeleteDraftMutation, useListUserDraftsLazyQuery, useSaveDraftContentMutation, useUpdateDraftTitleAndSubtitleMutation
} from "../../generated";

import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreIcon from "@mui/icons-material/MoreHoriz";
import AddPostIcon from "@mui/icons-material/PostAdd";
import PublishIcon from "@mui/icons-material/Publish";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";


import { IDialogReference } from "./createDraftDialog";
import { IImagePickerComponentReference } from "./imagePicker";
import { WrapperDiv } from "../accountSettings/profile";
import ToolTipComponent from "../shared/toolTip";


interface DraftListProps {
  newDraft: () => void;
}

interface ISelectedDraft {
  draftIDHash: string;
  title: string;
}

const DraftListComponent: React.FC<DraftListProps> = ({ newDraft }: DraftListProps) => {

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  
  const [drafts, setDrafts] = useState<DraftDto[] | null | undefined>(null);

  const [loadingDrafts, setLoadingDrafts] = useState<boolean>(false);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [deletingDraft, setDeletingDraft] = useState<boolean>(false);
  const [selectedDraft, setSelectedDraft] = useState<ISelectedDraft>({draftIDHash: "", title: ""});

  const [deleteDraft] = useDeleteDraftMutation();

  const [listUserDrafts] = useListUserDraftsLazyQuery({
    fetchPolicy: "cache-and-network",
    onCompleted(data: ListUserDraftsQuery) {
      if (data?.listUserDrafts) {
        setDrafts(data.listUserDrafts);
      } else {
        setDrafts(null);
      }
      setLoadingDrafts(false);
    }
  });

  const CustomCell = (rd: any) => {
    const [anchor, setAnchor] = useState<any>(null);
    const theme = useTheme();

    return (
      <>
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{lineHeight: 'initial',  }}
          onClick={(e) => setAnchor(e.currentTarget)}
        >
          <MoreIcon />
        </Button>
  
        <Menu
          open={Boolean(anchor)}
          anchorEl={anchor}
          onClose={() => {
            setAnchor(null);
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <MenuItem style={{color:theme.palette.error.main}} onClick={() => {setSelectedDraft({draftIDHash: rd.rd.draftIDHash, title: `${rd.rd.title || ''}${(rd.rd.subtitle ? ": " + rd.rd.subtitle : "")}`});setOpenDeleteConfirmation(true);}}>
            <DeleteIcon style={{marginRight:5}}/>Delete
          </MenuItem>
          <MenuItem style={{color:theme.palette.primary.main}}>
            <PublishIcon style={{marginRight:5}}/>Publish
          </MenuItem>
        </Menu>
      </>
    );
  };
  

  const StyledDataGrid = styled(DataGrid)`
    &.MuiDataGrid-root {
     border:none; 
    }
    &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
    &.MuiDataGrid-root .MuiDataGrid-cell:focus-within,
    &.MuiDataGrid-root .MuiDataGrid-cell:focus {
      outline: none;
    }
  `;

  const columns: GridColDef[] = [
    {
      field: 'fullTitle',
      headerName: 'Title',
      description: 'This column has a value getter and is not sortable.',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
      `${params.row.title || ''}${(params.row.subtitle ? ": " + params.row.subtitle : "")}`,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Link style={{cursor:'pointer'}} onClick={() => { navigate('/createContent/drafts/edit/' + params.row.draftIDHash); }}>{params.value}</Link>
      )
    },
    {
      field: 'created',
      headerName: 'Created',
      type: 'date',
      width: 110,
      valueFormatter: params => 
        moment(params?.value).format("MM/DD/YYYY"),
    },
    {
      field: 'modified',
      headerName: '',
      width: (isMobile ? 80 : 300),
      renderCell: (params: GridRenderCellParams) => {
        if (!isMobile)
        {
          return (
            <>
              <Button
                variant="outlined"
                color="error"
                size="small"
                style={{lineHeight: 'initial',  }}
                tabIndex={params.hasFocus ? 0 : -1}
                onClick={() => {setSelectedDraft({draftIDHash: params.row.draftIDHash, title: `${params.row.title || ''}${(params.row.subtitle ? ": " + params.row.subtitle : "")}`});setOpenDeleteConfirmation(true);}}
              >
                <DeleteIcon style={{marginRight:(!isMobile ? 5 : 0)}}/>
                {!isMobile && <>Delete</>}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                style={{ marginLeft: 10, lineHeight: 'initial',  }}
                tabIndex={params.hasFocus ? 0 : -1}
              >
                <PublishIcon style={{marginRight:(!isMobile ? 5 : 0)}}/>
                {!isMobile && <>Publish</>}
              </Button>
            </>
          )
        }
        else {
          return (
            <CustomCell rd={params.row}/>
          );
        }
      },
    }
    
  ];

  const deleteDraftDoc = () => {
    
    setDeletingDraft(true);
    
    deleteDraft({
      variables: {
        draftIDHash: selectedDraft.draftIDHash
      },
      onCompleted(data: DeleteDraftMutation) {
        setDeletingDraft(false);
        
        if (data?.deleteDraft.error) {
          toast.error(data?.deleteDraft.error);
        }
        else {
          
          setSelectedDraft({draftIDHash: "", title: ""});
          setOpenDeleteConfirmation(false);
          setLoadingDrafts(true);
          listUserDrafts();
        }
      }
    });
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{display:'flex', justifyContent:'end', gap:20}} >
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            newDraft();
          }}
        >
          <>
            <AddPostIcon style={{ marginRight: 5 }} />
            New
          </>
        </Button>
        <GridToolbarQuickFilter/>
      </GridToolbarContainer>
    );
  }

  const handleDeleteConfirmationClose = (event: any, reason: any) => {

    if (reason && reason === "backdropClick") 
        return;

    setOpenDeleteConfirmation(false);
  };

  useEffect(() => {
    
    setLoadingDrafts(true);
    listUserDrafts();
  }, [])

  return (
    <>
      <Dialog
        open={openDeleteConfirmation}
        TransitionComponent={Grow as React.ComponentType}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleDeleteConfirmationClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          Delete Draft
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
            <Typography>Are you sure you want to delete the draft<b>{selectedDraft.title ? " - " + selectedDraft.title : ""}</b>?<br/>This cannot be undone.</Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="outlined" style={{ fontWeight: "bold", marginLeft: 20 }} title="Cancel" onClick={() => {setOpenDeleteConfirmation(false)}}>
            <CancelIcon /><span style={{marginLeft:5}}>Cancel</span>
          </Button>
          <Button color="primary" variant="contained" disabled={deletingDraft} style={{ fontWeight: "bold", marginLeft: 20 }} title="Delete" onClick={() => {deleteDraftDoc()}}>
            {deletingDraft && <CircularProgress size={20} style={{ color: "white" }} />}
            {!deletingDraft && <><DeleteIcon /><span style={{marginLeft:5}}>Delete</span></>}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteConfirmation}
        TransitionComponent={Grow as React.ComponentType}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleDeleteConfirmationClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          Publish Draft
        </DialogTitle>
        <DialogContent>
        <WrapperDiv>
          <div style={{ display: "flex", alignItems: "center", width: "100%", marginTop: 30 }}>
            <div style={{ flexGrow: 1 }}>
              <ToolTipComponent
                htmlFor="txtGenre"
                label={"Email"}
                tooltip={isMobile ? "The email you login to Miadara with and where communications are sent." : ""}
              />
            </div>
          </div>
        </WrapperDiv>
          
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="outlined" style={{ fontWeight: "bold", marginLeft: 20 }} title="Cancel" onClick={() => {setOpenDeleteConfirmation(false)}}>
            <CancelIcon /><span style={{marginLeft:5}}>Cancel</span>
          </Button>
          <Button color="primary" variant="contained" disabled={deletingDraft} style={{ fontWeight: "bold", marginLeft: 20 }} title="Delete" onClick={() => {deleteDraftDoc()}}>
            {deletingDraft && <CircularProgress size={20} style={{ color: "white" }} />}
            {!deletingDraft && <><DeleteIcon /><span style={{marginLeft:5}}>Delete</span></>}
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ width: "100%" }}>
        <h1 style={{ textAlign: "center" }}>Drafts</h1>
        {loadingDrafts &&
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <CircularProgress />
          </div>
        }
        {!loadingDrafts && !drafts &&
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <h3 style={{color: "red"}}>Failed to load drafts</h3>
          </div>
        }
        {!loadingDrafts && drafts &&
          <Box sx={{  }}>
            <StyledDataGrid
              autoHeight
              rows={drafts}
              getRowId={(row) => row.draftIDHash}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              disableDensitySelector
              columnVisibilityModel={{
                // Hide columns status and traderName, the other columns will remain visible
                fullTitle: true,
                created: !isMobile,
                modified: true
              }}
              components={{ Toolbar: CustomToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
            />
          </Box>
        }
      </div>
    </>
  );
};

export default DraftListComponent;
