import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  DefaultOptions,
  from,
  InMemoryCache
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/link-error";
import { PublicClientApplication } from "@azure/msal-browser";
import { Cache, Hub } from "aws-amplify";
import React, { FC } from "react";
import App from "./App";
import { b2cPolicies, msalConfig } from "./authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

const ApolloConnection: FC<any> = () => {
  
  
  const GetAccessToken = async () => {
    console.log("in apollo connection getting access token");
    let activeAuthority = Cache.getItem("AUTHENTICATION_ACTIVE_AUTHORITY");
    let request = {
        scopes: [msalConfig.auth.scope],
        authority: (activeAuthority ? activeAuthority : b2cPolicies.authorities.signUpSignIn.authority)
    };
    let accounts = msalInstance.getAllAccounts();
    if (!accounts || accounts.length === 0) {
      console.log("not authenticated");
      msalInstance.logoutRedirect();
      return "";
    } else console.log("getting token for: " + accounts[0].name);

    msalInstance.setActiveAccount(
      msalInstance.getAccountByHomeId(accounts[0].homeAccountId)
    );
    let authToken = await msalInstance
      .acquireTokenSilent(request)
      .catch((error) => {
        console.warn(error);
        console.warn(
          "silent token acquisition fails. acquiring token using popup"
        );
        //if (error instanceof msal.InteractionRequiredAuthError) {
        if (error != null) {
          // fallback to interaction when silent call fails
          return msalInstance
            .acquireTokenRedirect(request)
            .then((response) => {
              console.log(response);
              return response;
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          console.warn(error);
        }
      });
    if (authToken == null) {
      return "";
    } else if (authToken?.accessToken == null) {
      return "";
    } else {
      return authToken?.accessToken;
    }
  };

  /**
   * Error link to log all errors to the console and special handling for HTTP errors related to JWT and 5xx errors.
   */
  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, response }) => {
      // tslint:disable no-console
      // tslint:disable no-string-literal

      if (true) {
        if (graphQLErrors) {
          console.error(
            "graphql errors: ",
            JSON.stringify(graphQLErrors),
            graphQLErrors
          );
          // @ts-ignore
          //response.errors = null;
          Hub.dispatch('graphqlError', { event: 'graphql_error', data: networkError, message: 'A graphql error has occurred'});
        }
        if (networkError) {
          console.error(
            "network error: ",
            JSON.stringify(networkError),
            networkError
          );
          Hub.dispatch('globalError', { event: 'network_error', data: networkError, message: 'A network error has occurred'});
        }
        if (graphQLErrors || networkError) {
          console.error("operation: ", JSON.stringify(operation), operation);
        }
        if (response) {
          console.error("response: ", JSON.stringify(response), response);
        }
      }
    }
  );

  const withToken = setContext(async (_, { headers }) => {
    const token = await GetAccessToken();
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : null,
      },
    };
  });

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI,
  });

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
  
  const client = new ApolloClient({
    link: from([errorLink, withToken, httpLink]),
    connectToDevTools: true,
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions
  });

  return (
    <ApolloProvider client={client}>
      <App pca={msalInstance} />
    </ApolloProvider>
  );
};

export default ApolloConnection;
