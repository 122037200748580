import {
  AppBar,
  Box, Toolbar, Typography, useMediaQuery, useTheme
} from "@mui/material";

import { useContext, useEffect, useRef, useState } from "react";
import {
  CreateDraftMutation, UpdateDraftTitleAndSubtitleMutation, useCreateDraftMutation, useUpdateDraftTitleAndSubtitleMutation
} from "../../generated";
import { UserContext } from "../../ServiceWrapper";

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import headerLogoMark from "../../images/inkwell_blueink_v2_100x100.png";
import Header from "../header";
import DraftUpperRightMenuComponent from "./draftUpperRightMenu";


import CreateDraftDialogComponent, { IDialogReference } from "./createDraftDialog";
import DraftListComponent from "./draftList";
import EditDraftComponent, { IEditDraftReference } from "./editDraft";

const DraftsComponent = () => {
  const {
    setHeaderFunction,
  } = useContext(UserContext);

  const {action, id} = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const [draftIsDirty, setDraftIsDirty] = useState<boolean>(false);

  const [savingDraft, setSavingDraft] = useState<boolean>(false);
  const [savingDraftTitle, setSavingDraftTitle] = useState<boolean>(false);
  
  const editDraftRef = useRef<IEditDraftReference>(null);

  const [createDraft] = useCreateDraftMutation();
  const [updateDraftTitleAndSubtitle] = useUpdateDraftTitleAndSubtitleMutation();
  
  const draftTitleDialogRef = useRef<IDialogReference>(null);


  const getTitleDialog = () => {
    return (
      <CreateDraftDialogComponent ref={draftTitleDialogRef} action={action} savingDraft={savingDraftTitle} saveDraftTitle={saveDraftTitle}/>
    );
  };

  const showTitleEditor = (incomingTitle: string, incomingSubtitle: string | null) => {
    if (draftTitleDialogRef.current) {
      draftTitleDialogRef.current.showDialog(incomingTitle, incomingSubtitle);
    }
  };

  const goHome = () => {
    navigate('/');
  }

  const getHeader = () => {
    
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" style={{background:'white', display:'flex', alignItems:'center'}}>
          <div className="contentWidth">
            <Toolbar sx={{ }}>
              <Box sx={{ flexGrow: 0, display:'flex', alignItems: 'center' }} onClick={goHome} style={{cursor:'pointer'}}>
                <img id="headerLogoMark" src={headerLogoMark} style={{height:45, width:45}}></img>
              </Box>
              {!isMobile &&
              <Box sx={{ flexGrow: 1 }}>
                  <Typography
                    variant="h5"
                    component="span"
                    sx={{ color: "gray", marginLeft:2 }}
                  >|</Typography>
                  <Typography
                    component="span"
                    sx={{ color: "gray", marginLeft:2, fontSize: 17 }}
                  >{action === "new" && <>New Draft</>}{action === "edit" && <>Edit Draft</>}</Typography>
              </Box>
              }
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
              ></Typography>
              <DraftUpperRightMenuComponent saveDraft={saveDraft} action={action} isSaving={savingDraft} isDirty={draftIsDirty}/>
            </Toolbar>
          </div>
          <div style={{height:3, width:'100%'}} className="appBarHeaderBorder">
                &nbsp;
          </div>  
        </AppBar>
        
      </Box>
    );
  }

  const saveDraft = () => {
    if (editDraftRef.current) {
      editDraftRef.current.saveDraftContent();
    }
  };
  
  const saveDraftTitle = (draftTitle: string, draftSubTitle: string | null) => {
    
    let cLength = draftTitle.length;
    if (draftSubTitle)
      cLength += draftSubTitle.length;

    if (cLength > 200) {
      toast.error('The combined title and subtitle length may not exceed 200 characters.');
      return;
    }
    
    setSavingDraftTitle(true);

    if (!id) {
      // create new record
      createDraft({
        variables: {
          title: draftTitle,
          subtitle: draftSubTitle
        },
        onCompleted(data: CreateDraftMutation) {
          setSavingDraftTitle(false);
  
          if (data?.createDraft.error) {
            toast.error(data?.createDraft.error);
          }
          else {
            if (draftTitleDialogRef.current) {
              draftTitleDialogRef.current.hideDialog();
            }
            navigate('/createContent/drafts/edit/' + data?.createDraft.value);
          }
        }
      });
    }
    else {
      // update record
      
      updateDraftTitleAndSubtitle({
        variables: {
          draftIDHash: id,
          title: draftTitle,
          subtitle: draftSubTitle
        },
        onCompleted(data: UpdateDraftTitleAndSubtitleMutation) {
          setSavingDraftTitle(false);

          if (data?.updateDraftTitleAndSubtitle.error) {
            toast.error(data?.updateDraftTitleAndSubtitle.error);
          }
          else {

            if (editDraftRef.current) {
              editDraftRef.current.updateDraftTitleSubtitle(draftTitle, draftSubTitle);
            }

            if (draftTitleDialogRef.current) {
              draftTitleDialogRef.current.hideDialog();
            }
          }
        }
      });
    }
  };
  
  useEffect(() => {
    console.log('updating header');
    if (action === "new" || action === "edit")
      setHeaderFunction(getHeader);
    else
      setHeaderFunction(() => {return <Header/>});
  },[action, isMobile, draftIsDirty, savingDraft]);

  
  if (action === "edit") {
    return (
      <>
        {getTitleDialog()}
        <EditDraftComponent ref={editDraftRef} editDraftTitleSubtitle={showTitleEditor} setHeaderDraftIsDirty={setDraftIsDirty} setHeaderSavingDraft={setSavingDraft} />
      </>
    )
  }
  else if (action != null)
  {
    //navigate("/createContent/drafts");
    return null;
  }

  return (
    <>
      {getTitleDialog()}
      <DraftListComponent newDraft={() => { showTitleEditor("", "") }}/>
    </>
  );
};

export default DraftsComponent;