import {
  ApolloError,
  useApolloClient
} from "@apollo/client";
import { AccountInfo, AuthenticationResult, EventMessage, EventType } from "@azure/msal-browser";
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Cache } from "aws-amplify";
import axios from 'axios';
import React, { createContext, useEffect, useRef, useState } from 'react';
//import { apolloClient, httpLink } from './App';
import { useLocation, useNavigate } from 'react-router-dom';
//import {Cache, Hub} from "aws-amplify";
import { b2cPolicies, msalConfig } from './authConfig';
import { GlobalCacheKeys } from "./cache/globalCacheKeys";
import EmailCodeController, { IEmailCodeControllerReference } from "./components/emailCodeController";
import ProcessingController, { IProcessingControllerReference } from "./components/processingController";
import AuthenticatorController, { IAuthenticatorReference } from "./components/authenticatorController";
import { CurrentUserQuery, useCurrentUserLazyQuery, UserDto, useSendCurrentEmailCodeMutation, useVerifyEmailCodeLazyQuery, useVerifyTwoFaCodeMutation, VerifyEmailCodeQueryResult } from './generated';
import { toast } from "react-toastify";
import { jsx } from "@emotion/react";
import Header from "./components/header";

interface ServiceWrapperProps {
    children: JSX.Element
}

export interface TwoFAVerificationResult {
  code: string,
  time?: number
};

interface IGlobalCache {
  clear(): void,
  getAllKeys() : string[] | Promise<string[]>,
  getItem(key: GlobalCacheKeys): any,
  setItem(key: GlobalCacheKeys, value: any): void,
  removeItem(key: GlobalCacheKeys): void
}

interface IUserContext {
  isAuthenticated: Boolean,
  hasToken: Boolean,
  globalCache: IGlobalCache,
  currentUser: UserDto | null | undefined,
  setCurrentUser: React.Dispatch<React.SetStateAction<UserDto | null | undefined>>,
  setHeaderFunction: React.Dispatch<React.SetStateAction<() => JSX.Element>>,
  headerFunction: () => JSX.Element,
  verifyAuthenticatorCode: (successCallback: (result: TwoFAVerificationResult) => Promise<boolean>, cancelCallback?: () => void) => void,
  verifyEmailCode: (callback: (result: TwoFAVerificationResult) => Promise<boolean>, cancelCallback?: () => void) => void,
  verifyTwoFA: (successCallback: (result: TwoFAVerificationResult) => void, cancelCallback?: () => void) => void,
  twoFAVerificationResult: TwoFAVerificationResult,
  setTwoFAVerificationResult: React.Dispatch<React.SetStateAction<TwoFAVerificationResult>>,
  showProcessing: (processingText: string) => void,
  hideProcessing: () => void,
  setGlobalTimeout: (callback: () => any) => void,
  clearGlobalTimeout: () => void
}

const GlobalCache: IGlobalCache = {
  
  clear: () => {
    Cache.clear();
  },
  getAllKeys: () => {
    return Cache.getAllKeys();
  },
  getItem: (key: GlobalCacheKeys) => {

    return Cache.getItem(key);
  },
  setItem: (key: GlobalCacheKeys, value: any) => {

    return Cache.setItem(key, value);
  },
  removeItem: (key: GlobalCacheKeys) => {

    return Cache.removeItem(key);
  }
};

const defaultUserState: IUserContext = {
  isAuthenticated: false,
  hasToken: false,
  globalCache: GlobalCache,
  currentUser: null,
  headerFunction: () => { return <Header/>},
  twoFAVerificationResult: { code: "", time: 0},
  setTwoFAVerificationResult: () => {},
  setCurrentUser: () => {},
  setHeaderFunction: () => {},
  verifyTwoFA: () => {},
  verifyAuthenticatorCode: () => {},
  verifyEmailCode: () => {},
  showProcessing: () => {},
  hideProcessing: () => {},
  setGlobalTimeout: () => {},
  clearGlobalTimeout: () => {}
};

export const UserContext = createContext<IUserContext>(defaultUserState);

const ServiceWrapper: React.FC<ServiceWrapperProps> = ({ children }: ServiceWrapperProps) => {
  const { instance, accounts } = useMsal();
  const apolloClient = useApolloClient();
  const account = useAccount(accounts[0]);
  const navigate = useNavigate();
  const location = useLocation();
  const [hasToken, setHasToken] = useState<Boolean>(GlobalCache.getItem(GlobalCacheKeys.AUTHORIZATION_ACCESS_TOKEN) !== null);
  //const [activeAuthority, setActiveAuthority] = useState<string | null>(GlobalCache.getItem(GlobalCacheKeys.AUTHENTICATION_ACTIVE_AUTHORITY));
  const [navPathAfterLogin, setNavPathAfterLogin] = useState<string>('');
  const isAuthenticated = useIsAuthenticated();
  const processingControllerRef = useRef<IProcessingControllerReference>(null);
  const authenticatorControllerRef = useRef<IAuthenticatorReference>(null);
  const emailCodeControllerRef = useRef<IEmailCodeControllerReference>(null);

  const [globalTimeoutRef, setGlobalTimeoutRef] = useState<any>(null);
  const [twoFAVerificationResult, setTwoFAVerificationResult] = useState<TwoFAVerificationResult>({code: "", time: 0});
  const [currentUser, setCurrentUser] = useState<UserDto | null | undefined>(GlobalCache.getItem(GlobalCacheKeys.CURRENT_USER));
  const [headerFunction, setHeaderFunction] = useState<() => JSX.Element>(() => { return <Header/> });
  const [verify2FACode] = useVerifyTwoFaCodeMutation();
  const [sendCurrentEmailCode] = useSendCurrentEmailCodeMutation();

  
  const [verifyEmailCodeQuery] = useVerifyEmailCodeLazyQuery({
    fetchPolicy: "cache-and-network",
    onError(error: ApolloError) {
      toast.error("An error occurred while verifying your email code.");
      console.log(error);
    },
  });
  
  const [getCurrentUser] = useCurrentUserLazyQuery({
    onCompleted(data: CurrentUserQuery) {
      console.log(data);
      setCurrentUser(data?.currentUser);
      
      if (data?.currentUser?.registrationComplete !== true) {
        setNavPathAfterLogin('/register');
      }
      else {
        let lastVisited = GlobalCache.getItem(GlobalCacheKeys.LAST_VISITED_PAGE);

        if (lastVisited !== null) {
          console.log('last visited: ' + lastVisited);
          setNavPathAfterLogin(lastVisited);
          GlobalCache.removeItem(GlobalCacheKeys.LAST_VISITED_PAGE);
        }
        else {
          if (location.pathname === '/login') { 
            if (lastVisited == null) {
              setNavPathAfterLogin('/');
            }
          }
        }
      }
    },
    onError(error: ApolloError)
    {
        console.log('an error occurred');
        console.log(error);
    }
  });

  const verifyTwoFA = (successCallback: (result: TwoFAVerificationResult) => void, cancelCallback?: () => void) => {
    
    if (!!currentUser?.is2FASetup) {
      
      verifyAuthenticatorCode(async (result: TwoFAVerificationResult) => {
        
        let verificationResponse = await verify2FACode({
          variables: {
            code: result.code,
            codeEntryTime: result.time,
            saveCode: false
          },
          onError(error: ApolloError) {
            toast.error("An error occurred while verifying your code, please try again.");
            console.log(error);
            return false;
          }
        }).then((val: any) => {
          
          if (val?.data?.verifyTwoFACode?.error) {
            toast.error(val?.data?.verifyTwoFACode?.error);
            return false;
          }
          
          if (val?.data?.verifyTwoFACode?.success) {
            setTwoFAVerificationResult({code: result.code, time: result.time});
            return true;
          }
          
          toast.error('An error occurred while verifying Two-Factor Authentication.');
          return false;
        });

        if (verificationResponse)
          await successCallback(result);
        
        return verificationResponse;
      }, cancelCallback);
    }
    else {
      
      verifyEmailCode(async(result: TwoFAVerificationResult) => {
      
        let verificationResponse = await verifyEmailCodeQuery({
          variables: {
            code: result.code,
            isCurrentEmail: true
          }
        }).then((val: VerifyEmailCodeQueryResult) => {
          if (!!val.data?.verifyEmailCode) {
            setTwoFAVerificationResult({code: result.code, time: result.time});
            return true;
          }

          toast.error('Invalid code');
          return false;
        });

        if (verificationResponse)
          await successCallback(result);

        return verificationResponse;
      }, cancelCallback);
    }
  };

  const verifyAuthenticatorCode = (successCallback: (result: TwoFAVerificationResult) => Promise<boolean>, cancelCallback?: () => void) => {
    
    if (authenticatorControllerRef && authenticatorControllerRef.current)
      authenticatorControllerRef?.current.showAuthenticatorCodeVerification(successCallback, cancelCallback);
  };

  const verifyEmailCode = (successCallback: (result: TwoFAVerificationResult) => Promise<boolean>, cancelCallback?: () => void) => {
    
    if (emailCodeControllerRef && emailCodeControllerRef.current)
      emailCodeControllerRef?.current.showEmailCodeVerification(successCallback, cancelCallback);
  };

  const showProcessing = (processingText: string) => {
    
    if (processingControllerRef && processingControllerRef.current)
      processingControllerRef?.current.showProcessing(processingText);
  };

  const hideProcessing = () => {
    
    if (processingControllerRef && processingControllerRef.current)
      processingControllerRef?.current.hideProcessing();
  };
 
  const GetAccessToken = async(acct: AccountInfo | null) => {
    let activeAuthority = GlobalCache.getItem(GlobalCacheKeys.AUTHENTICATION_ACTIVE_AUTHORITY);
    let request = {
        scopes: [msalConfig.auth.scope],
        authority: (activeAuthority ? activeAuthority : b2cPolicies.authorities.signUpSignIn.authority)
    };

    if (acct != null)
      instance.setActiveAccount(instance.getAccountByHomeId(acct.homeAccountId));

    let authToken = await instance.acquireTokenSilent(request)
    .catch(error => {
        console.warn(error);
        console.warn("silent token acquisition fails. acquiring token using popup");
        //if (error instanceof msal.InteractionRequiredAuthError) {
        if (error != null) {
            // fallback to interaction when silent call fails
            return instance.acquireTokenRedirect(request)
                .then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.error(error);
                });
        } else {
            console.warn(error);   
        }
    });
    if (authToken == null)
    {
      return '';
    }
    else if (authToken?.accessToken == null)
    {
      return '';
    }
    else
    {
      return authToken?.accessToken;
    }
  };
  
  
  const setGlobalTimeout = (callback: () => any) => {
    console.log('setting global timeout');
    setGlobalTimeoutRef(callback());
  }

  const clearGlobalTimeout = () => {
    console.log('clearing global timeout');
    clearTimeout(globalTimeoutRef);
  }

  useEffect(() => {

    // This will be run on component mount
    instance.enableAccountStorageEvents();
    const callbackId = instance.addEventCallback(async (message: EventMessage) => {
      if (message.eventType === EventType.ACCOUNT_ADDED) {
        // Update UI with new account
        console.log('account added');
        // give the account on the other tab long enough to acquire the token
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } 
      else if (message.eventType === EventType.ACCOUNT_REMOVED) {
        // Update UI with account logged out
        console.log('account removed');
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
      else if (message.eventType === EventType.LOGIN_FAILURE) {
        navigate('/');
      }
      else if (message.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        instance.logout();
      }
      else if (message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        
        let authResult = message.payload as AuthenticationResult;
        console.log('token aquisition succeeded');
        GlobalCache.setItem(GlobalCacheKeys.AUTHORIZATION_ACCESS_TOKEN, authResult.accessToken);
        setHasToken(true);

      }
      else if (message.eventType === EventType.LOGIN_SUCCESS)
      {
        console.log('login success');
        let authResult = message.payload as AuthenticationResult;
        //console.log('access token: ' + authResult.accessToken);
        //await GetAccessToken(authResult.account);
        
      }
      else if (message.eventType === EventType.LOGOUT_SUCCESS)
      {
        console.log('logout success');
        GlobalCache.clear();
        apolloClient.clearStore();
      }
    });
    
    return () => {
        // This will be run on component unmount
        if (callbackId) {
            instance.removeEventCallback(callbackId);
        }
    }
    
  }, []);

  
  useEffect(() => {
    clearTimeout(globalTimeoutRef);
    if (!location.pathname.startsWith('/createContent/drafts/new') && !location.pathname.startsWith('/createContent/drafts/edit'))
      setHeaderFunction(() => { return <Header/> });
    
  }, [location])
  
  useEffect(() => {

    if (isAuthenticated) {
      GetAccessToken(account);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && hasToken) {
      
      console.log('pathname: ' + location.pathname);

      if (location.pathname !== '/register')
        getCurrentUser();
    }
  }, [isAuthenticated, hasToken]);

  useEffect(() => {
    GlobalCache.setItem(GlobalCacheKeys.CURRENT_USER, currentUser);
  }, [currentUser]);

  useEffect(() => {
    if (navPathAfterLogin != '') {
      console.log('navpath was set - navigating: ' + navPathAfterLogin);
      navigate(navPathAfterLogin);
    }
  }, [navPathAfterLogin]);

  useEffect(() => {
    // Handle the redirect flows
    instance
    .handleRedirectPromise()
    .then((tokenResponse) => {
      // Handle redirect response
      console.log('handle redirect token response:', tokenResponse);
    })
    .catch((error) => {
      // Handle redirect error
      
      console.log('handle redirect error: ', error);
    /*
      let lastVisited = Cache.getItem("LAST_VISITED_PAGE");

          if (lastVisited !== null) {
            
            console.log('last visited: ' + lastVisited);
            window.location.href = lastVisited;
            Cache.removeItem("LAST_VISITED_PAGE");
          }*/

      let lastVisited = GlobalCache.getItem(GlobalCacheKeys.LAST_VISITED_PAGE);

      if (lastVisited !== null) {
        console.log('last visited: ' + lastVisited);
        setNavPathAfterLogin(lastVisited);
        GlobalCache.removeItem(GlobalCacheKeys.LAST_VISITED_PAGE);
      }
      else
        setNavPathAfterLogin('/');
    });

  },[]);

  /* eslint-disable no-param-reassign */
  axios.interceptors.request.use(async (config) => {
    console.log('in axios interceptor');
    var access_token = GlobalCache.getItem(GlobalCacheKeys.AUTHORIZATION_ACCESS_TOKEN);
    if (access_token == null)
      access_token = await GetAccessToken(account);

    const bearer = `Bearer ${access_token}`;
    if (config?.headers)
        config.headers.Authorization = bearer;

    return config;
  });
 
  return (
    <UserContext.Provider value={{ 
      isAuthenticated, hasToken, globalCache: GlobalCache, currentUser, headerFunction, twoFAVerificationResult,
      setTwoFAVerificationResult, setCurrentUser, setHeaderFunction, verifyTwoFA, verifyAuthenticatorCode, 
      verifyEmailCode, showProcessing, hideProcessing, setGlobalTimeout, clearGlobalTimeout}}>
      <AuthenticatorController ref={authenticatorControllerRef}/>
      <ProcessingController ref={processingControllerRef}/>
      <EmailCodeController ref={emailCodeControllerRef}/>
      {children}
    </UserContext.Provider>
  );
};

export default ServiceWrapper;