import { Typography } from "@mui/material";
import React from "react";

const PrivacyPolicyPage = () => {
    
  return (
      <>
        <Typography component="h2">
            Privacy Policy
        </Typography>
    </>
  );
};

export default PrivacyPolicyPage;