import { ApolloCache, DefaultContext, MutationFunctionOptions } from "@apollo/client";
import * as React from "react";
import { ErrorInfo } from "react";
import { ErrorInfoInput, ErrorInput, Exact, LogErrorMutation } from "../generated";
import { withErrorBoundaryHOC } from "./withErrorBoundaryHOC";

interface IErrorBoundaryProps {
    logError: (options?: MutationFunctionOptions<LogErrorMutation, Exact<{
        error: ErrorInput;
        errorInfo: ErrorInfoInput;
    }>, DefaultContext, ApolloCache<any>> | undefined) => Promise<any>;
  }

export class ErrorBoundary extends React.Component<IErrorBoundaryProps> {


  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }


  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    
    // You can also log the error to an error reporting service
    this.props.logError({
        variables: {
            error: {
                name: error?.name,
                message: error?.message,
                stack: error?.stack
            },
            errorInfo: errorInfo
        }});
  }

  render() {
    //@ts-ignore
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default withErrorBoundaryHOC(ErrorBoundary);