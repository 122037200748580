import { Button, useTheme } from "@mui/material";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import myWidget from "./myWidget";

export interface UploadWidgetProps {
  sources?: any;
  sourceKeys?: any;
  resourceType?: any;
  cloudName?: any;
  uploadPreset?: any;
  buttonText?: any;
  style?: any;
  widgetStyles?: any;
  folder?: any;
  cropping?: any;
  generateSignatureUrl?: any;
  onSuccess?: any;
  onFailure?: any;
  logging?: any;
  customPublicId?: any;
  eager?: any;
  apiKey?: any;
  accepts?: "application/json";
  contentType?: "application/json";
  withCredentials?: any;
  use_filename?: any;
  unique_filename?: any;
  googleDriveClientId?: any;
  multiple?: any;
  buttonType?: "button";
  destroy?: any;
  autoClose?: any;
  showButton?: boolean;
}

export interface IUploadWidgetReference {
  openUploadDialog: () => void
}

const UploadWidget = forwardRef<IUploadWidgetReference, UploadWidgetProps>((props, ref) => {
  
  const [uploadWidget, setUploadWidget] = useState<any>(null);
  const theme = useTheme();
  
  const openUploadDialog = () => {
    if (uploadWidget)
      uploadWidget.open();
    else
      console.log('upload widget not ready');
  };

  useImperativeHandle(ref, () => ({
    openUploadDialog
  }));

  useEffect(() => {
    setUploadWidget(initWidget());
  },[]);

  function initWidget() {
    if (uploadWidget == null) {
      let w = myWidget(
        props.sources,
        props.sourceKeys,
        props.resourceType,
        props.cloudName,
        props.uploadPreset,
        props.folder,
        props.cropping,
        props.generateSignatureUrl,
        props.onSuccess,
        props.onFailure,
        props.logging,
        props.customPublicId,
        props.eager,
        props.apiKey,
        props.accepts,
        props.contentType,
        props.withCredentials,
        props.use_filename,
        props.unique_filename,
        props.googleDriveClientId,
        props.multiple,
        props.widgetStyles,
        props.destroy,
        props.autoClose
      );
      return w;
    }
  }

  return (
    <>
      {uploadWidget != null && props.showButton && (
        <Button
          style={{
            marginTop: 10,
            color: "white",
            border: "none",
            width: "180px",
            backgroundColor: theme.palette.primary.main,
            borderRadius: "4px",
            height: "25px",
          }}
          onClick={() => {
            //initWidget();
            //@ts-ignore
            uploadWidget.open();
          }}
        >
          {props.buttonText || "Upload files"}
        </Button>
      )}
    </>
  );
});

export default UploadWidget;
